<template>
  <div v-loading="dataLoading">
    <div class="row">
      <el-table
        :data="slicedData"
        stripe
        show-summary
        row-class-name="cursor-pointer"
        :summary-method="getSummary"
        :empty-text="emptyText"
        class="w-full"
        size="small"
        @row-click="handleRowClick">
        <el-table-column
          prop="TimeIntervalUtc"
          :label="getDateHeader(filters.tz, $t('column_labels.date'))"
          :min-width="100">
          <template slot-scope="scope">
            <span>
              {{ getLocalDateTimeTz(scope.row.TimeIntervalUtc, filters.tzOffset, '', getUtcFormat()) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('SubAccountId')"
          prop="SubAccountId"
          :label="$t('column_labels.subaccount_id')"
          :min-width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.SubAccountId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Country')"
          prop="Country"
          :label="$t('column_labels.country')"
          :min-width="60">
          <template slot-scope="scope">
            <span>{{ scope.row.Country }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Total')"
          prop="SmsCountTotal"
          :label="$t('column_labels.total')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.SmsCountTotal).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Sent')"
          prop="SmsCountOutboundAll"
          :label="$t('column_labels.sent')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.SmsCountOutboundAll).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Total chargeable')"
          prop="SmsCountCharged"
          align="right">
          <template slot="header">
            <span>{{ $t('column_labels.total') }}</span>
            <br>
            <span>{{ $t('column_labels.chargeable') }}</span>
          </template>
          <template slot-scope="scope">
            {{ Number(scope.row.SmsCountCharged).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Delivered')"
          prop="SmsCountOutboundDelivered"
          :label="$t('column_labels.delivered')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.SmsCountOutboundDelivered).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Undelivered')"
          prop="SmsCountOutboundUndelivered"
          :label="$t('column_labels.undelivered')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.SmsCountOutboundUndelivered).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Rejected')"
          prop="SmsCountOutboundRejected"
          :label="$t('column_labels.rejected')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.SmsCountOutboundRejected).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Received')"
          prop="SmsCountInboundAll"
          :label="$t('column_labels.received')"
          align="right">
          <template slot-scope="scope">
            {{ Number(scope.row.SmsCountInboundAll).toLocaleString($constants.LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Delivery rate')"
          prop="SmsDeliveryRate"
          :label="$t('column_labels.delivery_rate')"
          align="right">
          <template slot-scope="scope">
            <span>{{ `${toFraction(scope.row.SmsDeliveryRate)}%` }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Currency')"
          prop="Currency"
          :label="$t('column_labels.currency')"
          :min-width="60">
          <template slot-scope="scope">
            <span>{{ scope.row.AccountCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkColumnByName('Cost') && showCost"
          prop="Cost"
          :label="$t('column_labels.cost')"
          align="right">
          <template slot-scope="scope">
            {{ $wc.formatPrice(scope.row.Cost, $constants.PRICE_DECIMAL, $constants.LOCALE) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row pt-4 flex">
      <div class="row flex flex-wrap flex-grow">
        <div class="w-2/6 flex-grow pt-4 content-right text-right">
          <el-pagination
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="total,prev,pager,next,jumper"
            :total="total"
            @current-change="sliceData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import TimeFormatter from '../../../mixins/timeFormatter';

export default {
  name: 'SmsData',

  components: {},

  mixins: [
    TimeFormatter,
  ],

  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },

    summary: {
      type: Array,
      default() {
        return [];
      },
    },

    selectedColumns: {
      type: Array,
      default() {
        return [];
      },
    },

    columns: {
      type: Array,
      default() {
        return [];
      },
    },

    dataLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    filters: {
      type: Object,
      default() {
        return {};
      },
    },

    interval: {
      type: Number,
      default() {
        return 1440;
      },
    },
  },

  data() {
    const self = this;
    return {
      emptyText: self.$t('date_labels.empty_data'),

      slicedData: [],
      currentPage: 1,
      pageSize: 20,
      displayColumns: [],

      total: 0,
    };
  },

  computed: {
    ...mapState('user', ['hasPricingEntitlement']),

    showCost() {
      return this.hasPricingEntitlement;
    },
  },

  watch: {
    tableData: {
      handler() {
        this.sliceData();
      },
      immediate: true,
    },
  },

  methods: {
    checkColumn(index) {
      return this.selectedColumns.includes(this.columns[index]);
    },

    checkColumnByName(column) {
      return this.selectedColumns.includes(this.columns.find(c => c === column));
    },

    toFraction(item) {
      const fraction = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      return Number(item).toLocaleString('en-US', fraction);
    },

    handleRowClick(r) {
      const { country, operator, subAccount } = this.filters;
      const query = {
        dateFrom: r.TimeIntervalUtc,
        dateTo: r.TimeIntervalUtc,
      };

      if (country && country.length) {
        query.c = country;
      }

      if (operator && operator.length) {
        query.o = operator;
      }

      if (subAccount && subAccount.length) {
        query.sa = subAccount;
      }

      this.$router.push({
        path: '/logs',
        query,
      });
    },

    getSummary() {
      const sumCols = [
        '',
        '',
        'SmsCountTotal',
        'SmsCountOutboundAll',
        'SmsCountCharged',
        'SmsCountOutboundDelivered',
        'SmsCountOutboundUndelivered',
        'SmsCountOutboundRejected',
        'SmsCountInboundAll',
        'SmsDeliveryRate',
        '',
        'Cost',
      ];

      this.loading = true;

      let sums = ['SUMMARY'];
      const summary = JSON.parse(JSON.stringify(this.summary));
      const curCol = summary.find(item => item.col === 'SummaryCurrency');
      const currency = curCol ? curCol.value : 'EUR';

      summary.forEach((val) => {
        const idx = sumCols.indexOf(val.col);

        if (idx !== -1) {
          let sum = 0;
          switch (val.col) {
            case 'SmsDeliveryRate':
              sum = `${val.total}%`;
              break;
            case 'Cost':
              if (this.selectedColumns.includes('Currency')) {
                sum = `${Number(val.total).toLocaleString(this.$constants.LOCALE)}`;
              } else {
                sum = `${currency} ${Number(val.total).toLocaleString(this.$constants.LOCALE)}`;
              }
              break;
            default:
              sum = Number(val.total).toLocaleString(this.$constants.LOCALE);
              break;
          }
          sums[idx + 1] = sum;
        }
      });

      sums[1] = '';
      sums[2] = '';
      if (!this.selectedColumns.includes('Currency')) sums[11] = '';
      if (this.selectedColumns.includes('Currency')) sums[11] = currency;

      sums = sums.reduce((acc, val, i) => {
        if (this.checkColumn(i)) {
          acc.push(val);
        }
        return acc;
      }, []);

      return sums;
    },

    sliceData() {
      const count = this.tableData.length;
      if (count
        && (this.selectedColumns.includes('SubAccountId')
          || this.selectedColumns.includes('Currency'))) {
        // Check if currentPage is within the page range
        const lastPage = Math.ceil(Number(count) / this.pageSize);

        if (this.currentPage > lastPage) {
          this.currentPage = lastPage; // eslint-disable-line
        } else if (this.currentPage < 0) {
          this.currentPage = 1; // eslint-disable-line
        }

        const from = this.pageSize * (this.currentPage - 1);
        const to = from + this.pageSize;

        this.total = count;
        this.slicedData = this.tableData.slice(from, to);
      } else if (count
        && !this.selectedColumns.includes('SubAccountId')
        && !this.selectedColumns.includes('Currency')) {
        const displayData = this.tableData.reduce((acc, item) => {
          const index = _.findIndex(acc, {
            Currency: item.Currency,
            Country: item.Country,
            SubAccountId: item.SubAccountId,
            TimeIntervalUtc: item.TimeIntervalUtc,
          });

          if (index > -1) {
            const accItem = { ...acc[index] };
            accItem.SmsCountDelivered += item.SmsCountDelivered;
            accItem.SmsCountRejected += item.SmsCountRejected;
            accItem.SmsCountTotal += item.SmsCountTotal;
            accItem.SmsCountTotalAccepted += item.SmsCountTotalAccepted;
            accItem.SmsCountUndelivered += item.SmsCountUndelivered;
            accItem.SmsDeliveryRate += Number(item.SmsDeliveryRate);
            accItem.SmsDeliveryRate /= 2;
            accItem.Cost += item.Cost;

            acc[index] = accItem;
          } else {
            const i = { ...item };
            i.SmsDeliveryRate = Number(i.SmsDeliveryRate);
            acc.push(i);
          }

          return acc;
        }, []);

        const lastPage = Math.ceil(Number(displayData.length) / this.pageSize);

        if (this.currentPage > lastPage) {
          this.currentPage = lastPage; // eslint-disable-line
        } else if (this.currentPage < 0) {
          this.currentPage = 1; // eslint-disable-line
        }

        const from = this.pageSize * (this.currentPage - 1);
        const to = from + this.pageSize;

        this.total = displayData.length;
        this.slicedData = displayData.slice(from, to);
      } else {
        this.slicedData = [];
      }
    },

    getUtcFormat() {
      let format = 'MMM DD, YYYY';

      if (this.interval === 60) {
        format = 'MMM DD, YYYY hh:mm A';
      }

      return format;
    },
  },
};
</script>
<style>
  .reports-checkbox .el-checkbox {
    display: block;
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  .reports-checkbox .el-checkbox .el-checkbox__label {
    font-size: 12px;
  }

  .reports-dropdown-item {
    line-height: 24px;
  }

  .reports-dropdown-item:hover {
    background-color: #ffffff !important;
    color: white;
  }
</style>
