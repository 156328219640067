import Vue from 'vue';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import VueMoment from 'vue-moment';
import ElementUI from 'element-ui';
import VueLocalStorage from 'vue-localstorage';
import VueSanitize from 'vue-sanitize';
import singleSpaVue from 'single-spa-vue';
import { sync } from 'vuex-router-sync';
// import locale from 'element-ui/lib/locale/lang/en';
import VueI18n from 'vue-i18n';

// Constants
import Constants from './constants';
import AppView from './App.vue';
import routes from './router';
import store from './store';

// Plugins
import VueAuth from './plugins/vue-auth';
import VueJstz from './plugins/vue-jstz';
import VueZxcvbn from './plugins/vue-zxcvbn';
import Wc from './plugins/vue-wc';
import CurrencySymbol from './plugins/vue-currency';

// Directives
import VueClipboard from './directives/clipboard';

if (window.Bugsnag) {
  window.Bugsnag.getPlugin('vue')
    .installVueErrorHandler(Vue);
}

Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: localStorage.getItem('SELECTED_LOCALE'), // set locale
  fallbackLocale: 'en',
});

// Plugins
Vue.use(Constants);
Vue.use(VueResource);
// Vue.use(ElementUI, { locale });
Vue.use(VueRouter);
Vue.use(VueMoment);
Vue.use(VueAuth);
Vue.use(VueJstz);
Vue.use(VueZxcvbn);
Vue.use(Wc);
Vue.use(CurrencySymbol);
Vue.use(VueLocalStorage);
Vue.use(VueSanitize);

// Directives
Vue.directive('clipboard', VueClipboard);
Vue.http.options.root = process.env.VUE_APP_API_URL;

// NOTE: for `websender/upload` - You need to update el-upload action in views/contacts/partials/ContactUpload.vue
// List of endpoints that is region based
const regionalEndpointList = [
  '^/survey-list/responses$', // /survey-list/responses
  '^/survey-list/summary$', // /survey-list/summary
  '^/short-urls$', // /short-urls
  '^/short-urls/summary$', // /short-urls/summary
  '^/short-urls/action/download$', // /short-urls/action/download
  '^/reports/sms/logs/export$', // /reports/sms/logs/export
  '^/contacts$', // /contacts
  '^/contacts/add-to-group$', // /contacts/add-to-group
  '^/contacts/([-a-zA-Z0-9]*)$', // /contacts/:msisdn
  '^/contacts/([-a-zA-Z0-9]*)/delete', // /contacts/:msisdn/delete
  '^/groups$', // /groups
  '^/groups/([-a-zA-Z0-9]*)$', // /groups/:groupId
  '^/groups/([-a-zA-Z0-9]*)/delete', // /groups/:groupId/delete
  '^/logs$', // /logs
  '^/files$', // /files
  '^/files/([-a-zA-Z0-9]*)/actions/download', // /files/:fileId/download
  '^/files/([-a-zA-Z0-9]*)/update', // /files/:fileId/update
  '^/reports$', // /reports
  '^/reports/download$', // /reports/download
  '^/reports/operators/summary$', // /reports/operators/summary
  '^/reports/([-a-zA-Z0-9]*)/conversion$', // /reports/:userId/conversion
  '^/reports/countries|operators(?:&?[^=&]*=[^=&]*)*$', // /api/v1/reports/countries?dateFrom=2021-10-13&dateTo=2021-10-20&utcOffset=0
  '^/balance$', // /balance
  '^/reports/traffic/config', // /reports/traffic/config
  '^/contacts-management/*', // contact-management
  '^/accounts/([-a-zA-Z0-9]*)/message-balance$', // get account message balance
];

// Decode JWT data
const decodeJwt = (t) => {
  try {
    const base64Url = t.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
    const decodedData = JSON.parse(jsonPayload);
    return decodedData;
  } catch (err) {
    return 0;
  }
};

Vue.http.interceptor.before = (request) => {
  try {
    const { url, root } = request;
    const noQueryUrl = `/${url.split('?')[0]}`; // Only extract the endpoints
    const isGeoApi = regionalEndpointList.find(apiRegExp => new RegExp(apiRegExp).test(noQueryUrl));
    // Determine if endpoint is part of `regionalEndpointList`
    if (isGeoApi) {
      let regionId = '';
      // Decode jwt and determine region based on RegionId
      const token = localStorage.getItem('WWW-Authenticate');
      const decodedData = decodeJwt(token);
      if (decodedData && Object.keys(decodedData).includes('RegionId')) {
        const { RegionId: region } = decodedData;
        regionId = region;

        if (![0, 1, 2].includes(regionId)) {
          if (window.Bugsnag) {
            window.Bugsnag.notify(new Error('Region Id is invalid or undefined'), (event) => {
              event.severity = 'error';
              event.addMetadata('user', {
                id: decodedData.UserId,
                Email: decodedData.Email,
                RegionId: decodedData.RegionId,
              });
            });
          }
        }
      }
      const apiVersion = root.includes('api/v1') ? '' : '_V2';
      // Change baseUrl based on region
      switch (regionId) {
        // Indonesia region
        case 1:
          request.root = process.env[`VUE_APP_API${apiVersion}_URL_ID`];
          break;

        // UK region
        case 2:
          request.root = process.env[`VUE_APP_API${apiVersion}_URL_UK`];
          break;

        // Default Singapore region
        default:
          break;
      }
    }
  } catch (err) {
    if (window.Bugsnag) {
      window.Bugsnag.notify(err);
    }
  }
};


Vue.http.interceptors.push(function(request) { // eslint-disable-line
  const RevokeAuth = request.headers.get('RevokeAuth');
  // Add authorization header
  if (RevokeAuth !== 'yes') {
    const token = localStorage.getItem('WWW-Authenticate');
    request.headers.set('Authorization', `Bearer ${token}`);
  }

  // return response callback
  return function(response) { // eslint-disable-line
    const authToken = response.headers.get('authorization');

    if (authToken) {
      localStorage.setItem('WWW-Authenticate', authToken);
    }
  };
});

const router = Vue.router = new VueRouter({ // eslint-disable-line
  base: '/messaging',
  mode: 'history',
  routes,
});

window.router = router;
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('WWW-Authenticate');

  // Prevent rerouting if app is unmounted
  if (!token || !window.location.pathname.match(/^\/messaging/i)) {
    return 0;
  }
  next();
  return 0;
});

router.afterEach(() => {});
sync(store, router);
// eslint-disable-next-line
__webpack_public_path__ = process.env.VUE_APP_BASE_URL
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#messaging',
    i18n,
    router,
    store,
    render(h) {
      // check root config for locale messages in main portal
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });


      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });

      const { localeMessages, langUtil, appConfig } = this;

      // Assign appConfig so it is accessible to all components
      Vue.prototype.appConfig = appConfig;

      return h(AppView, { props: { localeMessages, langUtil, appConfig } });
    },
    setPublicPath(path) {
      // eslint-disable-next-line
      __webpack_public_path__ = path
    },
    localStorage: {
      timezones: {
        type: Array,
        default: [],
      },
      countries: {
        type: Array,
        default: [],
      },
      reports: {
        type: Object,
        default: {},
      },
      apikeys: {
        type: Object,
        default: {},
      },
    },
  },
});

export const bootstrap = [
  vueLifecycles.bootstrap,
];

export const mount = [
  vueLifecycles.mount,
];

export const unmount = [
  vueLifecycles.unmount,
];
