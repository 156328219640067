import Vue from 'vue';

export default {
  setFilterString({ commit, dispatch }, payload) {
    commit('SET_FILTER_STRING', payload);
    dispatch('filterUsers');
  },

  getUsers({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'users',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_USERS', response.body);
          commit('SET_FILTERED_USERS', response.body);
          dispatch('filterUsers');
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  filterUsers({ state, commit }) {
    const { users, filterString } = state;
    const s = filterString.toLowerCase().trim();
    const fu = users.filter(user => user.Login.toLowerCase().includes(s)
      || (`${user.Firstname || ''} ${user.Lastname || ''}`).toLowerCase().includes(s));
    commit('SET_FILTERED_USERS', s.length ? fu : users);
  },

  getAllModules({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: 'users/roles?uid=&format=true',
        method: 'GET',
      })
        .then((response) => {
          commit('SET_ALL_MODULES', response.body);
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getSubAccounts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `accounts/${payload.AccountUid}/sub-accounts`,
        method: 'GET',
      })
        .then((response) => {
          const data = response.body;
          commit('SET_SUB_ACCOUNTS', data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getCurrentUser({ commit }, payload) {
    commit('SET_LOADING_CURRENT_USER', true);
    commit('SET_CURRENT_USER', {
      ...payload,
      NewRoles: null,
    });

    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.UserId}/roles`,
        method: 'GET',
      })
        .then((response) => {
          commit('SET_CURRENT_USER', {
            ...payload,
            ...response.body,
          });
          commit('SET_LOADING_CURRENT_USER', false);
          resolve({
            ...payload,
            ...response.body,
          });
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          commit('SET_LOADING_CURRENT_USER', false);
          reject(err);
        });
    });
  },

  updateUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `portal-users/${payload.userId}`,
        method: 'PUT',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  inviteUsers({ commit }, payload) {
    commit('SET_LOADING_SEND', true);
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.UserId}/invites`,
        method: 'POST',
        body: payload.Body,
      })
        .then((response) => {
          resolve(response.body);
          commit('SET_LOADING_SEND', false);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          commit('SET_LOADING_SEND', false);
          reject(err);
        });
    });
  },

  resendInvitation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload.userId}/resend-invite`,
        method: 'POST',
        body: payload.data,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  deleteUser({ commit, dispatch }, payload) {
    commit('SET_LOADING_CHANGE', true);
    return new Promise((resolve, reject) => {
      Vue.http({
        url: `users/${payload}`,
        method: 'DELETE',
      })
        .then((response) => {
          dispatch('getUsers').then(() => {
            commit('SET_LOADING_CHANGE', false);
            resolve(response.body);
          });
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};
