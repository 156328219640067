<template>
  <div>
    <el-table
      ref="table"
      v-loading="loading"
      class="dt__table full-width"
      fit
      :data="renderTable"
      :empty-text="emptyText || $t('app_labels.no_data')"
      :row-class-name="`sender-id-unlinked ${rowClassName}`">
      <el-table-column
        show-overflow-tooltip
        prop="SenderId"
        label="Sender ID"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.SenderId }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="Se"
        label="Destination"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            <span
              :class="`mr-2 flag-container flag-icon flag-icon-${scope.row.Country.toLowerCase()}`" />
            <span>{{ getCountryName(scope.row.Country) }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="OperatorName"
        label="Operator"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.OperatorName }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="SenderIdStatusId"
        label="Registration status"
        :render-header="renderLabel"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            <span
              :class="`status ${getStatusCategory(
                scope.row.SenderIdStatusId
              ).classText}`">{{ getStatusCategory(scope.row.SenderIdStatusId).displayText }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="RequestedAt"
        label="Request date"
        :render-header="renderLabel"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ formatDate(scope.row.RequestedAt) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="RequestedAt"
        label="Registration date"
        :render-header="renderLabel"
        sortable
        :min-width="100">
        <template slot-scope="scope">
          <div class="truncate">
            {{ formatDate(scope.row.RegisteredAt) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="ValidityEndAt"
        label="Valid till"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ formatDate(scope.row.ValidityEndAt) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="SubAccountId"
        label="Subaccount"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.SubAccountId }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="Category"
        label="Category"
        sortable>
        <template slot-scope="scope">
          <div class="truncate">
            {{ scope.row.Category }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="TicketNumber"
        label="Action"
        sortable>
        <template slot-scope="scope">
          <div
            v-if="scope.row.SenderIdStatusId === 1"
            class="el-button el-button--text el-button--small loa-actions"
            @click="openSigningModal(scope.row.TicketNumber)">
            Sign LOA
          </div>
          <div
            v-else-if="[2, 3].includes(scope.row.SenderIdStatusId)"
            class="el-button el-button--text el-button--small loa-actions"
            @click="goToViewLOA(scope.row.TicketNumber)">
            View LOA
          </div>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="TicketNumber"
        label="">
        <template slot-scope="scope">
          <div
            class="view-ticket el-button el-button--text el-button--small loa-actions"
            @click="ticketLink(scope.row.TicketNumber)">
            View ticket
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="bg-white pagination">
      <div class="text-right p-2">
        <div
          class="bg-white flex justify-between items-center text-xs leading-none">
          <div class="text-base">
            <span> {{ $t("app_labels.rows_per_page") }}:</span>
            <el-select
              v-model="paginationDef.pageSize"
              class="ml-3"
              style="width: 65px"
              @change="handleSizeChange">
              <el-option
                v-for="page in paginationDef.pageSizes"
                :key="page"
                :label="page"
                :value="page" />
            </el-select>
          </div>
          <div class="flex items-center">
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base"
              :class="{
                'cursor-not-allowed text-grey disabled disable-events':
                  disabledBtn('prev'),
              }"
              @click.prevent="handleCurrentChange('prev')">
              <i class="icon-novo-arrow-left-long mr-2" />
              <span class="capitalize">{{ $t("actions.prev") }}</span>
            </a>
            <div class="text-base mr-3 ml-3">
              <el-select
                v-model="paginationDef.currentPage"
                style="width: 65px"
                @change="handleSelectPage">
                <el-option
                  v-for="page in lastPage"
                  :key="page"
                  :label="page"
                  :value="page" />
              </el-select>
              {{
                $t("app_labels.of_item_pages", {
                  item: lastPage? lastPage.toString():1,
                })
              }}
            </div>
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
              :class="{
                'cursor-not-allowed text-grey disabled': disabledBtn('next'),
              }"
              @click.prevent="handleCurrentChange('next')">
              <span class="capitalize">{{ $t("actions.next") }}</span>
              <i class="ml-2 icon-novo-arrow-right-long" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <SignLoaDialog
      :dialog-title="dialogTitle"
      :dialog-visible="dialogVisible"
      :ticket-number="ticketNumber"
      @reload-by-wet-signature="handleReload"
      @close-dialog="() => { dialogVisible = false }" />
  </div>
</template>

<script>
import _ from "lodash"; // eslint-disable-line
import { mapActions, mapGetters } from 'vuex';
import { getName } from 'country-list';
import moment from 'moment';
import SignLoaDialog from './registration/loa/SignLoaDialog.vue';
import tablesMixins from '../../mixins/tables';

const dateFormat = 'MM-DD-YYYY';
export default {
  name: 'SenderIdDashboard',
  components: {
    SignLoaDialog,
  },

  mixins: [
    tablesMixins,
  ],
  props: {
    rowClassName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },

    tableData: {
      type: Function,
      default: () => {},
    },

    dataSize: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      paginationDef: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        currentPage: 1,
      },
      pageSize: 10,
      currentPage: 1,
      total: 0,
      selected: [],
      renderTable: [],
      dialogVisible: false,
      ticketNumber: null,
      truncateTexts: {},
    };
  },

  computed: {
    ...mapGetters({
      countries: 'country/getCountriesSupported',
      statuses: 'senderIds/getStatuses',
    }),

    lastPage() {
      return Math.ceil(
        this.dataSize / this.paginationDef.pageSize,
        10,
      );
    },
  },

  watch: {
    tableData(val) {
      this.filterRenderTable(val);
    },
  },

  methods: {
    ...mapActions({
      getSignatureMethods: 'senderIds/getSignatureMethods',
      getLoaDocumentPreview: 'senderIds/getLoaDocumentPreview',
    }),

    handleReload() {
      this.$emit('reload-by-wet-signature');
    },

    disabledBtn(action) {
      if (this.loading) {
        return true;
      }

      if (action === 'prev' && this.paginationDef.currentPage < 2) {
        return true;
      }

      if (action === 'next' && this.paginationDef.currentPage >= this.lastPage) {
        return true;
      }

      return false;
    },

    filterRenderTable(val) {
      const { pageSize, currentPage } = this.paginationDef;
      const skipIndex = pageSize * (currentPage - 1);
      this.renderTable = val
        .sort((a, b) => a.order - b.order)
        .filter((x, index) => index >= skipIndex)
        .filter((x, index) => index < pageSize);
    },

    handleSelectPage(page) {
      this.$refs.table.clearSelection();
      this.paginationDef.currentPage = page;
      this.filterRenderTable(this.tableData);
    },

    handleCurrentChange(action) {
      this.$refs.table.clearSelection();

      if (action === 'next' && !this.disabledBtn('next')) {
        this.paginationDef.currentPage = this.paginationDef.currentPage + 1;
      }

      if (action === 'prev' && this.paginationDef.currentPage && !this.disabledBtn('prev')) {
        this.paginationDef.currentPage = this.paginationDef.currentPage - 1;
      }
      this.filterRenderTable(this.tableData);
    },

    handleSizeChange(size) {
      this.paginationDef.pageSize = size;
      this.filterRenderTable(this.tableData);
    },

    getStatusCategory(SenderIdStatusId) {
      if (SenderIdStatusId === 0) return { classText: 'in-review', displayText: 'Under review' };
      if (SenderIdStatusId === 1) return { classText: 'loa-ready', displayText: 'Ready for LOA' };
      if (SenderIdStatusId === 2) return { classText: 'in-progress', displayText: 'In-progress' };
      if (this.statuses.length < 1) return { classText: '-', displayText: '-' };
      if (![3, 4, 5, 6, 7].includes(SenderIdStatusId)) return { classText: '-', displayText: '-' };
      const st = this.statuses.find(x => x.SenderIdStatusId === SenderIdStatusId);
      if (!st) return { classText: '-', displayText: '-' };
      return { classText: st.StatusCategory.toLowerCase(), displayText: st.StatusCategory };
    },

    getCountryName(code) {
      return getName(code);
    },

    formatDate(date) {
      if (!date) {
        return '-';
      }

      return moment(date).format(dateFormat);
    },

    ticketLink(id) {
      window.location.href = `/support/tickets/${id || '00000'}/conversation`;
    },

    async openSigningModal(ticketId) {
      this.dialogVisible = true;
      this.ticketNumber = ticketId;
    },

    async goToViewLOA(ticketId) {
      try {
        this.loading = true; // eslint-disable-line
        const signedLoa = await this.getLoaDocumentPreview({ ticketId });
        const routeData = this.$router.resolve({ name: 'sender-id-loa-view' });
        localStorage.setItem('filetype', 'pdf');
        localStorage.setItem('view-loa', signedLoa.preview);
        localStorage.setItem('loaDocumentName', `LOA_${ticketId}`);
        window.open(routeData.href, '_blank');
      } catch (ex) {
        if (window.Bugsnag) {
          window.Bugsnag.notify(
            ex,
            (event) => {
              event.severity = 'error';
              event.addMetadata('details', {
                message: ex,
                description: 'Failed to view LOA file',
                ticketId,
              });
            },
          );
        }
        this.$notify({
          message: 'Unable to view LOA. Please try again later.',
          type: 'warning',
        });
      } finally {
        this.loading = false; // eslint-disable-line
      }
    },
  },
};
</script>
<style lang="scss">
.test-w {
  width: 40px;
}
.sender-id-unlinked {
  .view-ticket {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #0056E0;
    opacity: 0;
  }

  &:hover {
    .view-ticket {
      opacity: 1;
    }
  }
}

.sender-id-table {
  .el-table th>.cell {
    display: flex !important;
    align-items: center;
    justify-content: left;
    padding-right: 0px !important;
  }

  .pagination {
    border-radius: 0px 0px 8px 8px;
  }
}
.status {
  &.in-review {
    &.truncate {
      color: #ffffff !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.bg-red {
  background: red !important;
}

.status {
  padding: 2px 8px;
  gap: 4px;
  height: 24px;
  border-radius: 15px;
  // display: grid;
  // place-items: center;

  &.registered {
    background: #d2f3e1 !important;
    border: #d2f3e1 !important;
    outline: #d2f3e1 !important;
  }

  &.in-review {
    background: #666666 !important;
    border: #666666 !important;
    outline: #666666 !important;
    color: #ffffff !important;

    &.truncate {
      color: #ffffff !important;
    }
  }

  &.loa-ready {
    background: #caddf8 !important;
    border: #caddf8 !important;
    outline: #caddf8 !important;
  }

  &.in-progress {
    background: #feefd1 !important;
    border: #feefd1 !important;
    outline: #feefd1 !important;
  }

  &.rejected {
    background: #fed9d8 !important;
    border-color: #fed9d8 !important;
    outline-color: #fed9d8 !important;
  }

  &.cancelled{
    background: #e0e0e0 !important;
    border-color: #e0e0e0 !important;
    outline-color: #e0e0e0 !important;
  }

  &.blocked,
  &.blacklisted {
    background: #000 !important;
    color: #fff;
  }
}
.tabs {
  width: 150px;
}
.sid-bg {
  background-color: var(--body-background);
}
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}
.loa-actions {
  color: #0056e1 !important;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}
</style>
