<template>
  <div
    v-loading="loading"
    class="page-bg h-full">
    <el-row>
      <el-col :span="12">
        <div class="page-title">
          <h1 class="text-xl text-color font-semibold page-title mb-4 mt-4">
            Register new Sender ID
          </h1>
          <span
            class="text-base icon-novo-support cursor-pointer"
            @click="showModalInfo = true" />
        </div>
        <el-dialog
          :visible="showModalInfo"
          width="500px"
          @close="showModalInfo = false">
          <template slot="title">
            <div class="flex items-center">
              <span class="text-xl icon-novo-info-circle mr-2" />
              <h2>Info</h2>
            </div>
          </template>
          <div class="content">
            <h2 class="mb-5">
              Register new Sender ID
            </h2>
            <p class="mb-5">
              This form is to request for a customized sender ID.
            </p>
            <p
              class="mb-5 no-break"
              style="line-height: 1.625;">
              When this form is sent, 8x8 will begin the registration process.
              There will be a letter of Agreement sent to you shortly (please check Ticket History to track your request progress)
            </p>
            <p class="mb-5 no-break">
              The whole registration will take anywhere between 7-21 working days.
            </p>
            <p>
              NOTE: For more information on Sender ID, read this <a
                href="/"
                class="no-underline text-blue">article</a>.
            </p>
          </div>
          <span
            slot="footer"
            class="dialog-footer">
            <el-button
              type="primary"
              @click="showModalInfo = false">Close</el-button>
          </span>
        </el-dialog>
      </el-col>
      <el-col
        :span="12"
        style="padding-top: 10px; padding-bottom: 10px;">
        <Steps :items="steps" />
      </el-col>
    </el-row>
    <el-container class="form-content">
      <el-main
        v-loading="loading">
        <!-- Add country and company details -->
        <div
          v-if="step === 1"
          class="flex flex-col h-full justify-between">
          <div>
            <Country
              :form-data="draftForm"
              @update="updateDraft"
              @show-warning="handleShowWarning"
              @set-loading="setLoading" />
            <el-divider v-if="showCompanySection" />
            <Company
              v-if="showCompanySection"
              :form-data="draftForm"
              @update="updateDraft"
              @set-loading="setLoading" />
          </div>

          <div class="mt-5 mb-4">
            <Alert
              v-if="isDraft && showWarning"
              type="warning">
              <span>You are making changes to the draft which cannot be overwritten in the current draft. A new draft will be created for this request.</span>
            </Alert>
            <Alert
              v-if="isNotDraftAlert"
              type="info">
              <span>Sender ID registration takes anywhere between 15-20 working days. The time required to process varies between countries. For more information on country specific regulations, read this <a>article.</a></span>
            </Alert>
          </div>
        </div>

        <!-- Add attachments -->
        <div
          v-if="step === 2"
          class="documents-container">
          <Documents
            :form-data="draftForm"
            @attachments="handleAttachments"
            @continue="handleContinue"
            @is-incomplete="handleAttachmentsIncomplete" />
        </div>

        <!-- Add sender ids -->
        <SenderID
          v-if="step === 3"
          :form-data="draftForm"
          @update="updateDraft"
          @set-loading="setLoading" />

        <!-- Review details -->
        <Review
          v-if="step === 4"
          :form-data="draftForm"
          @finish-review="finishReview" />
      </el-main>
      <el-footer class="form-footer">
        <el-button
          :disabled="disableSaveDraft()"
          @click="handleSaveDraft()">
          Save as draft
        </el-button>
        <div class="flex justify-between items-center gap-x-10">
          <el-button
            type="text"
            @click="handleCancel">
            Cancel
          </el-button>
          <el-button
            v-if="step !== 1"
            @click="handleBack()">
            Back
          </el-button>
          <el-button
            type="primary"
            :disabled="isContinueDisabled"
            @click="handleContinue()">
            {{ step === 4 ? 'Confirm and submit' : 'Continue' }}
          </el-button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Country from './company/Country.vue';
import Company from './company/Company.vue';
import Documents from './documents';
import SenderID from './sender-id/index.vue';
import Review from './review/index.vue';
import Steps from './components/Steps.vue';
import Alert from './components/Alert.vue';

export default {
  name: 'HomeComponent',
  components: {
    Country,
    Company,
    Documents,
    SenderID,
    Review,
    Steps,
    Alert,
  },

  props: {
    draftKey: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    const defaultSteps = [
      {
        id: 0, name: 'Sender ID', active: false, done: true,
      },
      {
        id: 1, name: 'Details', active: true, done: false,
      },
      {
        id: 2, name: 'Documents', active: false, done: false,
      },
      {
        id: 3, name: 'Sender ID', active: false, done: false,
      },
      {
        id: 4, name: 'Review', active: false, done: false,
      },
    ];

    return {
      isScriptLoaded: false,
      isChatWindowHide: true,
      isShowChat: false,
      loading: false,
      guidelines: '',
      faqs: '',
      countryNames: [],
      tooltip: '',
      step: 1,
      steps: JSON.parse(JSON.stringify(defaultSteps.slice(1, defaultSteps.length))), // deep copy
      showModalInfo: false,
      showWarning: false,
      draftForm: {},
      accountId: '',
      userId: '',
      userEmail: '',
      isDraft: false,
      draftId: '',
      incompleteAttachments: true,
      hasReviewed: false,
    };
  },
  computed: {
    ...mapGetters({
      country: 'country/getCountry',
      countries: 'country/getCountriesSupported',
      user: 'user/getUser',
      form: 'senderIds/getForm', // Registration data
      countryCustomFields: 'country/getCustomFields',
      companyFormType: 'country/getCompanyFormId',
      subAccounts: 'common/getSubAccounts',
    }),

    isContinueDisabled() {
      return this.disableContinue();
    },

    isNotDraftAlert() {
      return !this.isDraft && this.draftForm.company && Object.keys(this.draftForm.company).length;
    },

    showCompanySection() {
      return this.draftForm.country
      && this.draftForm.country.name
      && this.draftForm.country.headquarter
      && this.draftForm.country.industry
      && this.companyFormType !== '';
    },
  },

  watch: {
    step(newVal, oldVal) {
      if (oldVal > newVal && newVal > 0) {
        // Backwards
        this.steps[oldVal - 1].active = false;
        this.steps[oldVal - 1].done = false;
        this.steps[newVal - 1].active = true;
        this.steps[newVal - 1].done = false;
      } else if (newVal <= this.steps.length) {
        this.steps[newVal - 1].active = true;
        this.steps[newVal - 2].done = true;
      }
    },
  },

  async created() {
    try {
      const { id } = this.$route.params;
      const user = this.$auth.user();

      this.draftForm = this.form;
      this.loading = true;

      // This is a draft
      if (id) {
        this.draftId = id;
        const { data: draft } = await this.getDraft({
          accountId: user.AccountId,
          draftId: this.draftId,
        });

        if (draft) {
          this.isDraft = true;
          this.draftForm = draft;
          this.step = this.draftForm.step;

          // Handle steps
          let ctr = 1;
          while (ctr < this.step) {
            this.steps[ctr - 1].done = true;
            this.steps[ctr - 1].active = false;
            ctr += 1;
          }

          const countries = await this.getCountriesSupported();
          if (countries) {
            const { country: { code, headquarter } } = draft;
            const country = countries.find(c => c.code === code);
            this.setCountry(country);
            this.setCompanyFormId(headquarter);
          }
        }
      }
    } catch (err) {
      this.$showError(this, err);
    } finally {
      this.loading = false;
    }
  },

  mounted() {
    const user = this.$auth.user();
    this.accountId = user.AccountId;
    this.userEmail = user.Login;
    this.userId = user.UserId;
    this.getCountriesSupported().then((countries) => {
      if (countries) {
        this.loading = false;
        this.countryNames = countries.map(c => c.name);
      }
    });
    this.getUserSubAccounts(user);
  },

  beforeDestroy() {
    if (window.zE) {
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'hide');
    }
    this.isShowChat = false;
  },

  methods: {
    ...mapMutations({
      updateForm: 'senderIds/UPDATE_FORM',
      setCountriesSupported: 'country/SET_COUNTRIES_SUPPORTED',
      setCompanyFormId: 'country/SET_COMPANY_FORM_ID',
    }),

    ...mapActions({
      getCountriesSupported: 'country/getCountriesSupported',
      createRequest: 'articles/createRequest',
      getArticle: 'articles/getArticle',
      getUserSubAccounts: 'common/getSubAccounts',
      setCountry: 'country/setCountry',
      uploadDocument: 'senderIds/uploadDocument',
      getDocumentPreview: 'senderIds/getDocumentPreview',
      addSenderIdRegistration: 'senderIds/addSenderIdRegistration',
      saveSubmission: 'senderIds/saveSubmission',
      saveDraft: 'senderIds/saveDraft',
      getDraft: 'senderIds/getDraft',
      deleteDraft: 'senderIds/deleteDraft',
      uploadFile: 'tickets/uploadFile',
    }),

    handleCancel() {
      window.location.href = '/messaging/sender-id';
    },

    setLoading(value) {
      this.loading = value;
    },

    handleShowWarning(value) {
      this.showWarning = value;
    },

    disableSaveDraft() {
      if (this.draftForm && this.draftForm.company && this.draftForm.company.fields && this.draftForm.company.fields.length > 0) {
        return false;
      }
      return true;
    },

    disableContinue() {
      let validForm = false;

      if (this.step === 1) {
        const validCountry = (Object.keys(this.draftForm).length > 0
            && this.draftForm.country
            && this.draftForm.country.name
            && this.draftForm.country.headquarter
            && this.draftForm.country.industry);

        const validCompany = (Object.keys(this.draftForm).length > 0
            && this.draftForm.company
            && this.draftForm.company.fields
            && this.draftForm.company.fields.length > 0);

        validForm = validCountry && validCompany;
      } else if (this.step === 2) {
        const validDocuments = !this.incompleteAttachments;

        validForm = validDocuments;
      } else if (this.step === 3) {
        const validSenderIds = (Object.keys(this.draftForm).length > 0
            && this.draftForm.senderIds
            && this.draftForm.senderIds.length > 0);

        validForm = validSenderIds;
      } else if (this.step === 4) {
        // Valid once the user has scrolled until the bottom of the review page
        validForm = this.hasReviewed;
      }

      return !validForm && (this.step <= this.steps.length);
    },

    finishReview(value) {
      this.hasReviewed = value;
    },

    handleAttachments(data) {
      const { action, file, id } = data;

      let updatedProp = [];
      if (this.draftForm.documents && this.draftForm.documents.length) {
        updatedProp = [...this.draftForm.documents];
      }
      if (action === 'add') {
        updatedProp.push(file);
      }
      if (action === 'remove') {
        updatedProp = this.draftForm.documents.filter(doc => doc.id !== id);
      }
      this.draftForm = { ...this.draftForm, documents: updatedProp };
    },

    handleAttachmentsIncomplete(val) {
      // this.isContinueDisabled = val;
      this.incompleteAttachments = val;
    },

    handleAddSenderID(data) {
      const { action, value, id } = data;
      let updatedProp = this.draftForm.senderIds || [];

      if (action === 'add') {
        updatedProp.push(value);
      }

      if (action === 'edit') {
        updatedProp = this.draftForm.senderIds.map((s) => {
          if (s.id === id) {
            return value;
          }
          return s;
        });
      }

      if (action === 'delete') {
        updatedProp = this.draftForm.senderIds.filter(s => s.id !== id);
      }

      this.draftForm = { ...this.draftForm, senderIds: updatedProp };
    },

    gotoSupportTickets() {
      window.location.href = '/support/tickets';
    },

    updateDraft(value) {
      const { key, data } = value;

      if (key === 'senderIds') {
        this.handleAddSenderID(data);
      } else if (Object.keys(data) && Object.keys(data).length > 0) {
        const updatedProp = { ...this.draftForm[key], ...data };
        const updatedObject = { [key]: updatedProp };

        this.draftForm = { ...this.draftForm, ...updatedObject };
      } else {
        const updatedProp = data;
        const updatedObject = { [key]: updatedProp };

        this.draftForm = { ...this.draftForm, ...updatedObject };
      }
    },

    handleContinue() {
      if (this.step === 1) {
        this.step = this.step + 1;
      } else if (this.step === 2) {
        this.step = this.step + 1;
      } else if (this.step === 3) {
        this.step = this.step + 1;
      } else if (this.step === 4) {
        this.handleSubmit();
      }
    },

    async handleSubmit() {
      const { forms } = this.country;
      const { documents, senderIds } = this.draftForm;

      this.loading = true;

      try {
      // Upload company documents to S3
        if (documents && documents.length > 0) {
          await this.uploadDocuments(documents);
        } else {
          this.draftForm = { ...this.draftForm, documents: [] };
        }

        // Upload Sender ID attachments to S3
        if (senderIds) {
          const updatedSenderIds = await this.uploadSenderIdAttachments(senderIds);

          this.draftForm = { ...this.draftForm, senderIds: updatedSenderIds };
        }

        // Upload Sender ID attachments to Zendesk
        const updatedSenderIds = await Promise.all(this.draftForm.senderIds.map(async (senderId) => {
          const attachments = senderId.fields.filter(f => f.id === 'attachmentkey');
          if (attachments.length > 0 && attachments[0].value) {
            const updatedSenderIdAttachments = await this.uploadToZendesk(attachments[0].value);
            let updatedFields = senderId.fields;
            const estimationField = updatedFields.filter(f => f.name === 'Monthly Traffic Estimation')[0];

            if (estimationField) {
              updatedFields = updatedFields.filter(f => f.name !== 'Monthly Traffic Estimation');
              const newValue = Number(estimationField.value);
              updatedFields.push({
                ...estimationField,
                rawValue: newValue,
                value: newValue,
              });
            }

            const index = updatedFields.findIndex(obj => obj.id === 'attachmentkey');
            if (index !== -1) {
              updatedFields[index] = {
                ...updatedFields[index],
                rawValue: updatedSenderIdAttachments,
                value: updatedSenderIdAttachments,
              };
            }

            return {
              ...senderId,
              fields: updatedFields,
            };
          }
          return senderId;
        }));

        // Upload company documents to Zendesk
        const updatedCompanyDocuments = await this.uploadToZendesk(this.draftForm.documents);

        // Remove previews
        const docsWithoutPreview = updatedCompanyDocuments.map((doc) => {
          delete doc.preview;
          return doc;
        });

        // Update form with updated documents and sender ids
        this.draftForm = {
          ...this.draftForm,
          userId: this.userId,
          userEmail: this.userEmail,
          documents: docsWithoutPreview,
          senderIds: updatedSenderIds,
        };

        // Save to S3
        await this.saveSubmission({
          accountId: this.accountId,
          data: {
            formId: forms[this.companyFormType],
            data: this.draftForm,
          },
        });

        if (this.draftId) {
          await this.deleteDraft({
            accountId: this.accountId,
            draftId: this.draftId,
          });
        }

        this.$notify({
          type: 'success',
          message: 'Sender ID registration is submitted successfully',
        });

        this.loading = false;
        window.location.href = '/messaging/sender-id';
      } catch (error) {
        this.$showError(this, error);
      }
    },

    handleBack() {
      this.step = this.step - 1;
    },

    // Update form
    async handleSaveDraft() {
      try {
        this.loading = true;
        const {
          country, company, documents, senderIds,
        } = this.draftForm;

        // Upload documents
        if (documents && documents.length > 0) {
          await this.uploadDocuments(documents);
        }

        // Upload sender id attachments to S3
        if (senderIds) {
          const updatedSenderIds = await this.uploadSenderIdAttachments(senderIds);

          this.draftForm = { ...this.draftForm, senderIds: updatedSenderIds };
        }

        // Save draft to S3
        let companyName = '';
        if (company && company.fields.length > 0) {
          const companyNameField = company.fields.filter(c => c.name === 'Company Name')[0];
          companyName = companyNameField.value;
        }

        const key = `${country.code}_${country.headquarter}_${country.industry.id.replace(/ /g, '-')}_${companyName.replace(/ /g, '-')}`;

        delete this.draftForm.step;
        await this.saveDraft({
          accountId: this.accountId,
          data: {
            id: key,
            data: {
              id: key,
              step: this.step,
              ...this.draftForm,
            },
          },
        });

        this.$notify({
          type: 'success',
          message: 'Draft saved successfully',
        });

        window.location.href = '/messaging/sender-id?tab=drafts';
      } catch (error) {
        this.$showError(this, error);
      } finally {
        this.loading = false;
      }
    },

    async uploadToZendesk(attachments) {
      const updatedAttachments = await Promise.all(attachments.map(async (attachment) => {
        const { filename, key, type } = attachment;
        const { preview } = await this.getDocumentPreview({ key });
        const response = await fetch(preview);
        const blob = await response.blob();
        const file = new File([blob], filename, { lastModified: Date.now(), type });
        const { token } = await this.uploadFile({ file });

        return {
          token,
          ...attachment,
        };
      }));

      return updatedAttachments;
    },

    async uploadDocuments(docs) {
      if (docs && docs.length) {
        const uploads = await Promise.all(docs.map((doc) => {
          if (doc.file) {
            return this.uploadDocument(doc);
          }
          return doc;
        }));
        const docPreviews = await Promise.all(uploads.map(async (doc) => {
          if (doc.file) {
            const preview = await this.getDocumentPreview({ ...doc, rId: this.user.RegionId });
            return {
              ...doc, ...preview, file: null, // label: 'draft',
            };
          }
          return doc;
        }));

        this.draftForm = { ...this.draftForm, documents: docPreviews };
      }
    },

    async uploadSenderIdAttachments(senderIds) {
      const updatedSenderIds = await Promise.all(senderIds.map(async (senderId) => {
        const attachments = senderId.fields.filter(f => f.id === 'attachmentkey');
        if (attachments.length > 0 && attachments[0].value) {
          const sidAttachments = attachments[0].value;
          const uploads = await Promise.all(sidAttachments.map(async (attachment) => {
            if (attachment.file) {
              const result = await this.uploadDocument(attachment);
              return {
                ...result,
                file: null,
              };
            }
            return attachment;
          }));

          const updatedFields = senderId.fields;

          const index = updatedFields.findIndex(obj => obj.id === 'attachmentkey');
          if (index !== -1) {
            updatedFields[index] = {
              ...updatedFields[index],
              rawValue: uploads,
              value: uploads,
            };
          }

          return {
            ...senderId,
            data: {
              ...senderId.data,
              'Attachments': uploads,
            },
            fields: updatedFields,
          };
        }
        return senderId;
      }));

      return updatedSenderIds;
    },
  },
};
</script>
<style lang="scss">
  .company-dialog {
    .el-dialog__body {
      padding: 24px !important;
    }
  }
  .el-alert__description {
    a {
      color: #0056e0 !important;
    }
  }
  .article a {
    color: #0056e0 !important;
  }

  .collapsible {
    .el-collapse-item__header {
      padding: 0 20px 0 20px;
      color: #171817;
    }

    .el-collapse-item__content {
      padding-bottom: 15px;
      border: none;
      color: #171817;
    }

    .el-collapse-item__header,
    .el-collapse-item__wrap {
      border-left: 1px solid #dae1e7;
      border-right: 1px solid #dae1e7;
    }
  }

  .guidelines {
    color: #171817;
    padding: 0 20px;

    h3 {
      padding: 3px 0;
      margin-bottom: 10px;

      span {
        text-decoration: none;
      }
    }

    ul,
    ol {
      padding: 0px 20px 20px 40px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .faqs {
    padding: 0;
    border: none;

    p {
      margin-bottom: 0;
      padding: 0;
      text-align: justify;
    }
  }

  .next-btn {
    text-decoration: none;
  }

  .warning-alert {
    .el-alert__content .el-alert__title {
      font-size: 15px;
    }
  }

  .support-icons {
    font-size: 32px;
  }

  button.custom-launcher {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 999999999999999999999999999999999999999;
    height: 45px;
    width: 100px;
    background-color: #CB2232;
    color: white;
    border-radius: 5rem;
    padding: 10px;
    text-align: center;
    display: block;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border: none;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);


    span {
      vertical-align: middle;
    }
  }

  button.custom-launcher:focus {
    outline: none !important;
  }

  button.custom-launcher:hover {
    transform: scale(1.1, 1.1);
  }

  .bg-icon {
    z-index: 0;
    user-select: none;
  }

  .article-icon {
    font-size: 300px;
    left: -49px;
    top: -51px;
  }

  .col-card {
    height: 7rem;
  }

  .row-card {
    height: 15rem;
  }

  .row-icon {
    font-size: 255px;
    right: -64px;
    top: -55px;
  }

  .page-bg {
    background-color: var(--body-background);
  }

  .page-title {
    display: flex;
    place-items: center;
    gap: 10px;
  }

  .form-content {
    // padding: 16px;
    background-color: #fff;
    height: calc(100vh - 150px);
    box-shadow: 0px 2px 6px 0px #29292940;
    border-radius: 8px !important;
    border: 1px solid #E0E0E0;

    .el-main {
      padding: 16px 16px 0 16px !important;

      h3 {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.017em;
      }

      h4 {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.011em;
        text-align: left;
      }
    }
  }

  .form-label {
    display: flex;
    // align-items: center;
    column-gap: 5px;
  }

  .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px !important;
    border-top: 1px solid var(--body-background);
  }

  .el-dialog__header {
    border-bottom: 1px solid var(--body-background);
  }

  .el-dialog__body {
    color: #000 !important;
  }

  .el-alert--info.is-light {
    background-color: transparent !important;
    color: #666 !important;
    border: 1px solid #d9d9d9;
    border-left: 4px solid black;
  }

  .el-alert {
    padding: 10px 16px !important;
    border-radius: 4px !important;
    align-items: start !important;
  }

  .el-alert .el-alert__description {
    margin: 0 !important;
  }

  .no-break {
    white-space: normal;
    word-break: keep-all;
    overflow-wrap: break-word; /* or use word-wrap: break-word */
  }
</style>
