import Vue from 'vue';
import moment from 'moment';

export default {
  getSenderIds: async ({ commit }, payload) => new Promise((resolve, reject) => {
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/accounts/${payload}`,
      method: 'get',
    })
      .then((response) => {
        const data = response.body;
        commit('SET_SENDER_IDS', data.map((senderId, i) => ({ order: i + 1, ...senderId })));
        resolve(response);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getSenderIdStatuses: async ({ commit }) => new Promise((resolve, reject) => {
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/statuses`,
      method: 'get',
    })
      .then((response) => {
        const data = response.body;
        commit('SET_SENDER_ID_STATUSES', data);
        resolve(response);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  addSenderIdRegistration: async ({ commit }, payload) => new Promise((resolve, reject) => {
    // Check if payload is not empty before retrieving the fields for the country form
    if (Object.keys(payload).length !== 0) {
      Vue.http({
        url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration`,
        method: 'post',
        body: payload,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    }
  }),

  updateSenderIdRegistration: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { id: senderIdRegistrationId } = payload;
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/${senderIdRegistrationId}`,
      method: 'put',
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  uploadDocument: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { file, id: filename } = payload;

    const mimeType = file.type;
    if (Object.keys(payload).length !== 0) {
      const base = `${process.env.VUE_APP_API_V2_URL}sender-id/registration/documents/upload-url`;
      Vue.http({
        url: `${base}?filename=${filename}&type=${mimeType}`,
        method: 'get',
      })
        .then(async (response) => {
          const { url: uploadUrl, key } = response.body;

          const headers = {
            'Content-Type': mimeType,
            'Cache-Control': 'no-cache',
            'RevokeAuth': 'yes',
          };

          const uploadReq = {
            method: 'PUT',
            url: uploadUrl,
            body: file,
            headers,
            transformRequest: [
              (d, h) => {
                // eslint-disable-next-line no-param-reassign
                if (h.Authorization) {
                  // eslint-disable-next-line no-param-reassign
                  delete h.Authorization;
                }

                return d;
              },
            ],
          };

          Vue.http(uploadReq).then((uploadResponse) => {
            const { status } = uploadResponse;
            if (status === 200) {
              resolve({ ...payload, key });
            }
          });
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    }
  }),

  getDocumentPreview: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { key } = payload;
    if (key) {
      const url = `${process.env.VUE_APP_API_V2_URL}sender-id/registration/documents/download-url?key=${key}`;
      const reqParams = {
        url,
        method: 'GET',
      };
      Vue.http(reqParams).then(async (response) => {
        const { location } = response.body;
        resolve({ preview: location });
      }).catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
    }
  }),

  getLoaDocumentPreview: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { key } = payload;
    if (Object.keys(payload).length !== 0) {
      const url = `${process.env.VUE_APP_API_V2_URL}sender-id/registration/loa/download/${payload.ticketId}`;
      const reqParams = {
        url,
        method: 'GET',
        body: { key },
      };
      Vue.http(reqParams).then(async (response) => {
        const { location } = response.body.data;
        resolve({ preview: location });
      }).catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
    }
  }),

  deleteCompany: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { data } = payload;
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/companies`,
      method: 'post',
      body: data,
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  addCompany: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, data } = payload;
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: `sender-id/registration/${accountId}/companies`,
      method: 'post',
      body: data,
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getCompanies: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, query } = payload;
    const qs = query ? Object.keys(query).map(key => `${key}=${query[key]}`).join('&') : '';
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: `sender-id/registration/${accountId}/companies?${qs}`,
      method: 'get',
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  saveDraft: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, data } = payload;
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: `sender-id/registration/${accountId}/drafts`,
      method: 'post',
      body: data,
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getDraft: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, draftId } = payload;
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: `sender-id/registration/${accountId}/drafts?id=${draftId}`,
      method: 'get',
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getDrafts: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId } = payload;
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: `sender-id/registration/${accountId}/drafts?content=true`,
      method: 'get',
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  deleteDraft: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, draftId } = payload;
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: `sender-id/registration/${accountId}/drafts/${draftId}`,
      method: 'DELETE',
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  saveSubmission: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, data } = payload;
    Vue.http({
      root: process.env.VUE_APP_API_V2_URL,
      url: `sender-id/registration/${accountId}/submissions`,
      method: 'post',
      body: data,
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  updateSubmission: async ({ commit, dispatch }, payload) => new Promise((resolve, reject) => {
    const { updateParams, getParams } = payload;
    const { accountId } = getParams;
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/submissions/${accountId}`,
      method: 'put',
      body: updateParams,
    })
      .then(async (response) => {
        await dispatch('getSubmissionData', getParams);
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  upsertDocumentDetails: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, data, command } = payload;
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/documents-details/${accountId}`,
      method: 'post',
      body: { data, command },
    })
      .then(async (response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getDocumentDetails: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId } = payload;
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/documents-details/${accountId}`,
      method: 'get',
    })
      .then(async (response) => {
        const { data } = response.body;
        if (data) {
          const format = datetime => moment(new Date(datetime)).format('MMM D, YYYY');
          const docs = data.map(item => ({ ...item, updated: format(item.updated) }));
          commit('SET_DOCUMENT_DETAILS', docs);
        }
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  generateSigningUrl: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { data } = payload;
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/loa/generate-signing-url`,
      method: 'post',
      body: data,
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getSignatureMethods: async ({ commit }, payload) => new Promise((resolve, reject) => {
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/loa/signature-methods/${String(payload.ticketId)}`,
      method: 'get',
    })
      .then((response) => {
        const { data } = response.body;
        resolve(data);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  getSubmissionData: async ({ commit }, payload) => new Promise((resolve, reject) => {
    const { accountId, ticketId: id } = payload;
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/submissions/${accountId}?id=${id}`,
      method: 'get',
    })
      .then((response) => {
        const { data, path } = response.body;
        commit('SET_SUBMISSIONS', { data, key: path });
        resolve(data);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),

  uploadSignedLoa: async ({ commit }, payload) => new Promise((resolve, reject) => {
    Vue.http({
      url: `${process.env.VUE_APP_API_V2_URL}sender-id/registration/loa/upload/${String(payload.ticketId)}`,
      method: 'post',
    })
      .then((response) => {
        resolve(response.body);
      })
      .catch((err) => {
        commit('API_ERROR', err, { root: true });
        reject(err);
      });
  }),
};
