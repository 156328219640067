<template>
  <div class="download-sample">
    <div
      id="myModal"
      class="modal">
      <div class="modal-content">
        <div class="actions">
          <div class="btns">
            <span
              class="text-base icon-novo-download cursor-pointer icon-24"
              @click="download" />
            <span
              class="text-base icon-novo-close-large cursor-pointer icon-24"
              @click="closeTab" />
          </div>
        </div>
        <div class="dialog-body">
          <iframe
            v-if="filetype === 'pdf'"
            style="background: #FFFFFF;"
            :src="`${src}#toolbar=0&navpanes=0&scrollbar=0`"
            width="100%"
            height="100%"
            class="preview-body" />
          <img
            v-if="filetype === 'image'"
            :src="src"
            alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver';

export default {
  name: 'DownloadSample',
  data() {
    return {
      src: '',
      showPreview: true,
      filetype: 'image',
    };
  },

  mounted() {
    this.src = localStorage.getItem('sample-doc');
    this.filetype = localStorage.getItem('filetype') || 'pdf';
  },

  beforeDestroy() {
    localStorage.removeItem('sample-doc');
    localStorage.removeItem('filetype');
  },

  methods: {
    closeTab() {
      window.close();
    },

    download() {
      if (this.filetype === 'image') {
        window.location.href = this.src;
      } else {
        fetch(this.src)
          .then(response => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = localStorage.getItem('documentName');
            a['content-disposition'] = 'attachment';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((error) => {
            console.error('Failed to download file: ', error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-24 {
  font-size: 24px;
}
.modal {
  position: fixed;
  z-index: 99999;
  // padding-top: 30px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(82, 86, 89) !important;
}
.download-sample::-webkit-scrollbar {
  display: none !important;
}
.modal-content {
  margin: auto;
  background-color: transparent;
  .dialog-body {
    margin-top: 30px !important;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    width: 99%;
    position: sticky !important;
    top: 0 !important;

    .btns {
      margin-top: 30px;
      background: white;
      display: flex;
      justify-content: flex-end;
      padding: 8px;
      gap: 16px;
      border-radius: 6px;
      border: solid 1px rgb(82, 86, 89);
      position: absolute !important;
    }
  }
}

.dialog-body {
  display: flex;
  place-content: center;
  align-items: center;
  // height: 95vh;

  .preview-body {
    height: 100vh;
  }
}
</style>
