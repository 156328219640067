<template>
  <div class="documents-details">
    <div class="documents-details-filter">
      <div class="filter-container">
        <el-select
          v-model="searchString"
          filterable
          clearable
          placeholder="Search by name"
          class="w-3/5 el-select-prefix-suffix"
          :no-data-text="`We couldn’t find any results for ${searchString}`"
          @clear="handleClearSelect"
          @change="searchStringChange"
          @input.capture="searchStringCapture"
          @input.native="inputChange">
          <template slot="prefix">
            <div>
              <span class="text-2xl icon-novo-search" />
            </div>
          </template>
          <el-option
            v-for="(item, i) in searchData"
            :key="`${item.value}-${i}`"
            :value="item.value">
            <span>{{ item.label }}</span>
          </el-option>
        </el-select>
        <div class="badge-result">
          <el-badge
            v-if="badge > 0"
            :value="badge"
            type="warning">
            <i
              class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon"
              @click="showMore = !showMore" />
          </el-badge>
          <i
            v-else
            class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon"
            @click="showMore = !showMore" />
          <div class="results">
            {{ results }} results
          </div>
        </div>
      </div>
      <div
        class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
        <el-tooltip
          :content="$t('actions.refresh')"
          placement="top">
          <span
            class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
            @click="$emit('reload')" />
        </el-tooltip>
        <el-tooltip
          class="ml-4"
          content="Download"
          placement="top">
          <span
            class="icon-novo-download text-2xl cursor-pointer hover-icon"
            @click="$emit('download')" />
        </el-tooltip>
      </div>
    </div>
    <div
      v-show="showMore"
      class="expanded-filters">
      <div class="filters">
        <!-- country filter -->
        <div v-if="tab === 'sender-id-documents-details'">
          <div class="pb-1">
            Country
          </div>
          <el-select
            v-model="country"
            filterable
            clearable
            placeholder="Select country"
            @change="handleCountryChange">
            <el-option
              v-for="(item, i) in filtersData.countries"
              :key="`${item}-${i}`"
              :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </div>
        <!-- head quarter filter -->
        <div v-if="tab === 'sender-id-documents-details'">
          <div class="pb-1">
            Headquarter
          </div>
          <el-select
            v-model="headquarter"
            filterable
            clearable
            placeholder="Select headquarter"
            @change="handleHQChange">
            <el-option
              v-for="(item, i) in ['Local', 'International']"
              :key="`${item}-${i}`"
              :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </div>
        <!-- industry filter -->
        <div>
          <div class="pb-1">
            Industry
          </div>
          <el-select
            v-model="industry"
            filterable
            clearable
            placeholder="Select industry"
            @change="handleIndustryChange">
            <el-option
              v-for="(item, i) in filtersData.industries"
              :key="`${item}-${i}`"
              :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="clear-all">
        <div
          class="text-primary cursor-pointer btn-clear-all"
          @click="clearAll">
          Clear all
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'FiltersNew',

  props: {
    searchStringData: {
      type: Array,
      default: () => [],
    },

    tab: {
      type: String,
      default: '',
    },

    results: {
      type: Number,
      default: 0,
    },

    filtersData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchString: '',
      captureString: '',
      showMore: false,
      country: '',
      headquarter: '',
      industry: '',
    };
  },

  computed: {
    badge() {
      let count = 0;
      if (this.country) count += 1;
      if (this.headquarter) count += 1;
      if (this.industry) count += 1;
      return count;
    },
    searchData() {
      let data = [];
      if (this.searchString === '') {
        data = [
          {
            id: 1,
            value: '',
            label: `View all (${this.searchStringData.length})`,
          },
        ];
      } else {
        const tempSearchData = this.searchStringData.filter(item => item.value.toLowerCase().includes(this.searchString.toLowerCase()));
        if (tempSearchData.length > 1) {
          tempSearchData.unshift({
            id: 1,
            value: this.searchString,
            label: `View all result for ${this.searchString} (${tempSearchData.length})`,
          });
          data = tempSearchData;
        } else if (tempSearchData.length === 1) {
          data = tempSearchData;
        } else {
          data = [];
        }
      }
      return data;
    },
  },

  methods: {
    handleClearSelect() {
      this.searchString = '';
      this.$emit('search', { name: '' });
    },

    clearAll() {
      this.searchString = '';
      this.country = '';
      this.headquarter = '';
      this.industry = '';
      this.$emit('search', { country: '', headquarter: '', industry: '' });
    },

    searchStringCapture() {
      this.captureString = this.searchString;
    },

    searchStringChange() {
      this.$emit('search', { name: this.searchString || this.captureString });
    },
    inputChange({ target: { value: val } }) {
      this.searchString = val;
    },
    handleCountryChange(val) {
      this.$emit('search', { country: val.toLowerCase() });
    },
    handleHQChange(val) {
      this.$emit('search', { headquarter: val.toLowerCase() });
    },
    handleIndustryChange(val) {
      this.$emit('search', { industry: val.toLowerCase() });
    },
  },
};
</script>
<style lang="scss">
.documents-details-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .filter-container {
    display: flex;
    align-items: center;
    padding: 8px;
    width: 80%;
    .icon-container {
      height: 40px;
      display: grid;
      place-items: center;
    }

    .badge-result {
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }
  }
}

.documents-details {
  .expanded-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;
    .filters {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-start;
      padding: 16px 8px;
    }

    .clear-all {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;

      .btn-clear-all {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.019em;
        text-align: right;
        color: #0056E0;
      }
    }
  }
}
</style>
