<template>
  <el-dialog
    :visible="show"
    width="800px"
    @close="handleCloseDialog()">
    <template slot="title">
      <div class="flex items-center">
        <h2 class="mr-2">
          {{ title }}
        </h2>
      </div>
      <div class="text-xs text-grey-darker mt-1">
        Destination country:&nbsp;&nbsp;
        <span
          :class="`mr-2 flag-container flag-icon flag-icon-${formData.country.code.toLowerCase()}`" />
        <span>{{ formData.country.name }} ({{ formData.country.headquarter }})</span>
      </div>
    </template>
    <div class="content">
      <el-form
        ref="form"
        label-position="top"
        :model="form"
        :rules="rules"
        class="sender-id-details-form"
        @validate="onFormValidation">
        <div
          v-for="(field) in filteredFields"
          :key="field.key"
          :class="`key-${field.key} ${field.type === 'dateRange' ? 'validity': ''}`">
          <!-- if input type is Text -->
          <TextInput
            v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
            :key="field.key"
            :value="form"
            :textarea="field.type === 'textarea'"
            :field-name="field.key"
            :metadata="formData.country"
            :is-validating="validatingField === field.key && fieldHasError"
            @input="(newFormValue) => { form = newFormValue }"
            @blur="handleBlur" />

          <!-- if input type is select -->
          <SelectInput
            v-if="field.type === 'tagger'"
            :key="field.key"
            :value="form"
            :field-name="field.key"
            :metadata="formData.country"
            :disable-options="disableOptions"
            @input="(newFormValue) => { form = newFormValue }" />

          <div>
            <FileUploader
              v-if="field.type === 'uploader' && showUploader"
              ref="formUploader"
              :key="field.key"
              cls="sender-id-uploader"
              :modal-visible="false"
              :limit="requiredAttachments[formData.country.code].length"
              :file-list="attachments"
              @set-uploading="setUploading"
              @set-attachments="(newValue) => { setAttachments(newValue, field.id) }" />
            <p
              v-if="field.type === 'uploader'"
              class="text-red text-xs">
              {{ uploaderError }}
            </p>
          </div>
          <DateRangeInput
            v-if="field.type === 'dateRange'"
            :key="field.key"
            :value="form"
            :field-name="field.key"
            :metadata="formData.country"
            @input="(newFormValue) => { form = newFormValue }" />
        </div>
      </el-form>
      <div
        v-if="showUploader && attachments.length < requiredAttachments[formData.country.code].length"
        class="w-full mt-4">
        <Alert type="warning">
          <div v-if="formData.country.code === 'ID'">
            <span class="mb-2 text-bold">Documents to be attached: Per Sender ID name and category:</span>
            <ul
              v-for="file in requiredAttachments[formData.country.code]"
              :key="file">
              <li>
                {{ file }}
              </li>
            </ul>
          </div>
          <div v-if="formData.country.code === 'PH'">
            <span>Your Sender ID name should include the brand name of the company. If not, provide Intellectual Property Rights to the Brand Name OR Secretary's Certificate specifying the proof of Sender ID's relevance to the business.</span>
          </div>
          <div v-if="formData.country.code === 'SG'">
            <span class="mb-2">Documents to be attached:</span><br>
            <span>Screenshot of SGNIC Email that the Sender ID is whitelisted. You can visit
              <a
                href="https://smsregistry.sg/web/login"
                class="text-blue-light no-underline"
                target="_blank">https://smsregistry.sg/web/login</a>
              to register Sender ID on SG NIC
            </span>
          </div>
        </Alert>
      </div>
      <div
        v-if="hasFormError"
        class="mt-4">
        <Alert type="error">
          <span>To continue adding your Sender ID details, correct the error instructions.</span>
        </Alert>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer flex items-center justify-end">
      <el-button
        @click="handleCloseDialog()">Close</el-button>
      <el-button
        type="primary"
        :disabled="!formValid || hasFormError"
        @click="handleSubmit()">{{ ctaText }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextInput from '../../partials/TextInput.vue';
import SelectInput from '../../partials/SelectInput.vue';
import DateRangeInput from '../../partials/DateRangeInput.vue';
import FileUploader from '../../partials/FileUploader.vue';
import Alert from '../components/Alert.vue';
import formUtils from '../../../../mixins/formUtils';

export default {
  name: 'SenderIDForm',

  components: {
    TextInput,
    SelectInput,
    DateRangeInput,
    FileUploader,
    Alert,
  },

  mixins: [formUtils],

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },

    // Sender Id fields
    fields: {
      type: Array,
      required: true,
    },

    // List of added Sender Ids
    list: {
      type: Array,
      required: true,
    },

    operation: {
      type: String,
      required: true,
    },

    // Selected Sender Id for editing/copying
    selected: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filteredFields: [],
      loading: false,
      draftSenderIds: [],
      form: {},
      rules: {},
      attachments: [],
      showUploader: false,
      disableOptions: [],
      uploaderError: '',
      disableAddd: false,
      optionsToDisable: {},
      disableCTA: false,
      hasFormError: false,
      validatingField: false,
      ctaText: 'Add Sender ID details',
      requiredAttachments: {
        ID: [
          'Membership Flow',
          'Screenshot flow and screenshot of live application at Apple Store or Google Store',
        ],
        PH: [
          'IPR',
        ],
        SG: [
          'Screenshot of SGNIC Email',
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      dateFields: 'country/getDateFields',
    }),
  },

  watch: {
    form: {
      handler(val) {
        this.formValid = this.areRequiredFieldsNotEmpty(val, this.rules);
      },
      deep: true,
    },
    fields() {
      this.setFormRules();
    },

    draftSenderIds(list) {
      this.senderIds = list;
    },

    attachments(val) {
      if (this.showUploader) {
        if (val.length !== this.requiredAttachments[this.formData.country.code].length) {
          this.uploaderError = 'Upload the required attachments';
        } else {
          this.uploaderError = '';
        }
      } else {
        this.uploaderError = '';
      }
    },

    // When a sender id is selected for editing or copying
    selected(val) {
      this.title = this.operation === 'edit' ? 'Edit Sender ID Details' : 'Add Sender ID Details';
      this.ctaText = 'Done';

      // Populate existing form data
      this.form = {};

      const formCopy = {};
      val.fields.forEach((field) => {
        // this.$set(this.form, field.id, field.value);

        if (this.operation === 'edit') {
          formCopy[field.id] = field.value;

          if (field.id === 'attachmentkey') {
            this.showUploader = true;
            this.attachments = field.value;
          }
        } else {
          // For copy only 15658914265625
          // Populate Sender Id and Sub account fields
          if (field.name === 'Sender ID') {
            formCopy[field.id] = field.value;

            const sids = this.senderIds.filter(sid => sid['Sender ID'] === field.value);
            if (sids.length > 0) {
              sids.forEach((sid) => {
                if (sid['Message Type'] === 'OTP') {
                  this.disableOptions.push('OTP');
                }

                if (sid['Message Type'] === 'Notification') {
                  this.disableOptions.push('Notification');
                }

                if (sid['Message Type'] === 'Marketing') {
                  this.disableOptions.push('Marketing');
                }
              });
            }
          }

          if (field.name === 'Sub Account ID') {
            formCopy[field.id] = field.value;
          }
        }
      });

      if (this.operation === 'edit') {
        if (this.formData.country.code === 'PH') {
          this.fields.forEach((field) => {
            if (!formCopy[field.id]) {
              formCopy[field.id] = {
                startDate: formCopy[15658914265625],
                endDate: formCopy[15658965441177],
              };
            }
          });
        }
      }

      this.form = Object.assign({}, this.form, formCopy);
      console.log('EDITABLE FORM: ', this.form);

      // Set form rules
      // this.setFormRules();
    },
  },

  created() {
    this.senderIds = this.list;
    this.title = 'Add Sender ID Details';

    if (this.formData.country.code === 'SG' && this.formData.country.headquarter === 'local') {
      this.showUploader = true;
    }
  },

  methods: {
    ...mapActions({
      setCompanyFields: 'country/setCompanyFields',
      getCountriesSupported: 'country/getCountriesSupported',
      getArticle: 'articles/getArticle',
      getUserSubAccounts: 'common/getSubAccounts',
      getSenderIdCountryRules: 'articles/getSenderIdCountryRules',
    }),

    handleCloseDialog() {
      this.attachments = [];
      this.$refs.form.resetFields();
      this.rules = {};
      this.hasFormError = false;
      this.showUploader = false;
      this.setAttachments([]);

      this.$emit('close', true);
    },

    handleBlur(value) {
      // For PH and ID only
      // Check if company name is included in the Sender Id name
      // If Sender ID is not included, show file uploader field
      // as the user is required to attach documents
      const senderIdName = Object.values(value)[0].trim();
      const companyName = this.formData.company.fields.filter(f => f.name === 'Company Name')[0].value;
      if (!senderIdName.includes(companyName)
          && this.formData.country.code === 'PH'
          || (this.formData.country.code === 'ID'
          && this.formData.country.headquarter === 'local')) {
        this.showUploader = true;
      }

      // For ID only
      // User can register 3 Sender IDs under one Sender ID name
      // NOTE: Only 1 Sender ID name can be registered under 1 company
      // ID (international) - 1 OTP, 1 Marketing, 1 Notification
      // ID (local) - 1 Premium, 2 Regular
      this.disableOptions = [];
      if (this.formData.country.code === 'ID') {
        if (this.formData.country.headquarter === 'international') {
          // SID has been added
          const sids = this.senderIds.filter(sid => sid['Sender ID'] === senderIdName);
          if (sids.length > 0) {
            sids.forEach((sid) => {
              if (sid['Message Type'] === 'OTP') {
                this.disableOptions.push('OTP');
              }

              if (sid['Message Type'] === 'Notification') {
                this.disableOptions.push('Notification');
              }

              if (sid['Message Type'] === 'Marketing') {
                this.disableOptions.push('Marketing');
              }
            });
          } else {
            // If it is a new SID

            // if (this.senderIds.length) {
            //   this.showAlert
            // }
          }
        } else {
          // TODO local disable options
        }
      }
    },

    disableAdd() {
      if (this.formData.country.code === 'ID' && this.senderIds.length === 0) {
        return true;
      }
      return false;
    },

    setFormRules(data, hasdata = false) {
      const rules = {};
      const form = {};
      let dateRange = [];
      let senderIdRules = this.fields.filter((field) => {
        if (field.raw_title_in_portal && field.raw_title_in_portal.toLowerCase().includes('validity')) {
          if (field.type === 'dateRange') {
            dateRange = [field];
          }
          return false;
        }
        return true;
      });

      console.log('HERE IN FORM RULES: ', this.fields);

      if (hasdata) {
        senderIdRules = data;
      }

      if (dateRange.length > 0) {
        senderIdRules = [...senderIdRules, ...dateRange];
      }

      this.filteredFields = senderIdRules;

      senderIdRules.forEach((customField) => {
        form[customField.key] = '';
        rules[customField.key] = [
          {
            required: customField.required_in_portal,
            validator: (rule, value, callback) => this.validate(rule, value, callback, customField),
            trigger: 'change',
            titleInPortal: customField.title_in_portal,
            key: customField.key,
          },
        ];
      });

      if (!hasdata) {
        this.form = form;
      }
      this.rules = rules;
    },

    onFormValidation(val) {
      this.validatingField = val;
    },

    validate(rule, value, callback, customField) {
      const { customValidation } = customField;

      const errors = [];
      if (value && customValidation) {
        customValidation.forEach((field) => {
          if (!field.rule.test(value)) {
            errors.push(true);
            callback(new Error(`${field.message}`));
          } else {
            errors.push(false);
          }
        });
      }

      if (customField.raw_title_in_portal === 'Sender ID' && this.formData.country.code === 'ID') {
        if (this.senderIds.length > 0 && this.senderIds[0]['Sender ID'] !== value) {
          errors.push(true);
          callback(new Error('You are trying to register a different Sender ID name.'));
        }
      }

      if (customField.raw_title_in_portal === 'Content of Message') {
        const companyName = this.formData.company.fields.find(f => f.name === 'Company Name').value;
        console.log('COMPANY NAME: ', companyName);
        if (!value.includes(companyName)) {
          errors.push(true);
          callback(new Error('Your message must include the brand name in the content'));
        }

        if (value.length > 160) {
          errors.push(true);
          callback(new Error('Content of message should not exceed 160 characters'));
        }
      }

      if (customField.type === 'dateRange') {
        if ((!value.startDate || !value.endDate) && customField.required_in_portal) {
          errors.push(true);
          callback(new Error(`${customField.raw_title_in_portal} is required`));
        }
      }

      if (!value && customField.required_in_portal) {
        errors.push(true);
        callback(new Error(`${customField.raw_title_in_portal} is required`));
      }

      this.hasFormError = errors.reduce((acc, curr) => acc || curr, false);

      callback();
    },

    getFormRule(titleInPortal) {
      const temp = Object.keys(this.rules).map(el => this.rules[el]).filter(rule => titleInPortal === rule[0].titleInPortal);
      return temp[0][0];
    },

    setRuleRequired(titleInPortal, required) {
      const senderIdFields = this.fields.map((el) => {
        if (el.title_in_portal === titleInPortal) {
          const rule = {
            ...el,
            required_in_portal: required,
          };
          return rule;
        }
        return el;
      });

      this.setFormRules(senderIdFields, true);
    },

    generateRandomId() {
      return `_${Math.random().toString(36).substring(2, 9)}`;
    },

    convertFieldsToJson(fieldsArray) {
      const obj = {};
      fieldsArray.forEach((field) => {
        obj[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
      });
      // for (const field of fieldsArray) {
      //   obj[field.name] = field.type === 'tagger' ? field.rawValue : field.value;
      // }

      obj.id = `${obj['Sender ID']}${this.generateRandomId()}`;
      return obj;
    },

    setAttachments(attachments, id) {
      this.attachments = attachments;
      const formCopy = this.form;
      formCopy[id] = attachments;

      this.form = Object.assign({}, this.form, formCopy);
      this.$set(this.form, id, attachments);
    },

    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (this.showUploader && this.attachments.length < this.requiredAttachments[this.formData.country.code].length) {
          this.uploaderError = 'Upload the required documents';
        }

        if (!valid || this.uploaderError !== '') { return; }

        this.loading = true;
        this.disableOptions = [];
        const fields = [];

        // Populate validity period fields for PH form
        if (this.formData.country.code === 'PH') {
          const dateFields = this.dateFields[this.formData.country.code];

          this.form[dateFields.validityPeriodFromKey] = this.form[dateFields.validityPeriodRangeKey].startDate;
          this.form[dateFields.validityPeriodToKey] = this.form[dateFields.validityPeriodRangeKey].endDate;

          delete this.form[dateFields.validityPeriodRangeKey];
        }

        for (const field in this.form) { // eslint-disable-line
          const customField = this.fields.find(f => f.key === field);
          const value = customField.type === 'uploader' ? this.attachments : this.form[field];
          const formattedField = {
            id: customField.id,
            name: customField.type === 'uploader' ? customField.name : customField.raw_title_in_portal,
            type: customField.type,
            rawValue: value,
            value,
          };

          if (formattedField.name === 'Sub Account ID') {
            formattedField.type = 'text';
            this.selectedSubAccountId = formattedField.value;
          }

          if (customField.type === 'tagger' && customField.custom_field_options && customField.custom_field_options.length) {
            formattedField.rawValue = customField.custom_field_options.find(option => option.value === value).name;
          }

          fields.push(formattedField);
        }

        // if (!fields.find(f => f.id === 'attachmentkey')) {
        //   const formattedField = {
        //     id: customField.id,
        //     name: customField.type === 'uploader' ? customField.name : customField.raw_title_in_portal,
        //     type: customField.type,
        //     rawValue: value,
        //     value,
        //   };

        //   fields.push(formattedField);
        // }

        let senderId = this.convertFieldsToJson(fields);

        if (['edit', 'delete'].includes(this.operation) && this.selected) {
          delete senderId.id;

          senderId = {
            id: this.selected.data.id,
            ...senderId,
          };
        }

        if (this.attachments.length > 0) {
          senderId = {
            ...senderId,
            hasAttachments: this.attachments.length > 0,
          };
        }

        console.log('HERE IN SUBMIT SENDER ID: ', this.formData.senderIds);

        this.$emit('submit', {
          action: this.operation || 'add',
          senderId,
          fields,
        });

        // Clear form
        this.form = {};
        // this.rules = {};
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.sender-id-details-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 24px;

  .validity {
    grid-column-start: 1 !important;
  }
}
  .sender-id-uploader {
    .el-upload-dragger {
      width: 400px;
      height: 100px;
    }

    .el-upload-dragger,
    .el-upload--text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  .sender-id-dialog {
    .el-dialog__body {
      padding: 24px !important;
    }

    .el-dialog__header {
      border: none !important;
      padding: 24px 24px 0 24px !important;
    }

    .el-form-item__error {
      white-space: normal;
      word-break: keep-all;
      overflow-wrap: break-word;
    }
  }

  button.custom-launcher:focus {
    outline: none !important;
  }

  button.custom-launcher:hover {
    transform: scale(1.1, 1.1);
  }

  .bg-icon {
    z-index: 0;
    user-select: none;
  }

  .col-card {
    height: 7rem;
  }

  .row-card {
    height: 15rem;
  }

  .row-icon {
    font-size: 255px;
    right: -64px;
    top: -55px;
  }

  .form-label {
    display: flex;
    column-gap: 5px;
    padding-bottom: 5px;
  }

  .add-company {
    border: 1px solid var(--body-background);
  }
</style>
