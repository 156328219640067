<template>
  <div class="dt">
    <div
      v-if="loading"
      class="table-loader">
      <div
        v-loading="true"
        class="spinner" />
    </div>
    <el-table
      :data="curTableData"
      :header-row-class-name="headerClass"
      size="small"
      style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-row class="py-2">
            <el-col :span="12">
              {{ $t('column_labels.message_id') }}: <strong style="margin: 5px 0 0">{{ props.row.UMID }}</strong>
            </el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="py-2">
            <el-col :span="12">
              <p class="text-left">
                <span>{{ $t('column_labels.country') }}: </span>
                <strong>{{ props.row.Country || '-' }}</strong>
              </p>
            </el-col>
            <el-col :span="12">
              <p class="text-left">
                <span>{{ $t('column_labels.date') }} {{ props.row.SmsTypeId === 1 ? $t('column_labels.sent').toLowerCase() : $t('column_labels.received').toLowerCase() }}: </span>
                <strong>{{ getLocalDateTimeTz(props.row.CreatedTime, timeZoneOffset, timeZone) }}</strong>
              </p>
            </el-col>
          </el-row>

          <el-row
            :gutter="24"
            class="py-2">
            <el-col :span="12">
              <p class="text-left">
                <span>{{ $t('column_labels.operator') }}: </span>
                <strong>{{ props.row.OperatorName || '-' }}</strong>
              </p>
            </el-col>
            <el-col :span="12">
              <p class="text-left">
                <span>{{ $t('column_labels.client_batch_id') }}: </span>
                <strong>{{ props.row.ClientBatchId || '-' }}</strong>
              </p>
            </el-col>
          </el-row>

          <el-row
            :gutter="24"
            class="pt-2 pb-4 mb-4 border-b">
            <el-col
              v-if="showCost"
              :span="12">
              <p class="text-left">
                <span>{{ $t('column_labels.cost') }}: </span>
                <strong>{{ props.row.PriceContractCurrency }} {{ localize(props.row.Price) }}</strong>
              </p>
            </el-col>
            <el-col :span="12">
              <p class="text-left">
                <span>{{ $t('column_labels.client_message_id') }}: </span>
                <strong>{{ props.row.ClientMessageId || '-' }}</strong>
              </p>
            </el-col>
          </el-row>

          <el-row
            :gutter="24"
            class="py-2">
            <el-col :span="15">
              <p class="text-left">
                <span>{{ $t('column_labels.from') }}: </span>
                <strong>{{ getFrom(props.row) }}</strong>
              </p>
            </el-col>
            <el-col :span="9">
              <p>
                <span>{{ $t('column_labels.to') }}: </span>
                <strong>{{ getTo(props.row) }}</strong>
              </p>
            </el-col>
          </el-row>

          <el-row
            :gutter="24"
            class="py-2">
            <el-col :span="15">
              <el-row :gutter="24">
                <el-col :span="3">
                  <p>
                    <span>{{ $t('column_labels.message') }}: </span>
                  </p>
                </el-col>
                <el-col :span="21">
                  <!-- eslint-disable -->
                  <p
                    v-if="user.Roles.includes('SMS.ShowMessageBody_v2')"
                    class="msg mb-2 leading-loose whitespace-pre-wrap breakwords"
                  >{{ props.row.Body }}</p>
                  <!-- eslint-disable -->
                  <p
                    v-else
                    class="msg mb-2">
                    -
                  </p>
                  <span>{{ $t('column_labels.message_count') }}: </span>
                  <strong>{{ props.row.SegmentsReceived || 0 }}</strong>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="9">
              <p>
                <span>{{ $t('column_labels.status') }}: </span>
                <span v-if="props.row.SmsTypeId === 0">
                  <strong>{{ $t('column_labels.received') }}</strong>
                </span>
                <span v-if="props.row.SmsTypeId === 1">
                  <strong v-if="!props.row.Status.match(/^delivered$/i)">{{ props.row.Status }}</strong>
                  <strong
                    v-if="props.row.Status.match(/^delivered$/i)"
                    class="highlight-green">{{ props.row.Status }} <el-icon name="wc-check" /></strong>
                </span>
              </p>
              <p class="info-status">
                {{ $t('messaging.logs_data_tables[0]') }}
                <a
                  href="https://support.wavecell.com/hc/en-us/articles/115005285643-What-are-the-different-message-statuses-"
                  class="wc-link"
                  target="_blank">
                  {{ $t('messaging.logs_data_tables[1]') }}
                </a>
              </p>
              <br>
              <p>
                <span>{{ $t('column_labels.source') }}: </span>
                <strong>{{ props.row.ConnectionType }}</strong>
              </p>
              <br>
              <p>
                <span>{{ $t('column_labels.encoding_type') }}: </span>
                <strong>{{ props.row.EncodingType }}</strong>
              </p>
              <p class="total-status">
                <a
                  href="https://en.wikipedia.org/wiki/SMS#Message_size"
                  class="wc-link"
                  target="_blank">
                  {{ $t('messaging.logs_data_tables[2]') }}
                </a>
              </p>
              <el-template>
                <br>
                <p>
                  <span>{{ $t('column_labels.error_code') }}: </span>
                  <strong>{{ props.row.errorCode ? props.row.errorCode : $t('messaging.logs_data_tables[6]') }}</strong>
                </p>
              </el-template>
              <el-template>
                <br>
                <p>
                  <span>{{ $t('column_labels.code_description') }}: </span>
                  <strong>{{ props.row.CodeDescription ? props.row.CodeDescription : $t('messaging.logs_data_tables[7]') }}</strong>
                </p>
              </el-template>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        label=""
        prop="SmsTypeId"
        :min-width="20">
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.SmsTypeId === 1 ? 'Outgoing' : 'Incoming'"
            :enterable="false"
            class="help-cursor"
            effect="dark"
            placement="bottom"
          >
            <span
              class="w-6 h-6 flex justify-center items-center rounded-full"
              :class="scope.row.SmsTypeId === 1 ? 'bg-orange-lightest' : 'bg-green-lightest'">
              <i
                v-if="scope.row.SmsTypeId === 1"
                class="material-icons text-sm text-orange">
                call_made
              </i>
              <i
                v-if="scope.row.SmsTypeId === 0"
                class="material-icons text-sm text-green">
                call_received
              </i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('column_labels.subaccount', 1)"
        prop="SubAccountId"
        :min-width="100" />
      <el-table-column
        :label="getDateHeader(timeZone, $t('messaging.logs_data_tables[3]'))"
        prop="CreatedTime"
        :min-width="120">
        <template
          slot-scope="scope">
          <span>{{ getLocalDateTimeTz(scope.row.CreatedTime, timeZoneOffset, timeZone) }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template
          slot="header">
          <el-popover
            ref="fromPopOver"
            placement="top-start"
            width="250"
            trigger="hover">
            <!-- <span>
              Virtual number or mobile number <br>
              that received the messages
            </span> -->
            <i18n
              path="messaging.logs_data_tables[4]"
              tag="span">
              <template #br>
                <br>
              </template>
            </i18n>
          </el-popover>
          <span>{{ $tc('column_labels.destinations', 1) }}<i
            v-popover:fromPopOver
            class="el-icon-info
            text-blue" />
          </span>
        </template>
        <template slot-scope="scope">
          <span>{{ `+${scope.row.MSISDN}` }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template
          slot="header">
          <el-popover
            ref="fromPopOverSource"
            placement="top-start"
            width="250"
            trigger="hover">
            <!-- <span>
              Sender ID or mobile number <br>
              that was used to send messages
            </span> -->
            <i18n
              path="messaging.logs_data_tables[5]"
              tag="span">
              <template #br>
                <br>
              </template>
            </i18n>
          </el-popover>
          <span>{{ $t('column_labels.source') }} <i
            v-popover:fromPopOverSource
            class="el-icon-info
            text-blue" />
          </span>
        </template>
        <template slot-scope="scope">
          <span>
            {{ scope.row.Source }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showCost"
        :label="$t('column_labels.cost')"
        prop="Price">
        <template slot-scope="scope">
          <span>{{ scope.row.PriceContractCurrency }} {{ localize(scope.row.Price) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('column_labels.status')">
        <template slot-scope="scope">
          <span>
            {{ scope.row.SmsTypeId === 1 ? scope.row.Status : $t('column_labels.received') }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="showSelection"
        type="selection"
        width="180"
        reserve-selection />
      <slot />
      <el-table-column
        v-if="hasActionCol"
        label=""
        prop="innerRowActions"
        inline-template
        :min-width="actionColWidth"
        align="center">
        <div class="action__list">
          <span
            v-for="(action,i) in rowActionDef"
            :key="i">
            <el-button
              v-if="(action.hasOwnProperty('showHide'))? Boolean(action.showHide(row)) : true"
              type="text"
              :icon="action.icon"
              @click="action.handler(row)">
              {{ action.name }}
            </el-button>
          </span>
        </div>
      </el-table-column>
    </el-table>

    <div class="my-5 mx-2 text-right">
      <el-pagination
        :page-sizes="innerPaginationDef.pageSizes"
        :page-size="parseInt(innerPaginationDef.pageSize, 10)"
        :current-page="parseInt(innerPaginationDef.currentPage, 10)"
        :layout="innerPaginationDef.layout"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';

import TimeFormatter from '../../mixins/timeFormatter';

export default {
  name: 'LogsDataTables',

  mixins: [
    TimeFormatter,
  ],

  props: {
    loading: Boolean,

    data: {
      type: Array,
      default() {
        return [];
      },
    },

    rowKey: {
      type: String,
      default() {
        return '';
      },
    },

    rowClassName: {
      type: Function,
      default() {
        // do nothing
      },
    },

    actionsDef: {
      type: Object,
      default() {
        return {};
      },
    },

    bulkActionsDef: {
      type: Object,
      default() {
        return {};
      },
    },

    searchDef: {
      type: Object,
      default() {
        return {};
      },
    },

    getTextDef: {
      type: String,
      default: '',
    },

    rowActionDef: {
      type: Array,
      default() {
        return [];
      },
    },

    hasActionCol: {
      type: Boolean,
      default: true,
    },

    actionColWidth: {
      type: String,
      default: '',
    },

    colNotRowClick: {
      type: Array,
      default() {
        return [];
      },
    },

    paginationDef: {
      type: Object,
      default() {
        return {};
      },
    },

    selectionHandler: {
      type: Function,
      default() {
        // Do nothing
      },
    },

    showSummary: {
      type: Boolean,
      default: false,
    },

    summaryMethod: {
      type: Function,
      default() {
        return [];
      },
    },

    emptyText: {
      type: String,
      default: 'No data',
    },

    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      lsPageSizeKey: '',
      sortData: {},
      selected: [],
      pageSize: 20,
      currentPage: 1,
      searchKey: '',
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset', 'timeZoneName', 'hasPricingEntitlement']),

    showCost() {
      return this.hasPricingEntitlement;
    },

    innerActionsDef() {
      return Object.assign({}, {
        def: [],
      }, this.actionsDef);
    },

    innerBulkActionsDef() {
      return Object.assign({}, {
        actions: [],
      }, this.bulkActionsDef);
    },

    innerSearchDef() {
      return Object.assign({}, {
        show: true,
        props: undefined,
        filterFunction: undefined,
      }, this.searchDef);
    },

    innerPaginationDef() {
      return Object.assign({}, {
        layout: 'total, sizes, prev, pager, next, jumper',
        pageSizes: [20, 50, 100],
      }, this.paginationDef);
    },

    innerColNotRowClick() {
      return this.colNotRowClick.concat(['innerRowActions']);
    },

    tableData() {
      let newData = this.data;

      const doFilter = (defaultFilterFunction, filter) => {
        const filterFunction = filter.filterFunction || defaultFilterFunction;

        newData = newData.filter(el => filterFunction(el, filter));
      };

      this.filters.forEach((filter) => {
        const { val } = filter;

        if (!val || val.length === 0) {
          return true;
        }

        let defaultFilterFunction;

        if (filter.props) {
          // the filter is for some special column
          if (!(val instanceof Array)) {
            // filter value is not list
            defaultFilterFunction = (el, fltr) => {
              const fltrVal = String(fltr.val).toLowerCase();
              return fltr.props.some((prop) => {
                const elProp = String(el[prop]).toLowerCase();
                return elProp.indexOf(fltrVal) > -1;
              });
            };
          }

          if (val instanceof Array && val.length > 0) {
            // filter value is list, at the same time not empty
            defaultFilterFunction = (el, fltr) => { // eslint-disable-line
              return fltr.props.some(prop => fltr.val.indexOf(el[prop]) > -1); // eslint-disable-line
            };
          }
        } else {
          // filter is for all column
          defaultFilterFunction = (el, fltr) => { // eslint-disable-line
            return Object.keys(el).some(key => String(el[key].indexOf(fltr.val) > -1)); // eslint-disable-line
          };
        }

        doFilter(defaultFilterFunction, filter);
        return true;
      });

      if (this.sortData.order) {
        const { prop, order } = this.sortData;
        const isDescending = order === 'descending';

        // todo: customize sort function
        newData.sort((a, b) => {
          let sortRes = 0;
          if (a[prop] > b[prop]) {
            sortRes = 1;
          }

          if (a[prop] < b[prop]) {
            sortRes = -1;
          }

          return sortRes;
        });

        if (isDescending) {
          newData.reverse();
        }
      }

      return newData;
    },

    curTableData() {
      return this.tableData;
    },

    total() {
      const { total, pageSize, currentPage } = this.paginationDef;
      return (total > 0 && total % pageSize === 0)
        ? (pageSize * currentPage) + 1 : (pageSize * (currentPage - 1)) + total;
      // return this.paginationDef.total || this.tableData.length;
    },

    showSearch() {
      return this.innerSearchDef.show !== false;
    },

    showActions() {
      return this.innerActionsDef.def.length > 0;
    },

    showBulkActions() {
      return this.innerBulkActionsDef.actions.length > 0
        && this.selected.length > 0;
    },

    showSelection() {
      return this.innerBulkActionsDef.actions.length > 0
        && this.curTableData.length > 0;
    },

    filters() {
      const filters = [];

      if (this.showSearch) {
        filters.push({
          props: this.formatProps(this.innerSearchDef.props),
          val: this.searchKey,
          filterFunction: this.innerSearchDef.filterFunction,
        });
      }
      return filters;
    },

    localize() {
      return (n) => {
        const d = this.$constants.PRICE_DECIMAL;
        const l = this.$constants.LOCALE;
        const ns = this.$wc.formatPrice(n, d, l);

        return ns;
      };
    },
  },

  created() {
    this.pageSize = this.paginationDef.pageSize;
  },

  methods: {
    headerClass() {
      return '';
    },
    handleDeleteSelected() {
      this.deleteSelectedHandler();
    },

    formatProps(props) {
      return props ? [].concat(props) : undefined;
    },

    handleSort(obj) {
      this.sortData = obj;
    },

    handleSizeChange(size) {
      this.pageSize = size;

      this.onPaginationChange();
    },

    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;

      this.onPaginationChange();
    },

    onPaginationChange() {
      // Emit event to fetch new data
      this.$emit('sizeChange', {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      });
    },

    handleRowClick(row, event, column) {
      if (column && this.innerColNotRowClick.indexOf(column.property) === -1) {
        this.$emit('row-click', row);
      }
    },

    utcDate(d) {
      return moment.utc(d).format('MMM DD YYYY hh:mm A');
    },

    handleSelectionChange() {
      // Decorate
      return (s) => {
        this.selected = s;
        this.selectionHandler(s);
      };
    },

    getFrom(row) {
      if (row.SmsTypeId === 1) {
        return row.Source;
      }

      return `+${row.MSISDN}`;
    },

    getTo(row) {
      if (row.SmsTypeId === 1) {
        return `+${row.MSISDN}`;
      }

      return row.Source;
    },
  },
};
</script>

<style>
  .el-table__footer-wrapper tbody td {
    background-color: #eef1f6;
    border-top: 1px solid #dfe6ec ;
  }

  .text-def {
    padding-top: 15px;
    padding-right: 2px;
  }

  .el-table__body-wrapper .el-table__expanded-cell {
    background-color: #fbfbfb;
    box-shadow: inset 0 2px 0 #f4f4f4;
  }
</style>

<style scoped>
  .msg {
    padding: 1em;
    background-color: #fff;
    line-height: 2em;
    opacity: 0.8;
    letter-spacing: 0.5px;
    font-family: monospace;
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 5px;
    /* white-space: pre-line;      CSS3
    white-space: -moz-pre-line; Firefox
    white-space: -pre-line;     Opera <7
    white-space: -o-pre-line;   Opera 7 */
    /* word-wrap: break-word;      IE */
    max-width: 600px;
    /* white-space: break-spaces; */
  }

  .info-status {
    margin-top: 1em;
    color: #aaa;
    margin-left: 50px;
  }
</style>
