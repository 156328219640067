<template>
  <div v-loading="loading">
    <div class="row">
      <el-table
        :data="filterNoData(data.conversionReportByDay)"
        stripe
        show-summary
        row-class-name="cursor-pointer"
        :summary-method="getSummary"
        :empty-text="emptyText"
        class="w-full"
        size="small">
        <el-table-column
          v-if="data.groupBy !== 'day'"
          type="expand">
          <template slot-scope="props">
            <div class="ml-6">
              <el-table
                :data="props.row.Interval"
                stripe
                row-class-name="cursor-pointer"
                :empty-text="emptyText"
                class="w-full"
                :show-header="false"
                size="small">
                <el-table-column
                  prop="dateFrom"
                  :label="getDateHeader(filters.tz, $t('column_labels.date'))"
                  :min-width="100">
                  <template slot-scope="scope">
                    <span>{{ utcTime(scope.row.dateFrom, scope.row.dateTo) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="SmsCountTotal"
                  :label="$t('column_labels.sent')">
                  <template slot-scope="scope">
                    <span>{{ Number(scope.row.SmsCountTotal).toLocaleString($constants.LOCALE) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="SmsChargeable"
                  :label="$t('column_labels.chargeable')">
                  <template slot-scope="scope">
                    <span>{{ Number(scope.row.SmsChargeable).toLocaleString($constants.LOCALE) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="SmsDelivered"
                  :label="$t('column_labels.delivered')">
                  <template slot-scope="scope">
                    <span>{{ Number(scope.row.SmsDelivered).toLocaleString($constants.LOCALE) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="SmsUndelivered"
                  :label="$t('column_labels.undelivered')">
                  <template slot-scope="scope">
                    <span>{{ Number(scope.row.SmsUndelivered).toLocaleString($constants.LOCALE) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="SmsRejected"
                  :label="$t('column_labels.rejected')">
                  <template slot-scope="scope">
                    <span>{{ Number(scope.row.SmsRejected).toLocaleString($constants.LOCALE) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="SmsConverted"
                  :label="$t('column_labels.converted')">
                  <template slot-scope="scope">
                    <span>{{ Number(scope.row.SmsConverted).toLocaleString($constants.LOCALE) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ConversionRate"
                  :label="$t('column_labels.conversion_rate')">
                  <template slot-scope="scope">
                    <span>
                      {{ (scope.row.ConversionRate > 0 && scope.row.SmsCountTotal > 0) ? Number(scope.row.ConversionRate).toFixed(2) : '' }}
                      {{ (scope.row.ConversionRate > 0 && scope.row.SmsCountTotal > 0) ? '%': '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Currency"
                  :label="$t('column_labels.currency')" />
                <el-table-column
                  prop="Cost"
                  :label="$t('column_labels.cost')">
                  <template slot-scope="scope">
                    <span>
                      {{ $wc.formatPrice(Number(scope.row.Price).toFixed(2), $constants.PRICE_DECIMAL, $constants.LOCALE) }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="dateFrom"
          :label="getDateHeader(filters.tz, $t('column_labels.date'))"
          :min-width="100">
          <template slot-scope="scope">
            <span>{{ utcDate(scope.row.dateFrom) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SmsCountTotal"
          :label="$t('column_labels.sent')">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.SmsCountTotal).toLocaleString($constants.LOCALE) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SmsChargeable"
          :label="$t('column_labels.chargeable')">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.SmsChargeable).toLocaleString($constants.LOCALE) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SmsDelivered"
          :label="$t('column_labels.delivered')">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.SmsDelivered).toLocaleString($constants.LOCALE) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SmsUndelivered"
          :label="$t('column_labels.undelivered')">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.SmsUndelivered).toLocaleString($constants.LOCALE) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SmsRejected"
          :label="$t('column_labels.rejected')">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.SmsRejected).toLocaleString($constants.LOCALE) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SmsConverted"
          :label="$t('column_labels.converted')">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.SmsConverted).toLocaleString($constants.LOCALE) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ConversionRate"
          :label="$t('column_labels.conversion_rate')">
          <template slot-scope="scope">
            <span>
              {{ (scope.row.ConversionRate > 0 && scope.row.SmsCountTotal > 0) ? Number(scope.row.ConversionRate).toFixed(2) : '' }}
              {{ (scope.row.ConversionRate > 0 && scope.row.SmsCountTotal > 0) ? '%': '-' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="Currency"
          :label="$t('column_labels.currency')" />
        <el-table-column
          v-if="showCost"
          prop="Cost"
          :label="$t('column_labels.cost')">
          <template slot-scope="scope">
            <span>
              {{ $wc.formatPrice(Number(scope.row.Price).toFixed(2), $constants.PRICE_DECIMAL, $constants.LOCALE) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row pt-4 flex">
      <div class="row flex flex-wrap flex-grow">
        <div class="w-2/6 flex-grow pt-4 content-right text-right">
          <el-pagination
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="total,prev,pager,next,jumper"
            :total="total" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash';
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import timeFormatter from '../../../mixins/timeFormatter';
import { getSubscribedCountry } from '../../../helpers/handlePackageLimit';

export default {
  name: 'ConversionData',

  components: {},

  mixins: [
    timeFormatter,
  ],

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },

    columns: {
      type: Array,
      default() {
        return [];
      },
    },

    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    filters: {
      type: Object,
      default() {
        return {};
      },
    },

    getLocalTime: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const self = this;
    return {
      emptyText: self.$t('date_labels.empty_data'),

      currentPage: 1,
      pageSize: 200,
      displayColumns: [],

      total: 0,
    };
  },

  computed: {
    ...mapState('user', ['hasPricingEntitlement']),

    showCost() {
      console.log('filters', this.filters);
      console.log('getSubscribedCountry()', getSubscribedCountry());
      return this.hasPricingEntitlement;
    },
  },

  methods: {
    checkColumn(index) {
      return this.selectedColumns.includes(this.columns[index]);
    },

    toFraction(item) {
      const fraction = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      return Number(item).toLocaleString('en-US', fraction);
    },

    handleRowClick(r) {
      this.$router.push({
        path: '/logs',
        query: {
          dateFrom: r.TimeIntervalUtc,
          dateTo: r.TimeIntervalUtc,
        },
      });
    },

    utcDate(d) {
      if (this.timeInterval === 60) {
        return moment.utc(d).format('MMM DD, YYYY HH:mm');
      }
      return moment.utc(d).format('MMM DD, YYYY');
    },

    utcTime(from, to) {
      const f = moment.utc(from).format('HH:mm');
      const t = moment.utc(to).format('HH:mm');

      return `${f} - ${t}`;
    },

    getSummary(param) {
      const { summary } = this.data;
      const { columns } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (typeof (summary[column.property]) !== 'undefined' || column.property === 'Cost') {
          sums[index] = summary[column.property];

          if (column.property === 'Cost') {
            sums[index] = this.$wc.formatPrice(Number(summary.Price).toFixed(2), this.$constants.PRICE_DECIMAL, this.$constants.LOCALE);
          } else if (column.property === 'ConversionRate') {
            const rate = ((summary[column.property] > 0 && summary.SmsCountTotal > 0) ? Number(summary[column.property]).toFixed(2) : ' ');
            const percent = ((summary[column.property] > 0 && summary.SmsCountTotal > 0) ? '%' : '-');

            sums[index] = `${rate}${percent}`;
          } else if (column.property !== 'Currency' && column.property !== 'dateFrom') {
            sums[index] = Number(summary[column.property]).toLocaleString(this.$constants.LOCALE);
          }
        }
      });

      return sums;
    },

    filterNoData(data) {
      const withData = data.filter(d => d.SmsCountTotal > 0);
      return withData;
    },
  },
};
</script>
<style>
  .reports-checkbox .el-checkbox {
    display: block;
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  .reports-checkbox .el-checkbox .el-checkbox__label {
    font-size: 12px;
  }

  .reports-dropdown-item {
    line-height: 24px;
  }

  .reports-dropdown-item:hover {
    background-color: #ffffff !important;
    color: white;
  }
</style>
