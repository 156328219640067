import actions from './actions';

export default {
  namespaced: true,

  state: {
    users: [],
    filteredUsers: [],
    modules: [],
    currUser: {},
    loadingCurrUser: false,
    loadingSend: false,
    subAccounts: [],
    filterString: '',
    loadingRemove: false,
  },

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_FILTERED_USERS(state, payload) {
      state.filteredUsers = payload;
    },
    SET_ALL_MODULES(state, payload) {
      state.modules = payload;
    },
    SET_CURRENT_USER(state, payload) {
      state.currUser = payload;
    },
    SET_LOADING_CURRENT_USER(state, payload) {
      state.loadingCurrUser = payload;
    },
    SET_SUB_ACCOUNTS(state, payload) {
      state.subAccounts = payload;
    },
    SET_FILTER_STRING(state, payload) {
      state.filterString = payload;
    },
    SET_LOADING_SEND(state, payload) {
      state.loadingSend = payload;
    },
    SET_LOADING_CHANGE(state, payload) {
      state.loadingRemove = payload;
    },
  },

  getters: {
    loadingCurrentUser(state) {
      return state.loadingCurrUser;
    },
    currentUser(state) {
      return state.currUser;
    },

    activeUsers(state) {
      return state.filteredUsers.filter(user => user.UserStatus === 'A');
    },

    pendingUsers(state) {
      return state.filteredUsers.filter(user => user.UserStatus === 'I');
    },

    blockedUsers(state) {
      return state.filteredUsers.filter(user => user.UserStatus === 'B');
    },
  },

  actions,
};
