<template>
  <div>
    <div>
      <span>
        <h3>Country & industry</h3>
      </span>
      <el-row
        :gutter="20"
        class="pt-5 pb-3">
        <el-col :span="12">
          <span>
            <label class="form-label">
              <span class="flex"><p class="text-sm pb-1">Destination country name</p><span class="text-xs text-red">&nbsp;*</span></span>
              <el-tooltip
                :enterable="false"
                placement="top"
                effect="light">
                <div
                  slot="content"
                  class="tooltip-content">
                  <div>Destination country</div>
                </div>
                <span class="icon icon-novo-support" />
              </el-tooltip>
            </label>
            <el-select
              :value="countryData.name"
              :loading="countries.length <= 0"
              size="medium"
              class="w-full"
              placeholder="Select country"
              filterable
              @change="handleCountryChange">
              <el-option
                v-for="item in countries"
                :key="item.code"
                :disabled="!['ID', 'PH', 'TH', 'SG'].includes(item.code)"
                :value="item.code">
                <span
                  :class="`mr-2 flag-container flag-icon flag-icon-${item.code.toLowerCase()}`" />
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
          </span>
        </el-col>
        <el-col :span="12">
          <span>
            <label class="form-label">
              <span class="flex"><p class="text-sm pb-1">Headquarter</p><span class="text-xs text-red">&nbsp;*</span></span>
              <el-tooltip
                :enterable="false"
                placement="top"
                effect="light">
                <div
                  slot="content"
                  class="tooltip-content">
                  <div>Headquarter</div>
                </div>
                <span class="icon icon-novo-support" />
              </el-tooltip>
            </label>
            <el-select
              :value="countryData.headquarter"
              size="medium"
              class="w-full"
              placeholder="Select headquarter"
              filterable
              @change="handleHeadquarterChange">
              <el-option
                v-for="item in headquarters"
                :key="item.id"
                :disabled="(countryData.code === 'TH' && item.value === 'international')"
                :label="item.name"
                :value="item.value" />
            </el-select>
          </span>
        </el-col>
      </el-row>
      <el-button
        v-if="countryData.name && countryData.headquarter"
        type="text"
        @click="fetchCountryRules()">
        <span class="flex items-center primary-color">
          <span class="text-lg icon-novo-info-circle mr-2 primary-color" />
          Show country regulations
        </span>
      </el-button>
      <Article
        title="Country regulations"
        :show="showCountryRules"
        :content="countryRules.body"
        :loading="loading"
        @close="closeCountryRules" />
      <el-row
        v-if="countryData.name && countryData.headquarter"
        :gutter="20"
        class="pt-3">
        <el-col :span="12">
          <span>
            <label class="form-label">
              <span class="flex"><p class="text-sm pb-1">Industry</p><span class="text-xs text-red">&nbsp;*</span></span>
              <el-tooltip
                :enterable="false"
                placement="top"
                effect="light">
                <div
                  slot="content"
                  class="tooltip-content">
                  <div>Industry</div>
                </div>
                <span class="icon icon-novo-support" />
              </el-tooltip>
            </label>
            <el-select
              :value="industry.name"
              size="medium"
              class="w-full"
              placeholder="Select industry"
              filterable
              @change="handleIndustryChange">
              <el-option
                v-for="item in industries"
                :key="item.id"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Article from '../components/Article.vue';

export default {
  name: 'CountryDetails',

  components: {
    Article,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      showCountryRules: false,
      countryData: {},
      industry: {},
      industries: [],
      headquarters: [
        {
          id: 1, name: 'Local', value: 'local', disable: true,
        },
        {
          id: 2, name: 'International', value: 'international', disable: false,
        },
      ],
      countryIndustries: {
        ID: [
          { id: 'finance', name: 'P2P/Fintech/Banking/Finance' },
          { id: 'e-commerce', name: 'E-commerce/Digital Platform' },
          { id: 'crypto', name: 'Cryptocurrency' },
          { id: 'futures-trading', name: 'Futures Trading' },
          { id: 'others', name: 'Others' },
        ],
        PH: [
          { id: 'bfsi', name: 'Banking, Financial Services and Insurance' },
          { id: 'online-gaming', name: 'Online Gaming' },
          { id: 'others', name: 'Others' },
        ],
        TH: [
          { id: 'finance', name: 'P2P/Fintech/Banking/Finance' },
          { id: 'e-commerce', name: 'E-commerce/Digital Platform' },
          { id: 'crypto', name: 'Cryptocurrency' },
          { id: 'futures-trading', name: 'Futures Trading' },
          { id: 'online-gaming', name: 'Online Gaming' },
          { id: 'others', name: 'Others' },
        ],
        SG: [
          { id: 'finance', name: 'P2P/Fintech/Banking/Finance' },
          { id: 'e-commerce', name: 'E-commerce/Digital Platform' },
          { id: 'crypto', name: 'Cryptocurrency' },
          { id: 'futures-trading', name: 'Futures Trading' },
          { id: 'online-gaming', name: 'Online Gaming' },
          { id: 'others', name: 'Others' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      country: 'country/getCountry',
      countries: 'country/getCountriesSupported',
      countryRules: 'articles/getCountryRules',
      user: 'user/getUser',
    }),
  },

  watch: {
    formData(val) {
      this.setCountryData(val);
    },
  },

  created() {
    this.setCountry({});
    this.setCountryData(this.formData);
  },

  methods: {
    ...mapMutations({
      setCountry: 'country/SET_COUNTRY',
      setCompanyFormId: 'country/SET_COMPANY_FORM_ID',
    }),

    ...mapActions({
      getCountriesSupported: 'country/getCountriesSupported',
      getSenderIdCountryRules: 'articles/getSenderIdCountryRules',
    }),

    setCountryData(formData) {
      this.countryData = formData.country || {};
      this.industry = (formData.country && formData.country.industry) || {};

      // A draft
      if (Object.keys(formData).length > 0) {
        const draftCountry = this.countryData;

        if (draftCountry.headquarter) {
          this.setCompanyFormId(draftCountry.headquarter);
        }

        if (draftCountry.code) {
          this.industries = this.countryIndustries[draftCountry.code];
          this.getCountriesSupported().then((countries) => {
            if (countries) {
              const country = countries.find(c => c.code === draftCountry.code);
              this.setCountry(country);
            }
          });
        }
      }
    },

    handleCountryChange(id) {
      // Check if there is already a company data
      // If yes, remove the company data
      if (this.formData.company && this.formData.company.fields && this.formData.company.fields.length > 0) {
        this.changeCountry(id);

        // Trigger update form data
        this.$emit(
          'update',
          {
            key: 'company',
            data: {},
          },
        );

        // Documents
        this.$emit(
          'update',
          {
            key: 'documents',
            data: [],
          },
        );

        // Sender IDs
        this.$emit(
          'update',
          {
            key: 'senderIds',
            data: [],
          },
        );

        this.$emit('show-warning', true);
      } else {
        this.changeCountry(id);
      }
    },

    changeCountry(id) {
      const country = this.countries.find(c => c.code === id);
      const { track } = this.$telemetry;
      track('country_selected',
        {
          countrySelected: country.name,
          uiArea: 'sender_id_management_page',
        });

      this.setCountry(country);
      this.country = country;
      this.industries = this.countryIndustries[id];

      // Reset country info since country has been change
      this.$emit('update', { key: 'country', data: {} });

      // Trigger update form data
      this.$emit('update', { key: 'country', data: { name: country.name, code: id } });
    },

    handleHeadquarterChange(value) {
      // Check if there is already a company data
      // If yes, remove the company data
      if (this.formData.company && this.formData.company.fields && this.formData.company.fields.length > 0) {
        this.changeHeadquarter(value);

        // Trigger update form data
        // Company
        this.$emit(
          'update',
          {
            key: 'company',
            data: {},
          },
        );

        // Documents
        this.$emit(
          'update',
          {
            key: 'documents',
            data: [],
          },
        );

        // Sender IDs
        this.$emit(
          'update',
          {
            key: 'senderIds',
            data: [],
          },
        );

        this.$emit('show-warning', true);
      } else {
        this.changeHeadquarter(value);
      }
    },

    changeHeadquarter(value) {
      // Set company form id which is based on the country and headquarter
      this.setCompanyFormId(value);

      // Trigger update form data
      this.$emit('update', { key: 'country', data: { headquarter: value } });
    },

    handleIndustryChange(value) {
      // Check if there is already a company data
      // If yes, remove the company data
      if (this.formData.company && this.formData.company.fields && this.formData.company.fields.length > 0) {
        this.changeIndustry(value);

        // Trigger update form data
        // Company
        this.$emit(
          'update',
          {
            key: 'company',
            data: {},
          },
        );

        // Documents
        this.$emit(
          'update',
          {
            key: 'documents',
            data: [],
          },
        );

        // Sender IDs
        this.$emit(
          'update',
          {
            key: 'senderIds',
            data: [],
          },
        );

        this.$emit('show-warning', true);
      } else {
        this.changeIndustry(value);
      }
    },

    changeIndustry(value) {
      // Trigger update form data
      const industry = this.industries.filter(i => i.id === value)[0];
      this.$emit('update', { key: 'country', data: { industry } });
    },

    async fetchCountryRules() {
      try {
        this.$emit('set-loading', true);
        if (this.country.code && Object.keys(this.countryRules).length === 0) {
          const locale = 'en-US';
          const { rules } = this.country.articles;

          await this.getSenderIdCountryRules({ id: rules, locale });
        }
        this.showCountryRules = true;
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.$emit('set-loading', false);
      }
    },

    closeCountryRules() {
      this.showCountryRules = false;
    },
  },
};
</script>
<style lang="scss">
.primary-color {
  color: #0056e0 !important;
  [class*=" icon-novo-"] {
    color: #0056e0 !important;
  }

}
.article a {
  color: #0056e0 !important;
}

.collapsible {
  .el-collapse-item__header {
    padding: 0 20px 0 20px;
    color: #171817;
  }

  .el-collapse-item__content {
    padding-bottom: 15px;
    border: none;
    color: #171817;
  }

  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border-left: 1px solid #dae1e7;
    border-right: 1px solid #dae1e7;
  }
}

.guidelines {
  color: #171817;
  padding: 0 20px;

  h3 {
    padding: 3px 0;
    margin-bottom: 10px;

    span {
      text-decoration: none;
    }
  }

  ul,
  ol {
    padding: 0px 20px 20px 40px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.faqs {
  padding: 0;
  border: none;

  p {
    margin-bottom: 0;
    padding: 0;
    text-align: justify;
  }
}

.next-btn {
  text-decoration: none;
}

.warning-alert {
  .el-alert__content .el-alert__title {
    font-size: 15px;
  }
}

.support-icons {
  font-size: 32px;
}

button.custom-launcher {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999999999999999999999999999999999999999;
  height: 45px;
  width: 100px;
  background-color: #CB2232;
  color: white;
  border-radius: 5rem;
  padding: 10px;
  text-align: center;
  display: block;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: none;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);


  span {
    vertical-align: middle;
  }
}

button.custom-launcher:focus {
  outline: none !important;
}

button.custom-launcher:hover {
  transform: scale(1.1, 1.1);
}

.bg-icon {
  z-index: 0;
  user-select: none;
}

.article-icon {
  font-size: 300px;
  left: -49px;
  top: -51px;
}

.col-card {
  height: 7rem;
}

.row-card {
  height: 15rem;
}

.row-icon {
  font-size: 255px;
  right: -64px;
  top: -55px;
}

.form-label {
  display: flex;
  column-gap: 5px;
  padding-bottom: 5px;
}

.add-company {
  border: 1px solid var(--body-background);
}
</style>
