<template>
  <div>
    <div class="mb-1 text-sm font-medium text-grey-darker">
      <label class="flex items-center mb-2">
        <span class="flex"><p class="text-sm">Attachment</p></span>
      </label>
      <ul>
        <li class="text-xs">
          .doc, .xls, .csv, .jpg accepted
        </li>
        <li class="text-xs">
          up to 3MB accepted
        </li>
      </ul>
    </div>
    <el-upload
      ref="uploader"
      drag
      multiple
      auto-upload="false"
      :class="cls"
      :disabled="loading"
      :before-upload="beforeUpload"
      :http-request="onUploadRequest"
      :on-error="onUploadError"
      :on-success="onUploadSuccess"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :show-file-list="false"
      :limit="limit"
      :accept="allowedMimeTypes.toString()">
      <span class="icon-novo-folder-open text-2xl" />
      <div class="el-upload__text text-xs">
        Click to upload or drag and drop your files here.
      </div>
    </el-upload>
    <div
      v-for="file in attachments"
      :key="file.uid">
      <div class="file">
        <span class="flex items-center">
          <i class="icon-novo-check text-blue text-xl mr-2" />
          <span>{{ file.name }}</span>
        </span>
        <span
          class="cursor-pointer icon-novo-close-circle"
          @click="handleRemove(file)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SupportUploader',

  components: {

  },

  props: {
    cls: {
      type: String,
      default: '',
    },

    modalVisible: {
      type: Boolean,
      default: false,
    },

    limit: {
      type: Number,
      required: true,
    },

    fileList: {
      type: Array,
      required: true,
    },
  },

  data() {
    const mimeTypes = {
      csv: 'text/csv',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xls: 'application/vnd.ms-excel',
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      png: 'image/png',
    };

    const file = Object.keys(mimeTypes).reduce((a, b) => {
      a.mimeTypes.push(mimeTypes[b]);
      a.extensions.push(`.${b}`);
      return a;
    }, { mimeTypes: [], extensions: [] });

    return {
      fileSizeLimitMB: 3,
      allowedMimeTypes: file.mimeTypes,
      allowedFileExtensions: file.extensions,
      attachments: [],
      uploading: false,
    };
  },

  watch: {
    uploading() {
      this.$emit('set-uploading', this.uploading);
    },

    attachments(newData) {
      this.$emit('set-attachments', newData);
    },

    modalVisible(nw, old) {
      if (nw !== old) {
        this.attachments = [];
      }
    },
  },

  created() {
    this.attachments = [];

    if (this.fileList.length > 0) {
      this.attachments = this.fileList;
    }
  },

  beforeDestroy() {
    this.attachments = [];
  },

  methods: {
    handleRemove(file) {
      const { uid } = file;

      // Access the uploader component via ref
      const { uploader } = this.$refs;
      if (uploader) {
        const fileIndex = uploader.uploadFiles.findIndex(file => file.uid === uid);
        if (fileIndex !== -1) {
          uploader.uploadFiles.splice(fileIndex, 1);

          const att = this.attachments.find(v => v.uid === uid);

          if (att) {
            const { uid: attId } = att;
            this.attachments = this.attachments.filter(v => v.uid !== attId);
          }
        }
      }
    },

    handleExceed() {
      this.$message.warning(this.$t('support.upload.limit', { limit: this.attachmentLimit }));
    },

    beforeRemove(file) {
      const isAllowedFileType = this.allowedMimeTypes.includes(file.type);
      if (!isAllowedFileType) {
        this.handleRemove(file);
        return null;
      }

      return this.$confirm(this.$t('confirmations.remove', { item: file.name }));
    },

    beforeUpload(file) {
      const isAllowedFileType = this.allowedMimeTypes.includes(file.type);
      const isLt3M = file.size / 1024 / 1024 < this.fileSizeLimitMB;

      if (!isAllowedFileType) {
        this.$message.error(`${this.$t('support.upload.file_should_be')}: ${this.allowedFileExtensions.toString()}`);
      }
      if (!isLt3M) {
        this.$message.error(`${this.$t('support.upload.file_should_not')} ${this.fileSizeLimitMB}MB`);
      }
      return isAllowedFileType && isLt3M;
    },

    async onUploadRequest(fileObj) {
      try {
        this.uploading = true;

        this.attachments.push({
          uid: fileObj.file.uid,
          name: fileObj.file.name,
          filename: fileObj.file.name.replace(/\s/g, '-'),
          id: `SID__${fileObj.file.uid}_${fileObj.file.name.replace(/\s/g, '-')}`,
          size: fileObj.file.size,
          type: fileObj.file.type,
          file: fileObj.file,
        });

        fileObj.onSuccess();
        // this.$notify.success(this.$t('success.uploaded', { value: fileObj.file.name }));
      } catch (e) {
        fileObj.onError();
      } finally {
        this.uploading = false;
      }
    },

    onUploadError(evt, file) {
      this.$notify.error(`${this.$t('support.upload.failed')}: ${file.name}`);
    },

    onUploadSuccess() {
      // this.$notify.success(`${file.name} has been successfuly uploaded.`);
    },
  },
};
</script>
<style lang="scss">
  .file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-bottom: 7px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }
</style>
