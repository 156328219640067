<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu_children.Dashboard') }}
    </h1>
    <el-row
      :gutter="10"
      type="flex"
      class="mb-4 flex-wrap">
      <el-col
        :xs="24"
        :md="24"
        :lg="24"
        class="pb-6">
        <el-card class="dashboard-header">
          <div class="flex float-left pb-4">
            <h2 class="flex text-base font-normal text-white">
              {{ $t('messaging.dashboard.index[0]') }}
            </h2>
          </div>
          <span class="text-right font-normal float-right">
            {{ $t('messaging.dashboard.index[1]') }} {{ timeZone }}
          </span>
        </el-card>
      </el-col>

      <el-col
        :xs="12"
        :sm="12"
        :lg="6"
        class="sm:mb-3 xl:mb-0">
        <div
          type="flex"
          justify="center"
          align="middle"
          class="h-full p-5 rounded shadow">
          <div class="flex flex-col">
            <div class="mb-2">
              <span class="w-12 h-12 flex justify-center items-center bg-blue-lighter rounded-full">
                <i class="material-icons text-xl text-blue">chat_bubble_outline</i>
              </span>
            </div>
            <span class="text-4xl text-black py-2">
              {{ Number(getReportSummary('SmsCountTotal')).toLocaleString($constants.LOCALE) }}
              <div class="text-grey text-sm font-normal"> {{ $t('column_labels.total_sms') }}</div>
            </span>
            <el-row
              class="mt-4"
              type="flex"
              align="middle"
              justify="center">
              <i
                class="material-icons text-sm"
                :class="{ 'text-green': getReportTrend('Messages').trend === 'up', 'text-red': getReportTrend('Messages').trend === 'down' }">{{ getReportTrend('Messages').trend === 'down' && 'call_received' || '' }}</i>
              <i
                class="material-icons text-sm"
                :class="{ 'text-green': getReportTrend('Messages').trend === 'up', 'text-red': getReportTrend('Messages').trend === 'down' }">{{ getReportTrend('Messages').trend === 'up' && 'call_made' || '' }}</i>
              <span
                class="ml-2 text-sm text-black"
                :class="{ 'hidden': getReportTrend('Messages').trendVal === 0}">
                {{ (getChange('SmsCountTotal')).toLocaleString($constants.LOCALE, fraction) }}%
              </span>
              <span
                class="ml-2 text-sm text-grey"
                :class="{ 'hidden': getReportTrend('Messages').trendVal !== 0}"> {{ $t('messaging.dashboard.index[2]') }}</span>
            </el-row>
          </div>
        </div>
      </el-col>

      <el-col
        :xs="12"
        :sm="12"
        :lg="6"
        class="sm:mb-3 xl:mb-0">
        <div
          type="flex"
          justify="center"
          align="middle"
          class="h-full p-5 rounded shadow">
          <div class="flex flex-col">
            <div class="mb-2">
              <span class="w-12 h-12 flex justify-center items-center bg-orange-lightest rounded-full">
                <i class="material-icons text-xl text-orange">trending_down</i>
              </span>
            </div>
            <span class="text-4xl text-black py-2">
              {{ Number(getReportSummary('SmsDeliveryRate')).toLocaleString($constants.LOCALE, fraction) }}%
              <div class="text-grey text-sm font-normal">{{ $t('column_labels.delivery_rate') }}</div>
            </span>
            <el-row
              class="mt-4"
              type="flex"
              align="middle"
              justify="center">
              <i
                class="material-icons text-sm"
                :class="{ 'text-green': getReportTrend('Delivery rate').trend === 'up', 'text-red': getReportTrend('Delivery rate').trend === 'down' }">
                {{ getReportTrend('Delivery rate').trend === 'down' && 'call_received' || '' }}
              </i>
              <i
                class="material-icons text-sm"
                :class="{ 'text-green': getReportTrend('Delivery rate').trend === 'up', 'text-red': getReportTrend('Delivery rate').trend === 'down' }">
                {{ getReportTrend('Delivery rate').trend === 'up' && 'call_made' || '' }}
              </i>
              <span
                class="ml-2 text-sm text-black"
                :class="{ 'hidden': getReportTrend('Delivery rate').trendVal === 0}">
                {{ (getChange('SmsDeliveryRate')).toLocaleString($constants.LOCALE, fraction) }}%
              </span>
              <span
                class="ml-2 text-sm text-grey"
                :class="{ 'hidden': getReportTrend('Delivery rate').trendVal !== 0}"> {{ $t('messaging.dashboard.index[2]') }}</span>
            </el-row>
          </div>
        </div>
      </el-col>

      <el-col
        v-if="showCost"
        :xs="12"
        :sm="12"
        :lg="6"
        class="sm:mb-3 xl:mb-0">
        <div
          type="flex"
          justify="center"
          align="middle"
          class="h-full p-5 rounded shadow">
          <div class="flex flex-col">
            <div class="mb-2">
              <span class="w-12 h-12 flex justify-center items-center bg-teal-lighter rounded-full">
                <span
                  class="text-xl text-white p-2"
                  v-html="getSymbol(getReportSummary('SummaryCurrency'))" />
              </span>
            </div>
            <span class="text-4xl text-black py-2">
              {{ Number(getReportSummary('Cost')).toLocaleString($constants.LOCALE, fraction) }}
              <div class="text-grey text-sm font-normal">Total cost
                (<span v-html="getSymbol(getReportSummary('SummaryCurrency'))" />)
              </div>
            </span>
            <el-row
              class="mt-4"
              type="flex"
              align="middle"
              justify="center">
              <i
                class="material-icons text-sm"
                :class="{ 'text-green': getReportTrend('Cost').trend === 'up', 'text-red': getReportTrend('Cost').trend === 'down' }">{{ getReportTrend('Cost').trend === 'down' && 'call_received' || '' }}</i>
              <i
                class="material-icons text-sm"
                :class="{ 'text-green': getReportTrend('Cost').trend === 'up', 'text-red': getReportTrend('Cost').trend === 'down' }">{{ getReportTrend('Cost').trend === 'up' && 'call_made' || '' }}</i>
              <span
                class="ml-2 text-sm text-black"
                :class="{ 'hidden': getReportTrend('Cost').trendVal === 0}">
                {{ (getChange('Cost')).toLocaleString($constants.LOCALE, fraction) }}%
              </span>
              <span
                class="ml-2 text-sm text-grey"
                :class="{ 'hidden': getReportTrend('Cost').trendVal !== 0}"> {{ $t('messaging.dashboard.index[2]') }}</span>
            </el-row>
          </div>
        </div>
      </el-col>

      <el-col
        :xs="12"
        :sm="12"
        :lg="6"
        class="sm:mb-3 xl:mb-0">
        <div
          type="flex"
          justify="center"
          align="middle"
          class="h-full p-5 rounded shadow">
          <div class="flex flex-col">
            <div class="mb-2">
              <span class="w-12 h-12 flex justify-center items-center bg-pink-lightest rounded-full">
                <i class="material-icons text-xl text-pink">language</i>
              </span>
            </div>
            <span class="text-4xl text-black py-2">
              {{ Number(getDestinationCountries()).toLocaleString($constants.LOCALE) }}
              <div class="text-grey text-sm font-normal"> {{ $t('messaging.dashboard.index[3]') }}</div>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row
      :gutter="10"
      type="flex"
      class="my-8 flex-wrap">
      <el-col
        :span="24"
        class="sm:mb-3 xl:mb-0">
        <el-row
          :gutter="5"
          type="flex"
          class="mb-4 flex-wrap items-center">
          <el-col
            :span="24"
            class="sm:mb-3 xl:mb-0">
            <el-tabs
              v-model="activeName"
              class="flex flex-col dashboard-tab reports-tab"
              @tab-click="handleTabClick">
              <el-tab-pane
                :label="$t('column_labels.sms')"
                name="sms"
                class="flex">
                <el-row
                  type="flex"
                  class="row-bg w-full"
                  justify="space-around"
                  align="middle">
                  <el-col :span="16">
                    <SMSLineChart
                      v-if="smsChartData && activeName === 'sms'"
                      :chart-data="smsChartData"
                      class="h-full w-full" />
                  </el-col>
                  <el-col :span="8">
                    <ul class="sm:mb-3 xl:mb-0 legend-con">
                      <li
                        v-for="(item, index) in smsChartData.datasets"
                        :key="index"
                        class="sm:mb-3 xl:mb-0 el-col el-col-12 text-left">
                        <div :class="'text-grey align-middle legend_con_' + index">
                          <span class="icon" />
                          <span class="label">{{ item.label }}</span>
                        </div>
                        <div class="pl-4 pt-2">
                          {{ item.totalData }}
                        </div>
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-tab-pane>

              <el-tab-pane
                :label="$t('column_labels.delivery_rate')"
                name="delivery-rate"
                class="flex">
                <el-row
                  type="flex"
                  class="row-bg w-full"
                  justify="space-around"
                  align="middle">
                  <el-col :span="16">
                    <DeliveryRateLineChart
                      v-if="smsChartData && activeName === 'delivery-rate'"
                      :chart-data="smsChartData"
                      class="w-full" />
                  </el-col>
                  <el-col :span="8">
                    <ul class="sm:mb-3 xl:mb-0 legend-con">
                      <li
                        v-for="(item, index) in smsChartData.datasets"
                        :key="index"
                        class="sm:mb-3 xl:mb-0 el-col el-col-12 text-left">
                        <div class="text-grey align-middle legend_con_dr">
                          <span class="icon" /> <span class="label">{{ item.label }}</span>
                        </div>
                        <div class="pl-4 pt-2">
                          {{ item.totalData }} <span>%</span>
                        </div>

                        <div
                          v-if="item.trendElem.klas.length && item.trendElem.icon.length"
                          class="pl-4 pt-2">
                          <i :class="item.trendElem.klas">{{ item.trendElem.icon }}</i>
                          <span class="ml-2">{{ item.trend }}%</span>
                        </div>
                        <div
                          v-else
                          class="pl-4 pt-2">
                          <span class="text-sm text-grey"> {{ $t('messaging.dashboard.index[2]') }}</span>
                        </div>

                        <div class="text-grey align-middle pl-4 mt-12 pt-4">
                          {{ $t('messaging.dashboard.index[4]') }}
                        </div>
                        <div
                          class="pl-4 pt-3"
                          style="font-size: small;">
                          <span class="text-xl font-medium">{{ item.prevTotal }}</span>
                          <span class="pl-2 text-xl font-medium">%</span>
                        </div>
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-tab-pane>

              <el-tab-pane
                v-if="showCost"
                :label="$t('column_labels.cost')"
                name="cost"
                class="flex">
                <el-row
                  type="flex"
                  class="row-bg w-full"
                  justify="space-around"
                  align="middle">
                  <el-col :span="16">
                    <CostLineChart
                      v-if="smsChartData && activeName === 'cost'"
                      :chart-data="smsChartData"
                      class="w-full" />
                  </el-col>
                  <el-col :span="8">
                    <ul class="sm:mb-3 xl:mb-0 legend-con">
                      <li
                        v-for="(item, index) in smsChartData.datasets"
                        :key="index"
                        class="sm:mb-3 xl:mb-0 el-col el-col-12 text-left">
                        <div class="text-grey align-middle legend_con_cost">
                          <span class="icon" /> <span class="label">{{ item.label }}</span>
                        </div>
                        <div class="pl-4 pt-2">
                          <span>{{ smsChartData.currencySymbol }}</span>
                          <span class="pl-2">{{ item.cost }}</span>
                        </div>

                        <div
                          v-if="item.trendElem.klas.length && item.trendElem.icon.length"
                          class="pl-4 pt-2">
                          <i :class="item.trendElem.klas">{{ item.trendElem.icon }}</i>
                          <span class="ml-2">{{ item.trendVal }} %</span>
                        </div>
                        <div
                          v-else
                          class="pl-4 pt-2">
                          <span class="text-sm text-grey"> {{ $t('messaging.dashboard.index[2]') }}</span>
                        </div>

                        <div class="text-grey align-middle pl-4 mt-12 pt-4">
                          {{ $t('messaging.dashboard.index[5]') }}
                        </div>
                        <div
                          class="pl-4 pt-3"
                          style="font-size: small;">
                          <span class="text-xl font-medium">{{ smsChartData.currencySymbol }} </span>
                          <span class="text-xl font-medium">{{ item.prevTotal }}</span>
                        </div>
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row
      :gutter="10"
      type="flex"
      class="mb-4 flex-wrap">
      <el-col :span="18">
        <div
          type="flex"
          justify="center"
          align="middle"
          class="h-full p-5 rounded shadow">
          <h4 class="text-left mb-4">
            {{ $t('messaging.dashboard.index[3]') }}
          </h4>
          <TopCountries
            :forbidden="forbidden"
            :operators="operatorsSummary" />
        </div>
      </el-col>
      <el-col :span="6">
        <div
          type="flex"
          justify="center"
          align="middle"
          class="h-full p-5 rounded shadow dCampaigns">
          <h4 class="text-left p-4 relative">
            <i class="icon-campaign text-base mr-2" />
            {{ $t('messaging.dashboard.index[6]') }}
            <i
              class="material-icons text-sm absolute cursor-pointer call_made"
              style="right: 15px;"
              @click="gotoSender">call_made</i>
          </h4>
          <h2 class="text-left ml-10">
            {{ totalActive }}
          </h2>
          <div class="bottom-campaigns">
            <el-button
              type="text"
              @click="showCampaign">
              +  {{ $t('messaging.dashboard.index[7]') }}
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapActions, mapGetters, mapState } from 'vuex';
import CostLineChart from './LineChart/Cost.vue';
import DeliveryRateLineChart from './LineChart/DeliveryRate.vue';
import SMSLineChart from './LineChart/SMS.vue';
import TopCountries from './TopCountries.vue';

export default {
  components: {
    SMSLineChart,
    DeliveryRateLineChart,
    CostLineChart,
    TopCountries,
  },

  data() {
    return {
      legendData: '',
      loading: false,
      forbidden: false,
      campaignsForbidden: false,
      report: [],
      fetchedCampaigns: [],
      activeName: 'sms',
      currency: {
        CHF: '&#8355',
        CNY: '&#20803;',
        EUR: '&euro;',
        IDR: '&#82;&#112;',
        INR: '&#8377;',
        PHP: '&#8369;',
        RUB: '&#8381;',
        SGD: '&#36;',
        THB: '&#3647;',
        USD: '&dollar;',
      },
      fraction: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    };
  },

  computed: {
    ...mapState('dashboard', [
      'selectedReport',
      'dateFilters',
      'dateFilterIndex',
      'operatorsSummary',
      'subFilterIndex',
      'reports',
      'prevReports',
    ]),
    ...mapState('socket', ['socket', 'connected', 'socketId']),
    ...mapState('campaign', ['campaigns', 'totalActive']),
    ...mapState('user', ['timeZone', 'timeZoneOffset', 'timeZoneName', 'hasPricingEntitlement']),

    ...mapGetters({
      reportByTopic: 'dashboard/getReportByTopic',
      selectedReport: 'dashboard/getSelectedReport',
      reportTypes: 'dashboard/getReportTypes',
      hasReportData: 'dashboard/hasReportData',
    }),

    showCost() {
      return this.hasPricingEntitlement;
    },

    getRates() {
      return (row) => {
        const dr = (row.SmsDelivered / row.SmsTotal) * 100;
        const udr = (row.SmsUndelivered / row.SmsTotal) * 100;
        const rr = (row.SmsError / row.SmsTotal) * 100;

        return {
          dRate: !_.isNumber(dr) || !_.isFinite(dr) ? '0 %' : `${dr.toFixed(2)} %`,
          udRate: !_.isNumber(udr) || !_.isFinite(udr) ? '0 %' : `${udr.toFixed(2)} %`,
          rjRate: !_.isNumber(rr) || !_.isFinite(rr) ? '0 %' : `${rr.toFixed(2)} %`,
        };
      };
    },

    smsChartData() {
      const { reportByTopic } = this;

      if (reportByTopic.length) {
        const bgColor = [
          'rgb(36, 178, 222, 0.5)',
          'rgba(255, 255, 255, 0.04)',
          'rgba(255, 255, 255, 0.04)',
          'rgba(0, 31, 49, 0.5)',
        ];
        const borderColor = ['#24b2de', '#f6dd8f', '#E71D36', '#001f31', '#41f2d1'];
        const labelData = _.map(reportByTopic[0].dates, 'dateUTC');

        const reportedData = reportByTopic.map((report, index) => {
          let setLabel = report.topicName === 'Total' ? this.$t('column_labels.sent') : report.topicName;

          let tempLabel = report.topicName === 'Total' ? 'Sent' : report.topicName;
          let selectedBGColor = bgColor[index]; // eslint-disable-line
          let selectedBorderColor = borderColor[index]; // eslint-disable-line

          if (this.activeName === 'cost') {
            setLabel = this.$t('column_labels.total_cost');
            tempLabel = 'Total cost';
            selectedBGColor = bgColor[1]; // eslint-disable-line
            selectedBorderColor = borderColor[4]; // eslint-disable-line
          }
          if (this.activeName === 'delivery-rate') {
            setLabel = this.$t('column_labels.delivery_rate');
            tempLabel = 'deliveryRate';
            selectedBGColor = bgColor[1]; // eslint-disable-line
            selectedBorderColor = borderColor[1]; // eslint-disable-line
          }

          return ({
            label: setLabel,
            tempLabel,
            borderColor: selectedBorderColor,
            borderWidth: 2,
            backgroundColor: selectedBGColor,
            data: _.map(report.dates, 'value'),
            lineTension: ((index < 1 || index > 2) ? 0 : 0.4),
          });
        });

        let reportVal = '';
        if (this.activeName === 'cost') {
          reportVal = this.getReportTrend('Cost');
        }
        if (this.activeName === 'delivery-rate') {
          reportVal = this.getReportTrend('Delivery rate');
        }

        const itemData = reportedData.map((v) => {
          v.prevTotal = 0;
          v.trendVal = 0;
          v.cost = 0;
          v.trendElem = {
            klas: '',
            icon: '',
          };

          v.totalData = _.sum(v.data);

          if (this.activeName === 'sms') {
            if (v.tempLabel === 'Sent') { v.sortIndex = 0; }
            if (v.tempLabel === 'Delivered') { v.sortIndex = 1; }
            if (v.tempLabel === 'Undelivered') { v.sortIndex = 2; }
            if (v.tempLabel === 'Rejected') { v.sortIndex = 3; }
            v.totalData = Number(v.totalData).toLocaleString(this.$constants.LOCALE);
          } else {
            const dataVal = v.data.filter(d => d > 0).length;
            v.totalData = Number(v.totalData / dataVal).toLocaleString(this.$constants.LOCALE, this.fraction);
            if (reportVal) {
              let reportValPrevTotal = 0;
              if (reportVal.prevSum) {
                reportValPrevTotal = reportVal.prevSum.total;
              }

              if (this.activeName === 'cost') {
                v.cost = Number(reportVal.total).toLocaleString('en-US', this.fraction);
                v.trendVal = this.getChangeValTrend(reportVal.total, Number(reportValPrevTotal));
              } else {
                v.trendVal = this.getChangeValTrend(reportVal.total, Number(reportValPrevTotal));
              }

              v.prevTotal = Number(reportValPrevTotal).toLocaleString('en-US', this.fraction);
            }

            if (reportVal.trend === 'up') {
              v.trendElem = {
                klas: 'material-icons text-sm text-green',
                icon: 'call_made',
              };
            }

            if (reportVal.trend === 'down') {
              v.trendElem = {
                klas: 'material-icons text-sm text-red',
                icon: 'call_received',
              };
            }

            v.trendVal = Number(v.trendVal).toLocaleString(this.$constants.LOCALE, this.fraction);
          }

          return v;
        }).sort((a, b) => a.sortIndex - b.sortIndex);

        return {
          labels: _.map(labelData, this.formatSMSGraph),
          datasets: itemData,
          constantLocale: this.$constants.LOCALE,
          reportData: reportVal,
          currencyName: this.getReportSummary('SummaryCurrency') ? this.getReportSummary('SummaryCurrency') : 'EUR',
          currencySymbol: this.getReportSummary('SummaryCurrency')
            ? this.getSymbol(this.getReportSummary('SummaryCurrency'))
            : this.getSymbol('EUR'),
        };
      }
      return false;
    },
  },

  watch: {
    'activeName': 'selectReport',
  },

  created() {
    this.fetchCampaigns();

    this.selectReport();

    const { track } = this.$telemetry;
    track('dashboard_viewed', { channel: 'SMS' });
  },

  mounted() {
    this.setSelectedReport(1);
    this.getReportData(null);
  },

  methods: {
    ...mapActions({
      getCampaigns: 'campaign/getCampaigns',
      getReports: 'report/getReports',
      getOperatorsSummary: 'report/getOperatorsSummary',
      setSelectedReport: 'dashboard/setSeletectedReport',
      setSubFilter: 'dashboard/setSubFilter',
      setDateFilter: 'dashboard/setDateFilter',
    }),

    showCampaign() {
      const event = new Event('navigateToMCS');

      window.dispatchEvent(event);
    },

    gotoSender() {
      localStorage.setItem('sms-dashboard', 'active');
      window.location.href = '/multichannel-sender';
    },

    getReportData(params) {
      const dateFrom = moment().startOf('week').format('YYYY-MM-DD');
      const defaultFilter = [
        {
          timeInterval: 1440,
          comparison: false,
          dateFrom: moment().startOf('week').format('YYYY-MM-DD'),
          dateTo: moment().endOf('day').format('YYYY-MM-DD'), // Should not exceed today's date
          tz: this.timeZoneName,
          tzOffset: this.timeZoneOffset,
        },
        {
          timeInterval: 10080,
          comparison: true,
          dateFrom: moment(dateFrom).clone().subtract(1, 'day').startOf('week')
            .format('YYYY-MM-DD'),
          dateTo: moment().endOf('day').format('YYYY-MM-DD'), // Should not exceed today's date
          tz: this.timeZoneName,
          tzOffset: this.timeZoneOffset,
        },
      ];

      const filters = params || defaultFilter;
      const promises = [
        this.getReports(filters[0]),
        this.getReports(filters[1]),
        this.getOperatorsSummary({
          dateFrom: moment(filters[0].dateFrom).format('YYYY-MM-DD'),
          dateTo: moment(filters[0].dateTo).format('YYYY-MM-DD'),
          tzOffset: this.timeZoneOffset,
        }),
      ];

      this.loading = true;
      Promise.all(promises)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.forbidden = err.body.message === 'Forbidden';

          this.loading = false;
        });
    },

    getChangeValTrend(currentTotal, prevTotal) {
      if (currentTotal === 0 && prevTotal === 0) return 0;
      if (currentTotal === 0 && prevTotal !== 0) return -100;
      if (currentTotal !== 0 && prevTotal === 0) return 100;

      return ((currentTotal - prevTotal) / prevTotal) * 100;
    },

    fetchCampaigns() {
      const params = {
        offset: 0,
        limit: 10,
        from: moment().subtract(6, 'M').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        status: 'processed',
        tzOffset: this.timeZoneOffset,
      };

      this.getCampaigns(params)
        .then(() => {
          this.fetchedCampaigns = this.campaigns;
        })
        .catch((err) => {
          this.campaignsForbidden = err.body.message === 'Forbidden';
          // do nothing
        })
        .then(() => {
          this.isFetchingCampaigns = false;
        });
    },

    formatSMSGraph(data) {
      return new Date(data);
    },

    setLegend(html) {
      this.legendData = html;
    },

    handleTabClick(tab) {
      this.activeName = tab.name;
    },

    getReportSummary(colField) {
      const reportSummary = _.find(this.reports.summary, ['col', colField]);

      if (reportSummary && colField === 'SummaryCurrency') {
        return reportSummary.value;
      }

      if (!reportSummary && colField === 'SummaryCurrency') {
        return this.$auth.user().Currency ? this.$auth.user().Currency : 'EUR';
      }

      if (reportSummary) {
        return reportSummary.total;
      }

      return 0;
    },

    getSymbol(localCurrency) {
      if (this.$auth && this.$auth.user()
        && this.$auth.user().Symbol
        && this.$auth.user().Currency === localCurrency) {
        return this.$auth.user().Symbol;
      }

      return localCurrency ? this.currency[localCurrency] : this.currency.EUR;
    },

    getReportTrend(labelField) {
      return _.find(this.reportTypes, ['label', labelField]);
    },

    getChange(label) {
      let currentTotal = 0;
      if (this.reports && this.reports.summary && this.reports.summary.length) {
        currentTotal = (_.find(this.reports.summary, ['col', label])).total;
      }

      let prevTotal = 0;
      if (this.prevReports && this.prevReports.summary && this.prevReports.summary.length) {
        prevTotal = (_.find(this.prevReports.summary, ['col', label])).total;
      }

      if (currentTotal === 0 && prevTotal === 0) return 0;
      if (currentTotal === 0 && prevTotal !== 0) return -100;
      if (currentTotal !== 0 && prevTotal === 0) return 100;

      return ((currentTotal - prevTotal) / prevTotal) * 100;
    },

    getDestinationCountries() {
      // return _(this.operatorsSummary).mapValues('SmsCountTotal').valuesIn().sum();

      const destinationCountriesCount = _(this.operatorsSummary).mapValues('SmsCountTotal').toArray().value();

      if (destinationCountriesCount) {
        return destinationCountriesCount.length;
      }

      return 0;
    },

    selectReport() {
      if (this.activeName === 'cost') { this.setSelectedReport(0); }
      if (this.activeName === 'sms') { this.setSelectedReport(1); }
      if (this.activeName === 'delivery-rate') { this.setSelectedReport(2); }

      this.legendData = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .dashboard-header {
    background-color: #333442;
    color: white;
  }
</style>

<style>
.legend-con {
  list-style: none;
}
.legend-con > li:nth-child(1),
.legend-con > li:nth-child(2) {
  margin-bottom: 40px;
}
.legend-con > li:nth-child(3),
.legend-con > li:nth-child(4) {
  margin-top: 40px;
}
.legend-con > li > div:nth-child(2) {
  font-size: 35px;
}
.dCampaigns .el-card__body {
  position: relative;
  height: 100%;
  padding: 0;
}
.call_made:hover {
  color: #ffffff;
  background-color: #409EFF;
  border: 1px solid #409EFF;
  border-radius: 2px;
}
</style>
<style scoped>
  .bottom-campaigns {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
</style>
