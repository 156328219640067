/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    companyFormId: '',
    country: { label: 'Select Country', value: '' },
    countries: [],
    messageTypes: [],
    countriesSupported: [],
    customFields: [],
    dateFields: {
      'PH': {
        validityPeriodFromKey: '15658914265625',
        validityPeriodToKey: '15658965441177',
        validityPeriodRangeKey: 'date-range',
      },
    },
    senderIdFields: ['Sender ID', 'Sub Account ID', 'Content of Message', 'Message Type', 'Message Category', 'Purpose of Message', 'SMS Type', 'Purpose of SMS', 'Monthly Traffic Estimation', 'Validity Period Start Date', 'Validity Period End Date'],
    fieldsWithCustomValidation: [
      {
        keys: ['Email Address of Signing Officer', 'Email Address of Delegated Person'],
        validation: [
          {
            rule: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: 'Please enter a valid Email Address',
          },
        ],
      },
      {
        keys: ['Sender ID'],
        validation: [
          {
            rule: /^.{3,}$/,
            message: 'Minimum character is 3',
          },
          {
            rule: /^.{0,11}$/,
            message: 'Maximum character is 11',
          },
          {
            rule: /^[a-zA-Z\d-&. ]+$/,
            message: 'Special characters allowed: & - . (ampersand, dot and dash)',
          },
        ],
      },
    ],
    customHeader: {
      'SG': {
        title: 'International entities cannot register their Sender IDs with SGNIC (SINGAPORE NETWORK INFORMATION CENTRE)',
      },
    },
  },

  getters: {
    getCountriesSupported: st => st.countriesSupported,
    getCountry: st => st.country,
    getCustomFields: st => st.customFields,
    getDateFields: st => st.dateFields,
    getCustomHeader: st => st.customHeader,
    getCompanyFormId: st => st.companyFormId,
  },

  mutations: {
    SET_COMPANY_FORM_ID: (state, payload) => { state.companyFormId = payload; },

    SET_COUNTRY: (state, payload) => { state.country = payload; },

    SET_COUNTRIES_SUPPORTED: (st, payload) => { st.countriesSupported = payload; },

    SET_MESSAGE_TYPES: (st, payload) => { st.messageTypes = payload; },

    SET_CUSTOM_FIELDS: (state, payload) => {
      const countryCustomFields = [];

      payload.forEach((ccf) => {
        const title = ccf.title_in_portal;
        const isSenderIdInformation = state.senderIdFields.includes(ccf.title_in_portal);
        const fieldWithValidation = state.fieldsWithCustomValidation.find(field => field.keys.includes(title));

        const newField = {
          ...ccf,
          key: ccf.id.toString(),
          isSenderIdInformation,
          customValidation: fieldWithValidation ? fieldWithValidation.validation : null,
          helpText: null,
        };

        // Validation for all integer type
        if (ccf.type === 'integer') {
          newField.customValidation = [
            {
              rule: /^[0-9\d-+ ]+$/,
              message: 'Value should be numbers only',
            },
          ];
        }

        if (ccf.title === 'Sub Account ID') {
          newField.type = 'tagger';
        }

        countryCustomFields.push(newField);
      });

      // Add new dummy field for the validity period for PH form
      // This is to merge the two date fields into one date range field
      if (state.country.code === 'PH') {
        const dateField = countryCustomFields.find(f => f.key === state.dateFields[state.country.code].validityPeriodFromKey);
        const dateRangeField = {
          ...dateField,
          id: 'date-range',
          title: 'Validity Period (Strictly 1 year)',
          title_in_portal: 'Validity Period (Strictly 1 year)',
          raw_title_in_portal: 'Validity Period',
          raw_description: 'Sender ID is only valid for one year',
          type: 'dateRange',
          key: state.dateFields[state.country.code].validityPeriodRangeKey,
          isSenderIdInformation: true,
          required_in_portal: true,
        };

        countryCustomFields.push(dateRangeField);
      }

      state.customFields = countryCustomFields;
    },
  },

  actions,
};
