<template>
  <div
    v-loading="docsDetailsLoading"
    class="sender-id-documents-details">
    <el-dialog
      title="Add company details"
      :visible.sync="countryHqModal"
      width="450"
      custom-class="documents-country-hq-dialog"
      @close="() => handleAction('cancel')">
      <div class="content">
        <div class="field">
          <div class="pb-1">
            Country <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="countryHq.country"
            filterable
            clearable
            placeholder="Select country"
            class="flex flex-auto"
            @change="countryHQChange">
            <el-option
              v-for="(item, i) in countries"
              :key="`${item.code}-${i}`"
              :value="item.code">
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="field">
          <div class="pb-1">
            Headquarter <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="countryHq.hq"
            filterable
            clearable
            placeholder="Select country"
            class="flex flex-auto"
            @change="HQChange">
            <el-option
              v-for="(item, i) in ['Local', 'International']"
              :key="`${item}-${i}`"
              :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="field">
          <div class="pb-1">
            Industry <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="countryHq.industry"
            filterable
            clearable
            placeholder="Select country"
            :disabled="!countryHq.country"
            class="flex flex-auto">
            <el-option
              v-for="(item) in hqIndustries"
              :key="`${item.id}`"
              :value="item.name">
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="actions">
        <el-button @click="() => handleAction({action:'step-cancel'})">
          Cancel
        </el-button>
        <el-button
          type="primary"
          :disabled="!countryHq.country || !countryHq.hq || !countryHq.industry"
          @click="() => handleAction({action: 'continue-add'})">
          Continue
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="Comfirm deletion"
      :visible.sync="confirmDelete.deleteDialog"
      width="450"
      custom-class="documents-delete-dialog"
      @close="() => handleDeletion('cancel')">
      <span>{{ confirmDelete.deleteMsg }}</span>
      <div class="actions">
        <el-button @click="() => handleDeletion('cancel')">
          Cancel
        </el-button>
        <el-button
          type="primary"
          @click="() => handleDeletion(confirmDelete.action)">
          Confirm
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="visible"
      custom-class="documents-details-dialog"
      :title="title">
      <el-form
        ref="documents-details-form"
        label-position="top"
        :model="newDocument"
        :rules="rules"
        class="form">
        <el-form-item
          prop="country">
          <div class="pb-1">
            Country <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="newDocument.country"
            filterable
            clearable
            placeholder="Select country"
            class="flex flex-auto"
            @change="handleCountryChange">
            <el-option
              v-for="(item, i) in countries"
              :key="`${item.code}-${i}`"
              :value="item.name">
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="headquarter">
          <div class="pb-1">
            Headquarter <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="newDocument.headquarter"
            filterable
            clearable
            placeholder="Select headquarter"
            class="flex flex-auto">
            <el-option
              v-for="(item, i) in ['Local', 'International']"
              :key="`${item}-${i}`"
              :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="industry">
          <div class="pb-1">
            Industry <span class="text-red">&nbsp;*</span>
          </div>
          <el-select
            v-model="newDocument.industry"
            filterable
            clearable
            placeholder="Select industry"
            :disabled="!newDocument.country"
            class="flex flex-auto">
            <el-option
              v-for="(item, i) in countryIndustries"
              :key="`${item.id}-${i}`"
              :value="item.name">
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="name">
          <div class="pb-1">
            Document name <span class="text-red">&nbsp;*</span>
          </div>
          <el-input
            v-model="newDocument.name"
            placeholder="Enter document name" />
        </el-form-item>
        <el-form-item>
          <div class="list-title mt-2">
            File upload
          </div>
          <ul class="list">
            <li class="text-sm">
              .pdf, .jpeg, .jpg accepted
            </li>
            <li class="text-sm">
              up to 5MB accepted
            </li>
          </ul>
          <el-upload
            class="upload-card"
            drag
            :http-request="onUploadRequest"
            :show-file-list="false"
            accept="image/jpg, application/pdf"
            :multiple="false">
            <i class="icon-novo-folder" />
            <div class="el-upload__text text-sm">
              Click to upload or drag and drop your files here.
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="file-upload-alerts">
        <div
          v-if="uploadState === 'error'"
          class="alert">
          <span class="left-content">
            <span class="divider upload-error" />
            <span class="icon-novo-error upload-error" />
            <span>There was an error. Try again</span>
          </span>
          <span
            class="icon-novo-close-large pointer"
            @click="alertState = ''" />
        </div>
        <div
          v-if="uploadState === 'uploaded'"
          class="uploaded-alert">
          <span class="left-content">
            <span class="icon-novo-check check-icon" />
            <div class="name">
              <el-tooltip
                class="item"
                effect="dark"
                :content="filename || 'test.pdf'"
                placement="top">
                <div class="filename">{{ filename || 'test.pdf' }}</div>
              </el-tooltip>
            </div>
          </span>
          <span class="right-content">
            <span class="file-size">{{ fileSize || '2MB' }}</span>
            <span
              class="icon-novo-close-circle pointer"
              @click="uploadState = ''" />
          </span>
        </div>
        <div
          v-if="uploadState === 'uploading'"
          class="uploading-alert">
          <span class="left-content">
            <span class="spin-loader">
              <svg
                class="spin-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.0711 4.92893C18.29 5.70998 18.3171 6.96835 18.8815 7.91782C19.592 9.11289 20 10.5088 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 8.26504 6.55952 5.12788 10.0202 4.24685C11.0907 3.97435 12 3.10457 12 2C12 0.895431 11.0978 -0.0174693 10.0084 0.164515C4.32883 1.11322 0 6.05108 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 9.40503 23.1763 7.00244 21.7761 5.03942C21.1347 4.14016 19.8521 4.14788 19.0711 4.92893Z"
                  fill="#0056E0" />
              </svg>
            </span>
            <div class="name">
              <el-tooltip
                class="item"
                effect="dark"
                :content="filename"
                placement="top">
                <div class="filename">{{ filename }}</div>
              </el-tooltip>
              <div class="status">Uploading...</div>
            </div>
          </span>
          <span class="right-content">
            <span
              class="icon-novo-close-circle pointer"
              @click="uploadState = ''" />
          </span>
        </div>
        <div
          v-if="uploadState === 'size-error'"
          class="uploading-alert">
          <span class="left-content">
            <span class="icon-novo-error icon-error" />
            <div class="name">
              <el-tooltip
                class="item"
                effect="dark"
                :content="filename"
                placement="top">
                <div class="filename">{{ filename }}</div>
              </el-tooltip>
              <div class="status error">File exceeds size limit.</div>
            </div>
          </span>
          <span class="right-content">
            <span class="file-size">{{ fileSize }}</span>
            <span
              class="icon-novo-close-circle pointer"
              @click="uploadState = ''" />
          </span>
        </div>
      </div>
      <div class="actions">
        <el-button
          @click="visible = false">
          Cancel
        </el-button>
        <el-button
          :disabled="!newDocsFormValid && title.toLowerCase().includes('add')"
          type="primary"
          class="add-document"
          @click="submitAddDocument">
          {{ title.toLowerCase().includes('add') ? 'Add document' : 'Save changes' }}
        </el-button>
      </div>
    </el-dialog>
    <div class="content">
      <div class="header">
        <div class="title">
          Sender ID • Documents & details
        </div>
        <el-tooltip
          placement="right"
          :content="`This page stores all of your company documents and company details,
          required during the registration process. When you input the details while registering a sender ID,
          you can access them here the next time you need them. Alternatively,
          by saving all the details and documents here in advance, you can expedite and simplify your registration process.`">
          <span class="icon text-base icon-novo-support cursor-pointer" />
        </el-tooltip>
      </div>

      <div class="sender-id-docs-tabs">
        <OxygenTabs
          :tab-list="tablist"
          :default-active="activeTab"
          @tab-click="(name) => handleTabClick(name)" />
        <el-button
          v-if="activeTab === 'sender-id-documents-details'"
          type="primary"
          @click="addNewDoc">
          Add new document
        </el-button>
        <el-button
          v-else
          type="primary"
          @click="() => handleAction({action: 'company-add'})">
          Add new company details
        </el-button>
      </div>

      <div class="main">
        <Filters
          :tab="activeTab"
          :search-string-data="searchStringData"
          :results="tableData.length"
          :filters-data="filtersData"
          @download="downloadData"
          @reload="initScript"
          @search="handleSearch" />
        <div v-if="activeTab === 'sender-id-documents-details'">
          <DocumentsDetailsTable
            :table-data="tableData"
            :table-columns="tableColumns"
            :pagination-data="pagination"
            tab="sender-id-documents-details"
            @action="handleAction"
            @paginate="handlePaginate" />
        </div>
        <div v-if="activeTab === 'sender-id-company-details'">
          <DocumentsDetailsTable
            :table-data="tableData"
            :table-columns="tableColumns"
            tab="sender-id-company-details"
            @action="handleAction"
            @paginate="handlePaginate" />
        </div>
      </div>
    </div>
    <div>
      <CompanyForm
        :form-data="companyFormData"
        :show-dialog="companyDialog"
        @action="companyActions" />
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import OxygenTabs from '../../../components/OxygenTabs.vue';
import DocumentsDetailsTable from './DocumentsTable.vue';
import Filters from './Filters.vue';
import timeFormatter from '../../../mixins/timeFormatter';
import senderIdUtil from '../../../mixins/senderIdUtil';
import CompanyForm from './CompanyForm.vue';
import tables from '../../../mixins/tables';
import formUtils from '../../../mixins/formUtils';

export default {
  name: 'SenderIdDocumentsDetails',
  components: {
    OxygenTabs,
    DocumentsDetailsTable,
    Filters,
    CompanyForm,
  },

  mixins: [timeFormatter, senderIdUtil, tables, formUtils],
  data() {
    return {
      visible: false,
      countryHqModal: false,
      confirmDelete: {
        deleteDialog: false,
        title: 'Delete document',
        message: 'Are you sure you want to delete this document?',
        action: 'delete',
      },
      countryHq: {
        code: '',
        country: '',
        hq: '',
        industry: {},
      },
      title: 'Add document',
      activeTab: 'sender-id-documents-details',
      tablist: [
        {
          id: 'sender-id-documents-details',
          label: 'Documents',
        },
        {
          id: 'sender-id-company-details',
          label: 'Company details',
        },
      ],
      pagination: {
        pageSize: 10,
        currentPage: 1,
        totalPages: 1,
        resultCount: 0,
      },
      filters: {
        name: '',
        country: '',
        headquarter: '',
        industry: '',
      },
      filtersData: {
        countries: [],
        industries: [],
      },
      documentsData: [],
      companyData: [],
      newDocument: {
        country: '',
        headquarter: '',
        industry: '',
        name: '',
        type: '',
        countryCode: '',
        updated: '',
        key: '',
      },
      countryIndustries: [],
      hqIndustries: [],
      rules: {
        countryCode: [
          { required: true, message: 'Country is required', trigger: 'change' },
        ],
        headquarter: [
          { required: true, message: 'Headquarter is required', trigger: 'change' },
        ],
        industry: [
          { required: true, message: 'Industry is required', trigger: 'change' },
        ],
        name: [
          { required: true, message: 'Document name is required', trigger: 'blur' },
        ],
      },
      filename: '',
      fileSize: '',
      uploadState: '', // error, uploaded, uploading, size-error
      accountId: '',
      companyDialog: false,
      companyFormData: {
        title: 'Company details',
        action: 'add',
      },
      docsDetailsLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      countries: 'country/getCountriesSupported',
      documentDetails: 'senderIds/getDocumentDetails',
    }),
    tableData() {
      let data = [];
      if (this.activeTab === 'sender-id-documents-details') {
        data = this.documentDetails;
        const {
          name, country, headquarter, industry,
        } = this.filters;
        if (name) {
          data = data.filter(item => item.name.toLowerCase().includes(name.toLowerCase()));
        }
        if (country) {
          data = data.filter(item => item.country.toLowerCase().includes(country.toLowerCase()));
        }
        if (headquarter) {
          data = data.filter(item => item.headquarter.toLowerCase().includes(headquarter.toLowerCase()));
        }
        if (industry) {
          data = data.filter(item => item.industry.toLowerCase().includes(industry.toLowerCase()));
        }
        this.documentDetails.forEach((item) => {
          if (!this.filtersData.countries.includes(item.country)) {
            this.filtersData.countries.push(item.country);
          }
          if (!this.filtersData.industries.includes(item.industry)) {
            this.filtersData.industries.push(item.industry);
          }
        });
      } else if (this.activeTab === 'sender-id-company-details') {
        data = this.companyData.map(item => ({
          ...item,
          address: this.getCompanyAddress(item.fields),
          updated: this.formatDate(item.lastModified, { format: 'MMM D, YYYY' }),
        }));
        const { industry } = this.filters;
        if (industry) {
          data = data.filter(item => item['Company Industry'].toLowerCase().includes(industry.toLowerCase()));
        }
        this.companyData.map(x => x['Company Industry']).forEach((item) => {
          if (!this.filtersData.industries.includes(item)) {
            this.filtersData.industries.push(item);
          }
        });
      }
      return this.paginateData(data);
    },

    tableColumns() {
      if (this.activeTab === 'sender-id-documents-details') {
        return [
          { prop: 'name', label: 'Document name' },
          { prop: 'country', label: 'Country' },
          { prop: 'headquarter', label: 'Headquarter' },
          { prop: 'industry', label: 'Industry' },
          { prop: 'updated', label: 'Last updated' },
          { prop: 'type', label: 'Document type' },
        ];
      }
      return [
        { prop: 'name', label: 'Company name' },
        { prop: 'address', label: 'Address' },
        { prop: 'Company Industry', label: 'Industry' },
        { prop: 'updated', label: 'Last updated' },
      ];
    },

    searchStringData() {
      if (this.activeTab === 'sender-id-documents-details') {
        return this.documentDetails.map(item => ({ label: item.name, value: item.name }));
      }
      if (this.activeTab === 'sender-id-company-details') {
        return this.companyData.map(item => ({ label: item.name, value: item.name }));
      }
      return [];
    },

    newDocsFormValid() {
      const valid = this.areRequiredFieldsNotEmpty(this.newDocument, this.rules);
      return valid && this.uploadState === 'uploaded';
    },
  },

  watch: {
    countries(val) {
      console.log('countries', val);
    },
  },

  async created() {
    const { AccountId: accountId } = this.$auth.user();
    this.accountId = accountId;
    this.initScript();
  },

  async mounted() {
    const { tab = 'sender-id-documents-details' } = this.$route.query;
    this.activeTab = tab;
    this.$router.replace({ query: { tab } });
    await this.resetPagination(tab);
  },

  methods: {
    ...mapActions({
      getDocumentPreview: 'senderIds/getDocumentPreview',
      uploadDocument: 'senderIds/uploadDocument',
      getCountriesSupported: 'country/getCountriesSupported',
      upsertDocumentDetails: 'senderIds/upsertDocumentDetails',
      getDocumentDetails: 'senderIds/getDocumentDetails',
      addCompany: 'senderIds/addCompany',
      getCompanies: 'senderIds/getCompanies',
      deleteCompany: 'senderIds/deleteCompany',
    }),

    downloadData() {
      const isCompanyTab = this.activeTab === 'sender-id-company-details';
      let headers = {
        name: 'Document name',
        country: 'Country',
        headquarter: 'Headquarter',
        industry: 'Industry',
        updated: 'Last updated',
        type: 'Document type',
      };

      if (isCompanyTab) {
        headers = {
          'name': 'Company name',
          'address': 'Address',
          'Company Industry': 'Industry',
          'updated': 'Last updated',
        };
      }
      const items = this.tableData.map((each) => {
        if (isCompanyTab) {
          return {
            'name': each.name || '-',
            'address': each.address || '-',
            'Company Industry': each['Company Industry'] || '-',
            'updated': each.updated || '-',
          };
        }
        const {
          name, country, headquarter, industry, updated, type,
        } = each;
        return {
          name,
          country,
          headquarter,
          industry,
          updated,
          type,
        };
      });

      this.exportCSVFile(headers, items, isCompanyTab ? 'Company details' : 'Documents');
    },

    async initScript() {
      this.docsDetailsLoading = true;
      await this.getCountriesSupported();
      await this.getDocumentDetails({ accountId: this.accountId });
      await this.getCompaniesList({ accountId: this.accountId });
      this.docsDetailsLoading = false;
    },

    countryHQChange(value) {
      const country = this.countries.find(item => item.code === value);
      this.hqIndustries = country.industries;
      this.countryHq = {
        ...this.countryHq,
        country: country.name,
        code: value,
      };
      this.companyFormData = {
        ...this.companyFormData,
        country,
      };
    },

    HQChange(value) {
      const { country } = this.companyFormData;
      this.companyFormData = {
        ...this.companyFormData,
        country: {
          ...country,
          headquarter: value,
        },
      };
    },

    async companyActions(event) {
      const { action } = event;
      if (action === 'cancel') {
        this.companyDialog = false;
      } else if (['edit', 'add'].includes(action)) {
        const { form } = event;
        await this.addEditCompany(form, action === 'edit');
        await this.getCompaniesList({ accountId: this.accountId });
        this.countryHqModal = false;
        this.companyDialog = false;
      }
    },

    async addEditCompany(fields, isEdit = false) {
      const {
        country: {
          formId, industry, hq, code,
        },
        values: { key: fileKey },
      } = this.companyFormData;

      const params = {
        accountId: this.accountId,
        data: {
          id: fields.find(field => field.name === 'Company Name').rawValue,
          data: {
            ...fields,
            industry: industry.id,
            country: code,
            hq,
            formId: code === 'TH' ? 34237101714585 : formId,
            fields,
          },
          editing: {
            isEdit,
            key: fileKey,
          },
        },

      };
      await this.addCompany(params);
    },

    addNewDoc() {
      this.newDocument = {
        country: '',
        headquarter: '',
        industry: '',
        name: '',
        type: '',
        countryCode: '',
        updated: '',
        key: '',
      };
      this.title = 'Add document';
      this.visible = true;
      const docsFromRef = this.$refs['documents-details-form'];
      if (docsFromRef) {
        docsFromRef.resetFields();
      }
    },

    handleAction({ action, row }) {
      if (action === 'edit') {
        this.newDocument = { ...row };
        this.title = 'Edit document';
        this.visible = true;
      } else if (action === 'delete') {
        this.newDocument = { ...row };
        this.confirmDelete = {
          ...this.confirmDelete,
          deleteMsg: 'Are you sure you want to delete this document?',
          deleteDialog: true,
          action: 'docs-delete',
        };
      } else if (action === 'company-edit') {
        const country = this.countries.find(item => item.code === row.country);
        const { hq, industry, 'Company Industry': industryName } = row;
        this.companyFormData = {
          title: 'Edit company details',
          action: 'edit',
          values: row,
          country: { ...country, hq, industry: { id: industry, name: industryName } },
          saveText: 'Save changes',
        };
        this.companyDialog = true;
      } else if (action === 'company-add') {
        this.companyFormData = {
          title: 'Add company details',
          action: 'add',
          saveText: 'Add details',
        };
        this.countryHqModal = true;
      } else if (action === 'continue-add') {
        const industry = this.hqIndustries.find(item => item.name === this.countryHq.industry);
        this.companyFormData = {
          ...this.companyFormData,
          country: { ...this.companyFormData.country, ...this.countryHq, industry },
          values: [
            {
              name: 'industry', value: industry.name, type: 'tagger', rawValue: industry.id,
            },
            {
              name: 'country', value: this.countryHq.country, type: 'tagger', rawValue: this.countryHq.country,
            },
            {
              name: 'headquarter', value: this.countryHq.hq, type: 'tagger', rawValue: this.countryHq.hq,
            },
          ],
        };
        this.companyDialog = true;
      } else if (action === 'step-cancel') {
        this.countryHqModal = false;
      } else if (action === 'company-delete') {
        this.companyFormData = {
          title: 'Delete company details',
          action: 'delete',
          values: row,
        };
        this.confirmDelete = {
          ...this.confirmDelete,
          deleteMsg: 'Are you sure you want to delete this details?',
          deleteDialog: true,
          action: 'company-delete',
        };
      }
    },

    async handleDeletion(action) {
      this.confirmDelete.deleteDialog = false;
      this.docsDetailsLoading = true;
      if (action === 'docs-delete') {
        await this.submit('delete');
        await this.getDocumentDetails({ accountId: this.accountId });
      } else if (action === 'company-delete') {
        const { key: fileKey } = this.companyFormData.values;
        await this.deleteCompany({ accountId: this.accountId, data: { fileKey } });
        await this.getCompaniesList({ accountId: this.accountId });
      }
      this.docsDetailsLoading = false;
    },

    async getCompaniesList({ accountId }) {
      const { data } = await this.getCompanies({ accountId });
      let flatFields = [];
      if (data) {
        flatFields = await data.map((comp) => {
          const { industries } = this.countries.find(item => item.code === comp.country);
          const industry = industries.find(x => x.id === comp.industry);
          return {
            ...comp,
            'name': comp.fields.find(field => field.name === 'Company Name').rawValue,
            'Company Industry': industry ? industry.name : '',
          };
        });
      }
      this.companyData = flatFields;
    },

    handleCountryChange(value) {
      const country = this.countries.find(item => item.name === value);
      this.countryIndustries = country.industries;
      this.newDocument.countryCode = country.code;
    },

    async submit(command) {
      try {
        await this.upsertDocumentDetails({ accountId: this.accountId, data: this.newDocument, command });
        await this.getDocumentDetails({ accountId: this.accountId });
        this.newDocument = {
          country: '',
          headquarter: '',
          industry: '',
          name: '',
          type: '',
          countryCode: '',
          updated: '',
          key: '',
        };
      } catch (error) {
        this.$showError(this, error);
      }
    },

    submitAddDocument() {
      this.$refs['documents-details-form'].validate(async (valid) => {
        if (valid) {
          const command = this.title.toLowerCase().includes('add') ? 'insert' : 'update';
          await this.submit(command);
          this.visible = false;
        }
      });
    },

    async onUploadRequest({ file }) {
      this.uploadState = 'uploading';
      this.filename = file.name;
      this.newDocument.type = file.type;
      let unit = 'MB';
      let size = Math.floor(file.size / 1000000); // MB
      if (size < 1) {
        unit = 'KB';
        size = Math.floor(file.size / 1000); // KB
      }
      this.fileSize = `(${size} ${unit})`;
      if (size > 5 && unit === 'MB') {
        this.uploadState = 'size-error';
        return 'error';
      }

      const filenameArr = this.filename.split('.');
      const ext = filenameArr.pop();

      const id = `${filenameArr.join()}-${new Date().getTime()}.${ext}`;
      this.id = id;
      try {
        const { key } = await this.uploadDocument({ file, id });
        if (key) {
          this.newDocument.key = key;
          this.newDocument.updated = new Date().toISOString();
          this.uploadState = 'uploaded';
        } else {
          this.uploadState = 'error';
        }
      } catch (error) {
        this.uploadState = 'error';
      }

      return 'done';
    },

    async handleTabClick(name) {
      this.docsDetailsLoading = true;
      this.$router.replace({ query: { tab: name } });
      await this.resetPagination(name);
      this.activeTab = name;
      this.docsDetailsLoading = false;
    },

    handleSearch(searchString) {
      this.filters = { ...this.filters, ...searchString };
    },

    handlePaginate(pagination) {
      this.pagination = pagination;
    },

    resetPagination(tab) {
      if (tab === 'sender-id-documents-details') {
        this.pagination = {
          pageSize: 10,
          currentPage: 1,
          totalPages: Math.ceil(this.documentsData.length / 10),
          resultCount: this.documentsData.length,
        };
      }
      if (tab === 'sender-id-company-details') {
        this.pagination = {
          pageSize: 10,
          currentPage: 1,
          totalPages: Math.ceil(this.companyData.length / 10),
          resultCount: this.companyData.length,
        };
      }
    },

    paginateData(data) {
      const { pageSize, currentPage } = this.pagination;
      const start = (currentPage - 1) * pageSize;
      const end = start + pageSize;
      return data.slice(start, end);
    },
  },
};
</script>

<style lang="scss">

.sender-id-documents-details {
    display: flex;
    width: 100%;
    .content {
        padding: 0px 9px;
        width: 100%;
        .header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            .title {
                font-family: Inter;
                font-size: 28px;
                font-weight: 600;
                line-height: 40px;
                letter-spacing: -0.019em;
                text-align: left;
            }

            .icon {
                font-size: 24px;
            }
        }

        .sender-id-docs-tabs {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
        }

        .main {
            background: #fff;
            margin-top: 8px;
            box-shadow: 0px 2px 6px 0px #29292940;
            border-radius: 8px !important;
            border: 1px solid #E0E0E0;
        }
    }

    .documents-delete-dialog, .documents-country-hq-dialog {
      width: 450px !important;

      .el-dialog__title {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.017em;
        text-align: left;
      }
      .el-dialog__body {
        padding: 24px 24px 0px 24px !important;
      }

      .el-dialog__header {
        border: none !important;
        padding: 24px 24px 0 24px !important;
      }

      .content {
        .field {
          margin-bottom: 16px;
          .pb-1 {
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.019em;
            text-align: left;
            color: #000000;
            padding-bottom: 8px;
          }
        }
      }
      .actions {
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          padding: 24px 0px 24px 24px;
          .el-button {
            &.is-disabled {
              color: #858585 !important;
              background: #C2C2C2 !important;
            }
          }
        }

      .actions {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        padding: 24px 0px 24px 24px;
      }
    }
    .documents-details-dialog {
      width: 450px !important;

      .el-dialog__title {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.017em;
        text-align: left;
      }

      .el-dialog__body {
        padding: 24px !important;
      }

      .el-dialog__header {
        border: none !important;
        padding: 24px 24px 0 24px !important;
      }

      .el-form-item__content {
        line-height: 20px;
      }

      .el-form-item {
        margin-bottom: 16px;

        &.is-error {
          .el-input__inner {
            border: solid 1px #FF4D4F !important;
          }
        }

        .list {
          padding-left: 20px !important;
          padding-top: 8px;
        }

        .list-title {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.019em;
          text-align: left;
          color: var(--primary);
          padding-bottom: 12px;
        }
      }

      .upload-card {
        padding-bottom: 15px;
        .el-upload {
          width: 100%;
          height: 116px;
          border-radius: 8px;

          .el-upload-list {
            margin-top: 16px;
          }
        }
        .el-upload-dragger {
          height: 116px;
          border-radius: 8px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 8px;
          border-color: var(--accent-color) !important;
          color: var(--primary) !important;

        }
        .icon-novo-folder {
          font-size: 24px;
          color: var(--primary);
        }
        .el-upload__text {
          // font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.011em;
          text-align: center;
          color: var(--primary);
        }
      }

      .alert, .uploaded-alert {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 8px 16px 8px 0px;
        border-radius: 6px;
        margin-top: 16px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.011em;
        text-align: left;
        color: #000000;
        justify-content: space-between;
        height: 40px;
        .left-content {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: flex-start;
          .upload-error {
            color: #CB2233 !important;
          }
          .name {
            .filename {
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .status {
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
            }
          }
          .check-icon {
            padding-left: 16px;
            font-size: 20px !important;
            color: #0056E0 !important;
          }
          .spin-loader {
            padding-left: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            .spin-icon {
              fill: #0056E0;
              width: 24px;
              height: 24px;
              animation: spin 1s linear infinite;
            }

            @keyframes spin {
                from {transform:rotate(0deg);}
                to {transform:rotate(360deg);}
            }
          }
          .divider {
            background: #666666;
            width: 4px;
            height: 36px;
            gap: 0px;
            border-radius: 4px;

            &.upload-error {
              background: #CB2233 !important;
            }
          }
        }
        .right-content {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: flex-end;

          .file-size {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: right;
            color: #666666;
            width: 70px;
            font-variant-numeric: tabular-nums;
          }
        }
      }

      .uploading-alert {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 8px 16px 8px 0px;
        border-radius: 6px;
        margin-top: 16px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.011em;
        text-align: left;
        color: #000000;
        justify-content: space-between;
        .left-content {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: flex-start;
          .icon-error {
            color: #CB2233 !important;
            font-size: 20px !important;
            padding-left: 16px !important;
          }
          .name {
            .filename {
              font-family: Inter;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .status {
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;

              &.error {
                color: #CB2233;
              }
            }
          }
          .spin-loader {
            padding-left: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            .spin-icon {
              fill: #0056E0;
              width: 24px;
              height: 24px;
              animation: spin 1s linear infinite;
            }

            @keyframes spin {
                from {transform:rotate(0deg);}
                to {transform:rotate(360deg);}
            }
          }
          .divider {
            background: #666666;
            width: 4px;
            height: 36px;
            gap: 0px;
            border-radius: 4px;

          }
        }
        .right-content {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .file-size {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: right;
            color: #666666;
          }
        }
      }

      .alert {
        border: 1px solid #E0E0E0;
        background: #FFFFFF;
      }

      .uploading-alert, .uploaded-alert {
        border: 1px solid #E0E0E0;
        background: transparent;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 24px;
        .add-document {
          height: 40px;
          padding: 10px 16px 10px 16px;
          margin: 0px !important;
          gap: 8px;
          border-radius: 6px;
          color: #fff;
          background: var(--accent-color) !important;
          &.is-disabled {
            color: #858585 !important;
            background: #C2C2C2 !important;
          }
        }
      }
    }
}
</style>
