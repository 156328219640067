<template>
  <el-dialog
    width="800px"
    :visible.sync="show"
    :show-close="false"
    class="company-dialog">
    <div
      v-loading="loading"
      :element-loading-text="loadingText">
      <div
        slot="title"
        class="custom-header">
        <span>{{ title }}</span>
        <el-button
          type="text"
          @click="handleClose">
          <i class="text-2xl icon-novo-close-large" />
        </el-button>
      </div>
      <div class="content">
        <!-- Add company details -->
        <div v-if="operation === 'add' || operation === 'edit'">
          <el-form
            ref="form"
            label-position="top"
            :model="form"
            :rules="rules"
            class="company-form">
            <div
              v-for="(field) in companyFields"
              :key="field.key">
              <!-- if input type is text or textarea -->
              <TextInput
                v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
                :key="field.key"
                :value="form"
                :textarea="field.type === 'textarea'"
                :field-name="field.key"
                :metadata="formData.country"
                @input="(newFormValue) => { form = newFormValue }" />

              <!-- if input type is select -->
              <SelectInput
                v-if="field.type === 'tagger'"
                :key="field.key"
                :value="form"
                :field-name="field.key"
                :metadata="formData.country"
                @input="(newFormValue) => { form = newFormValue }" />

              <!-- if input type is a date -->
              <DateRangeInput
                v-if="field.type === 'dateRange'"
                :key="field.key"
                :value="form"
                :field-name="field.key"
                :metadata="formData.country"
                @input="(newFormValue) => { form = newFormValue }" />
            </div>
          </el-form>
        </div>

        <!-- Select company -->
        <div v-if="operation === 'select'">
          <div class="flex-shrink border border-grey-lighter">
            <Filters
              :table-data="tableData"
              @search="handleSearch"
              @reload="handleReload" />
          </div>
          <div class="border border-grey-lighter">
            <Table
              :table-data="tableData"
              :loading="loading"
              @select="selectCompany"
              @edit="editCompany"
              @reload="handleReload" />
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer flex items-center"
        :class="(operation === 'add' && hasCompanyList === 'yes') || ['select'].includes(operation) ? 'justify-between' : 'justify-end'">
        <el-button
          v-if="operation === 'add' && hasCompanyList === 'yes'"
          type="text"
          @click="update('select')">
          Select company instead
        </el-button>
        <el-button
          v-else-if="operation === 'select'"
          type="text"
          @click="update('add')">Add company instead</el-button>
        <span>
          <el-button
            @click="handleClose()">Close</el-button>
          <el-button
            type="primary"
            :disabled="disableCTAButton"
            @click="handleSubmit()">{{ callToActionText }}</el-button>
        </span>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { mapActions, mapGetters } from 'vuex';
import timeFormatter from '../../../../mixins/timeFormatter';
import DateRangeInput from '../../partials/DateRangeInput.vue';
import SelectInput from '../../partials/SelectInput.vue';
import TextInput from '../../partials/TextInput.vue';
import Filters from './Filters';
import Table from './Table';
import formUtils from '../../../../mixins/formUtils';

export default {
  name: 'CompanyForm',

  components: {
    TextInput,
    SelectInput,
    DateRangeInput,
    Filters,
    Table,
  },

  mixins: [timeFormatter, formUtils],

  props: {
    title: {
      type: String,
      required: true,
    },

    show: {
      type: Boolean,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    loadingText: {
      type: String,
      required: true,
    },

    companyFields: {
      type: Array,
      required: true,
    },

    companyList: {
      type: Array,
      required: true,
    },

    operation: {
      type: Boolean,
      required: true,
    },

    select: {
      type: Function,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },

    hasCompanyList: {
      type: String,
      default: 'no',
    },
  },

  data() {
    return {
      customFields: [],
      attachments: [],
      attachementsChecklist: null,
      form: {},
      rules: {},
      hasCustomForm: false,
      formId: null,
      customFormOptions: [],
      hasLocalEntity: false,
      accountId: '',
      selectedSubAccountId: '',
      phBestTimeCall: false,
      userId: '',
      dialogTitle: '',
      dataSize: 0,
      companyOperation: false,
      pagination: {
        size: 10,
        page: 1,
      },
      selectedCompany: {},
      addressFields: ['Country', 'City', 'Postal Code', 'Company Address Line 1', 'Company Address Line 2'],
      callToActionText: '',
      tableData: [],
      formValid: false,
    };
  },

  computed: {
    ...mapGetters({
      country: 'country/getCountry',
      article: 'articles/getArticle',
      countryRules: 'articles/getCountryRules',
      companyFormType: 'country/getCompanyFormId',
      countryCustomFields: 'country/getCustomFields',
      dateFields: 'country/getDateFields',
      customHeader: 'country/getCustomHeader',
      subAccounts: 'common/getSubAccountsSMS',
      draft: 'senderIds/getDraft',
    }),

    disableCTAButton() {
      return (this.operation === 'select' && Object.keys(this.selectedCompany).length === 0) || (this.operation === 'add' && !this.formValid);
    },
  },

  watch: {
    companyFields() {
      if (Object.keys(this.rules).length <= 0) {
        this.setFormRules();
      }
    },

    operation(val) {
      if (val === 'add') {
        this.callToActionText = 'Add details';
      }

      if (val === 'edit') {
        this.callToActionText = 'Save details';
      }

      if (val === 'select') {
        this.callToActionText = 'Select';
      }
    },

    companyList(val) {
      this.tableData = val;
    },
    form: {
      handler(val) {
        this.formValid = this.areRequiredFieldsNotEmpty(val, this.rules);
      },
      deep: true,
    },
  },

  created() {
    // this.getCountries();
    this.setFormRules();
    this.tableData = this.companyList;
  },

  methods: {
    ...mapActions({
      setCountry: 'country/setCountry',
      setCompanyFields: 'country/setCompanyFields',
      createRequest: 'articles/createRequest',
      getArticle: 'articles/getArticle',
      getSenderIdCountryRules: 'articles/getSenderIdCountryRules',
      addSenderIdRegistration: 'senderIds/addSenderIdRegistration',
    }),

    editCompany(data) {
      this.form = {};

      this.$emit('update', {
        operation: 'edit',
      });

      const formCopy = {};
      data.fields.forEach((field) => {
        formCopy[field.id] = field.value;
      });

      this.form = Object.assign({}, this.form, formCopy);
    },

    selectCompany(data) {
      this.selectedCompany = data;
    },

    update(val) {
      this.$emit('update', {
        operation: val,
      });
    },

    handleClose() {
      this.form = {};
      this.$emit('close', false);
    },

    handleSearch(value) {
      this.tableData = this.tableData.filter(c => c.companyName === value);
    },

    handleReload() {
      this.tableData = this.companyList;
    },

    paginateData(data) {
      if (!data || data.length === 0) return [];

      const index = this.pagination.page ? this.pagination.page - 1 : 0;
      const size = this.pagination.size ? this.pagination.size : 10;
      const chunks = _.chunk(data, size);

      return chunks[index];
    },

    setFormRules(data, hasdata = false) {
      const rules = {};
      const form = {};
      let countryRules = this.companyFields;

      if (hasdata) {
        countryRules = data;
      }
      countryRules.forEach((customField) => {
        form[customField.key] = '';
        rules[customField.key] = [
          {
            required: customField.required_in_portal,
            validator: (rule, value, callback) => this.validate(rule, value, callback, customField),
            trigger: 'change',
            titleInPortal: customField.title_in_portal,
            key: customField.key,
          },
        ];
      });

      if (!hasdata) {
        this.form = form;
      }
      this.rules = rules;
    },

    validate(rule, value, callback, customField) {
      const { customValidation } = customField;
      if (value && customValidation) {
        customValidation.forEach((field) => {
          if (!field.rule.test(value)) {
            callback(new Error(`${field.message}`));
          }
        });
      }

      if (value && customField.type === 'integer') {
        const pn = parsePhoneNumber(`+${value}`);
        if (!pn.valid) {
          callback(new Error('Contact Number must be in an international format. (e.g. +65 9876 5432)'));
        }
      }

      if (!value && customField.required_in_portal) {
        callback(new Error(`${customField.raw_title_in_portal} is required`));
      }

      callback();
    },

    getFormRule(titleInPortal) {
      const temp = Object.keys(this.rules).map(el => this.rules[el]).filter(rule => titleInPortal === rule[0].titleInPortal);
      return temp[0][0];
    },

    setRuleRequired(titleInPortal, required) {
      const companyFields = this.companyFields.map((el) => {
        if (el.title_in_portal === titleInPortal) {
          const rule = {
            ...el,
            required_in_portal: required,
          };
          return rule;
        }
        return el;
      });
      this.setFormRules(companyFields, true);
    },

    handleSubmit() {
      if (this.operation === 'select') {
        this.$emit('select', {
          operation: 'select',
          company: this.selectedCompany,
        });

        this.$emit('close');

        return;
      }

      if (this.country.code === 'PH') {
        const contactNumberRule = this.getFormRule('Contact Number of Signing Officer');
        if (this.form[contactNumberRule.key]) {
          this.setRuleRequired('Best Time To Call', true);
        } else {
          this.setRuleRequired('Best Time To Call', false);
        }
      }

      this.$refs.form.validate((valid) => {
        if (!valid) { return; }

        this.$emit('set-loading', {
          loadingText: 'Saving company details',
        });

        const fields = [];

        for (const field in this.form) { // eslint-disable-line
          const customField = this.companyFields.find(f => f.key === field);

          // Filter sender id info fields
          // eslint-disable-next-line no-continue
          if (customField.isSenderIdInformation) continue;

          const value = this.form[field];

          const formattedField = {
            id: customField.id,
            name: customField.raw_title_in_portal,
            type: customField.type,
            rawValue: value,
            value,
          };

          if (formattedField.name === 'Sub Account ID') {
            formattedField.type = 'text';
            this.selectedSubAccountId = formattedField.value;
          }

          if (customField.type === 'tagger' && customField.custom_field_options && customField.custom_field_options.length) {
            if (customField.custom_field_options.find(option => option.value === value)) {
              formattedField.rawValue = customField.custom_field_options.find(option => option.value === value).name;
            } else {
              formattedField.rawValue = customField.custom_field_options.find(option => option.name === value).name;
            }
          }

          fields.push(formattedField);
        }

        // Set selected company
        this.$emit('select', {
          operation: 'add',
          company: {
            formId: this.formId,
            fields,
          },
        });

        // Reset
        this.form = {};
        this.rules = {};
      });
    },
  },
};
</script>
<style lang="scss">
.company-dialog {
  .el-dialog__header {
    padding: 0;
    border: none;
  }
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  font-size: 18px;
  padding-bottom: 30px;
}

.company-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 24px;
}

.dialog-footer {
  padding-top: 30px;
}
</style>
