<!-- eslint-disable vue/valid-v-model -->
<template>
  <el-dialog
    :height="dialogHeight"
    :width="dialogWidth"
    :top="dialogTop"
    :visible="dialogVisible"
    :modal-append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @open="handleOpen"
    @close="handleClose">
    <DigitalSigningDialog
      :dialog-visible="digitalSigningDialogVisible"
      :ticket-number="dialogTicketId"
      :dialog-title="signatureDialogTitle"
      @close-digital-signging-dialog="() => { digitalSigningDialogVisible = false }"
      @close-sign-loa-dialog="handleClose" />
    <WetSignatureDialog
      :loa-unsigned="loaUnsigned"
      :dialog-visible="wetSignatureDialogVisible"
      :ticket-number="dialogTicketId"
      @reload-by-wet-signature="handleReload"
      @try-another-way="() => { wetSignatureDialogVisible = false }"
      @close-wet-signature-dialog="handleCloseWetSignature" />
    <span
      slot="title"
      class="no-border">
      <b class="text-lg">Sign LOA</b>
      <el-tooltip placement="top-start">
        <div slot="content">
          The Letter of Authorization (LOA) is a crucial document in<br>
          the SMS sender registration process which helps verify the<br>
          legitimacy of the sender, ensures compliance with<br>
          regulations, and establishes accountability.<br>
          Note that once the LOA is signed and submitted, 8x8 will<br>
          begin the registration process. The whole registration will<br>
          take anywhere between 7-21 working days.
        </div>
        <span class="icon-novo-info-circle text-xl ml-2" />
      </el-tooltip>
    </span>
    <span class="no-border">
      <div class="mb-8">
        Please select your preferred signing method.
      </div>
      <el-form
        ref="form"
        v-loading="loading"
        :model="form">
        <el-form-item>
          <el-radio-group v-model="form.signatureMethod">
            <div v-if="signatureMethods.length">
              <div
                v-for="(item, index) in signatureMethods"
                :key="index">
                <el-card
                  :key="index"
                  shadow="never"
                  disabled
                  class="box-card mb-2">
                  <!-- <div v-if="item.id > 0">
                    <el-radio
                      v-model="item.id"
                      :label="item.id"
                      disabled
                      class="radio-box">
                      <span class="radio-name">{{ item.name }}</span>
                      <div class="radio-description mt-3">{{ item.description }}</div>
                    </el-radio>
                  </div> -->
                  <div>
                    <el-radio
                      v-model="item.id"
                      :label="item.id"
                      class="radio-box">
                      <span class="radio-name">{{ item.name }}</span>
                      <div class="radio-description mt-3">{{ item.description }}</div>
                    </el-radio>
                  </div>
                </el-card>
              </div>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </span>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        type="text"
        class="text-primary mr-2"
        @click="handleClose()">
        Cancel
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="onSubmit()">
        Select
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import DigitalSigningDialog from './signing/DigitalSigningDialog.vue';
import WetSignatureDialog from './signing/WetSignatureDialog.vue';

export default {
  name: 'SignLoaDialog',

  components: {
    DigitalSigningDialog,
    WetSignatureDialog,
  },

  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },

    dialogTitle: {
      type: String,
      default: '',
    },

    ticketNumber: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      form: {
        signatureMethod: 0,
      },
      dialogHeight: 'auto',
      dialogWidth: '500px',
      dialogTop: '20vh',
      selectedSignatureMethod: null,
      signatureMethods: [],
      dialogTicketId: null,
      signatureDialogTitle: '',
      digitalSigningDialogVisible: false,
      wetSignatureDialogVisible: false,
      loading: false,
      loadingList: [], // ['signatureMethods', 'submissionData'],
      loaUnsigned: {},
    };
  },

  watch: {
    loadingList() {
      this.loading = !this.loadingList.includes('signatureMethods') && !this.loadingList.includes('submissionData');
    },
  },

  mounted() {
  },

  methods: {
    ...mapActions({
      getSignatureMethods: 'senderIds/getSignatureMethods',
      getSubmissionData: 'senderIds/getSubmissionData',
    }),
    handleCloseWetSignature() {
      this.wetSignatureDialogVisible = false;
      this.$emit('close-dialog');
    },
    handleReload() {
      this.$emit('reload-by-wet-signature');
    },
    handleOpen() {
      const { AccountId: accountId } = this.$auth.user();
      this.dialogTicketId = this.ticketNumber;
      this.fetchSignatureMethods(accountId);
      this.fecthSubmissionData({ accountId, ticketId: this.ticketNumber });
    },
    handleClose() {
      this.$emit('close-dialog');
    },
    handleSuccessClose() {
      this.$emit('close-dialog');
      this.$emit('signing-complete');
    },
    async fetchSignatureMethods(accountId) {
      this.loading = true;
      await this.getSignatureMethods({ ticketId: this.dialogTicketId })
        .then((response) => {
          this.signatureMethods = response;
        })
        .catch((err) => {
          if (window.Bugsnag) {
            window.Bugsnag.notify(
              new Error('Unable to get LOA signature methods.'),
              (event) => {
                event.severity = 'error';
                event.addMetadata('details', {
                  message: err,
                  accountId,
                  ticketId: this.dialogTicketId,
                });
              },
            );
          }
          this.$showError(this, 'Unable to get LOA signature methods. Please try again later.');
        })
        .finally(() => {
          this.loadingList.push('signatureMethods');
        });
    },
    async fecthSubmissionData(params) {
      try {
        const s3Data = await this.getSubmissionData(params);
        const {
          loa: {
            files,
            isClientLoaDownloaded,
            isUploadLater,
          },
        } = s3Data;
        const unsigned = files.find(file => file.loaType === 'client' && !file.signed && file.filetype === 'pdf');

        const loaUnsigned = { isClientLoaDownloaded, isUploadLater };

        if (unsigned) {
          this.loaUnsigned = {
            ...loaUnsigned,
            clientLoa: unsigned,
          };
        }

        if (isClientLoaDownloaded || isUploadLater) {
          this.wetSignatureDialogVisible = true;
          this.signatureDialogTitle = 'Sign LOA';
        }
      } catch (error) {
        console.log('🚀 ~ fecthSubmissionData ~ error:', error.message);
      } finally {
        this.loadingList.push('submissionData');
      }
    },
    onSubmit() {
      this.selectedSignatureMethod = this.form.signatureMethod;
      switch (this.selectedSignatureMethod) { // Refer to config.loa.signatureMethods array in config.default.js
        case 0: // Digital Signature: DocuSign
          this.digitalSigningDialogVisible = true;
          this.signatureDialogTitle = this.signatureMethods.find(i => i.id === 0).name;
          // window.location.href = `/messaging/sender-id/registration/loa/digital-signing/${this.dialogTicketId}`;
          break;
        case 2: // Wet Signature Only
        case 3: // Wet Signature and Company Stamp
          this.wetSignatureDialogVisible = true;
          this.signatureDialogTitle = 'Sign LOA';
          break;
        default:
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.loading-spin {
  transform-origin: center;
  animation: spin 1s linear infinite;
}

.box-card {
  border: 1px solid #0056e1;
  background-color: #f0f1f0;
}

.radio-box {
  padding: 5px;
  white-space: normal;
  word-break: break-word;
}

.radio-name {
  color: #000000 !important;
}

.radio-description {
  color: #777767 !important;
  margin-left: 20px;
  line-height: 1.3;
  font-size: 13px;
  font-weight: 300;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}
</style>
