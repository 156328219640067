<template>
  <div
    ref="scrollContainer"
    class="scrollable flex flex-col h-full">
    <div class="flex items-center justify-between">
      <h3>Review</h3>
      <div>
        <el-button
          type="text"
          class="p-0"
          @click="fetchCountryRules()">
          <span class="flex items-center primary">
            <span class="text-lg icon-novo-info-circle mr-2 body-1 primary-color" />
            Show country regulations
          </span>
        </el-button>
        <el-dialog
          :visible="Object.keys(countryRules).length > 0 && showCountryRules"
          width="600px"
          @close="setCountryRules({})">
          <template slot="title">
            <div class="flex items-center">
              <span class="text-xl icon-novo-info-circle mr-2" />
              <h2>Country regulations</h2>
            </div>
          </template>
          <div class="content">
            <div
              v-if="countryRules.body"
              class="article"
              v-html="$sanitize(countryRules.body)" />
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="w-full">
      <div
        role="alert"
        class="my-4 el-alert el-alert--info is-light">
        <i class="el-alert__icon icon-novo-info-circle is-small" />
        <div class="el-alert__content">
          <p class="el-alert__description">
            Scroll down and review all details entered by you.
            Once you submit , our team will review the details.
            After approval, you will be notified to sign an LOA for completeing your Sender ID request.
          </p>
          <i class="el-alert__closebtn el-icon-close" />
        </div>
      </div>
    </div>
    <div>
      <!-- country details -->
      <div class="mt-2">
        <h4>
          Country & industry
        </h4>
        <div>
          <BodyText
            label="Destination country"
            :value="formData.country.name" />
          <BodyText
            label="Headquarter"
            :value="formData.country.headquarter" />
          <BodyText
            label="Industry"
            :value="formData.country.industry.name" />
        </div>
      </div>
      <el-divider />
      <!-- company details -->
      <div class="mb-3">
        <h4>
          Company details
        </h4>
        <div class="grid-cols-2">
          <div>
            <div
              v-for="(field, i) in formData.company.fields"
              :key="i">
              <BodyText
                v-if="!addressFields.includes(field.name)"
                :label="field.name"
                :class-text="'grid-cols-2'"
                :value="field.type === 'tagger' ? field.rawValue : field.value" />
            </div>
          </div>
          <div>
            <div
              v-for="(field, i) in formData.company.fields"
              :key="i">
              <BodyText
                v-if="addressFields.includes(field.name)"
                :label="field.name"
                :class-text="'grid-cols-2'"
                :value="field.type === 'tagger' ? field.rawValue : field.value" />
            </div>
          </div>
        </div>
      </div>
      <el-divider />
      <!-- documents/attachments -->
      <div v-if="formData.documents && formData.documents.length > 0">
        <h4>
          Company documents
        </h4>
        <div class="grid-cols-4">
          <div class="body-1 weight-400">
            Total attached
          </div>
          <div class="body-1 weight-600">
            <span class="attach-stat">
              6/6 attached
            </span>
          </div>
        </div>
        <BodyText
          v-for="(doc, docIndex) in formData.documents"
          :key="doc.id"
          :label="`Document ${docIndex + 1}`"
          :value="doc.name" />
      </div>
      <el-divider v-if="formData.documents && formData.documents.length > 0" />
      <!-- Sender ID Entries -->
      <div>
        <h4 class="pb-5">
          Sender ID details
        </h4>
        <div
          v-for="(senderId, index) in senderIds"
          :key="senderId.id">
          <div :class="`pb-4 ${index > 0 ? 'pt-4': ''}`">
            <div class="grid-cols-4">
              <div class="body-1 weight-400">
                Serial no.
              </div>
              <div class="body-1 weight-600">
                <span class="serial-no">
                  {{ index + 1 }}/{{ senderIds.length }}
                </span>
              </div>
            </div>
            <div class="grid-cols-2">
              <div>
                <div
                  v-for="(field) in senderId.fields.filter(f => f.name !== 'Content of Message')"
                  :key="field.id">
                  <BodyText
                    :label="field.name"
                    :class-text="'grid-cols-2'"
                    :value="field.type === 'tagger' ? field.rawValue : field.value" />
                </div>
              </div>
              <div>
                <div
                  v-for="(field) in senderId.fields.filter(f => f.name === 'Content of Message')"
                  :key="field.id">
                  <BodyText
                    :label="field.name"
                    :class-text="'grid-cols-2'"
                    :value="field.type === 'tagger' ? field.rawValue : field.value" />
                </div>
                <div v-if="senderId.data && senderId.data.hasAttachments">
                  <BodyText
                    label="Attachments"
                    :class-text="'grid-cols-2'"
                    :value="`${senderId.data.Attachments.length}`" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import senderIdUtil from '../../../../mixins/senderIdUtil';
import BodyText from './BodyText.vue';

export default {
  name: 'ReviewComponent',
  components: {
    BodyText,
  },

  mixins: [senderIdUtil],

  props: {
    formData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      senderIds: [],
      senderIdDates: [],
      showCountryRules: false,
      addressFields: ['Country', 'City', 'Postal Code', 'Company Address Line 1', 'Company Address Line 2'],
    };
  },

  computed: {
    ...mapGetters({
      country: 'country/getCountry',
      countries: 'country/getCountriesSupported',
      countryRules: 'articles/getCountryRules',
      countryCustomFields: 'country/getCustomFields',
      user: 'user/getUser',
    }),
  },

  created() {
    const formCopy = JSON.parse(JSON.stringify(this.formData));
    this.senderIds = formCopy.senderIds.map((sid) => {
      const newFields = sid.fields.filter(f => f.type !== 'date' && f.type !== 'uploader');
      const dates = sid.fields.filter(f => f.type === 'date');
      const name = newFields.filter(f => f.name === 'Sender ID')[0].value;
      const docs = sid.fields.filter(f => f.type === 'uploader');
      delete sid.fields;
      return {
        fields: newFields,
        dates,
        name,
        attachments: docs.length > 0 ? docs[0].value : [],
        ...sid,
      };
    });
    // this.senderIds = [];
  },

  mounted() {
    console.log(this.formData);
    if (this.$refs.scrollContainer) {
      this.handleScroll();
      // Add scroll event listener to the referenced element
      this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
    }
  },

  beforeDestroy() {
    if (this.$refs.scrollContainer) {
      // Remove the scroll event listener to avoid memory leaks
      this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
    }
  },

  methods: {
    ...mapMutations({
      setCountryRules: 'articles/SET_COUNTRY_RULES',
      setCountry: 'country/SET_COUNTRY',
      setCompanyFormType: 'country/SET_COMPANY_FORM_ID',
    }),

    ...mapActions({
      setCompanyFields: 'country/setCompanyFields',
      getCountriesSupported: 'country/getCountriesSupported',
      getArticle: 'articles/getArticle',
      getUserSubAccounts: 'common/getSubAccounts',
      getSenderIdCountryRules: 'articles/getSenderIdCountryRules',
    }),

    isElementScrollable(element) {
      const isScrollableVertically = element.scrollHeight > element.clientHeight;
      const isScrollableHorizontally = element.scrollWidth > element.clientWidth;
      return isScrollableVertically || isScrollableHorizontally;
    },

    handleScroll() {
      const element = this.$refs.scrollContainer; // event.target;
      const isScrollable = this.isElementScrollable(element);
      if (!isScrollable) {
        this.$emit('finish-review', true);
        return;
      }
      const scrollBottom = Math.round(element.scrollTop + element.clientHeight) + 1;

      // Check if user has scrolled to the bottom
      // if (Math.round(element.scrollHeight - element.scrollTop) === element.clientHeight) {
      if (scrollBottom >= element.scrollHeight) {
        this.$emit('finish-review', true);
      }
    },

    handleChange() {
      this.$emit(
        'update',
        {
          key: 'loa',
          data: { wetSignature: this.wetSignature },
        },
      );
    },

    getSenderIdFields(senderId) {
      const senderIdFields = senderId.fields.filter(s => s.type !== 'date' && s.type !== 'uploader');
      return senderIdFields;
    },

    getSenderIdFieldDates(senderId) {
      const senderIdFields = senderId.fields.filter(s => s.type === 'date');
      return senderIdFields;
    },

    async fetchCountryRules() {
      try {
        this.loading = true;
        if (this.country.code && Object.keys(this.countryRules).length === 0) {
          const locale = 'en-US';
          const { rules } = this.country.articles;
          await this.getSenderIdCountryRules({ id: rules, locale });
        }
        this.showCountryRules = true;
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.primary {
  color: #0056E0 !important;
}
</style>
<style lang="scss">
.scrollable {
  padding-right: 20px;
  overflow-y: scroll;

  .grid-cols-4 {
    display: grid;
    grid-template-columns: 25% 75%;
  }
  .grid-cols-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .weight-400 {
    font-weight: 400;
  }

  .weight-600 {
    font-weight: 600;
  }

  .span-3 {
    grid-column: span 3;
  }

  .body-1 {
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
  }

  .attach-stat {
    height: 24px;
    padding: 2px 8px;
    gap: 4px;
    border-radius: 4px;
    background: #CCDDF9;
  }

  .serial-no {
    width: 44px;
    height: 24px;
    padding: 2px 8px;
    gap: 4px;
    border-radius: 4px;
    background: #E0E0E0;
    font-variant-numeric: tabular-nums;
  }

  .el-divider--horizontal {
      margin: 16px 0 !important;
  }

  .el-radio {
    margin: 0 !important;
    padding: 20px;
    border: 1px solid var(--body-background);
    border-radius: 1px;
    margin-bottom: 10px !important;
  }

  .el-alert--info.is-light {
    background-color: transparent !important;
    color: #666 !important;
    border: 1px solid #d9d9d9;
    border-left: 4px solid ;
  }

  .el-alert {
    padding: 10px 16px !important;
    border-radius: 4px !important;
    align-items: center !important;
  }

  .el-alert .el-alert__description {
    margin: 0 !important;
  }

  .el-input.is-disabled .el-input__inner {
    color: #666 !important;
  }

  .disabled-item {
    background-color: #c2c2c2;
    color: #666;
  }

  .el-textarea.is-disabled .el-textarea__inner {
    background-color: #c2c2c2 !important;
    color: #666 !important;
  }

  .date-range {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 22px;

    .date-picker {
      width: 100%;
    }

    .el-date-editor.el-input {
      width: 100%;
    }

    .el-date-editor .el-range-separator {
      color: black;
    }
  }
}
</style>
