<template>
  <div class="company-form">
    <el-dialog
      :visible="visible"
      :title="formData.title"
      custom-class="docs-details-company-form"
      @close="() => actionHandler('cancel')">
      <div v-loading="loading">
        <el-form
          ref="company-details-form"
          label-position="top"
          :model="form"
          :rules="rules">
          <div class="company-form-contents">
            <div
              v-for="(field) in companyFields"
              :key="field.key">
              <!-- if input type is text or textarea -->
              <TextInput
                v-if="(field.type === 'text' || field.type === 'textarea' || field.type === 'integer')"
                :key="field.key"
                :value="form"
                :textarea="field.type === 'textarea'"
                :field-name="field.key"
                :metadata="formData.country"
                @input="(newFormValue) => { form = newFormValue }" />

              <!-- if input type is select -->
              <SelectInput
                v-if="field.type === 'tagger'"
                :key="field.key"
                :value="form"
                :field-name="field.key"
                :metadata="formData.country"
                @input="(newFormValue) => { form = newFormValue }" />

              <!-- if input type is a date -->
              <DateRangeInput
                v-if="field.type === 'dateRange'"
                :key="field.key"
                :value="form"
                :field-name="field.key"
                :metadata="formData.country"
                @input="(newFormValue) => { form = newFormValue }" />
            </div>
          </div>
        </el-form>
        <div
          v-if="!loading"
          class="actions">
          <el-button
            @click="() => actionHandler('cancel')">
            Cancel
          </el-button>
          <el-button
            type="primary"
            :disabled="!formValid"
            @click="() => actionHandler(formData.action)">
            {{ formData.saveText }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { parsePhoneNumber } from 'awesome-phonenumber';
import DateRangeInput from '../partials/DateRangeInput.vue';
import SelectInput from '../partials/SelectInput.vue';
import TextInput from '../partials/TextInput.vue';
import formUtils from '../../../mixins/formUtils';

export default {
  name: 'CompanyForm',

  components: {
    DateRangeInput,
    SelectInput,
    TextInput,
  },

  mixins: [formUtils],

  props: {
    formData: {
      type: Object,
      required: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {},
      formValues: [],
      formValid: false,
    };
  },

  computed: {
    ...mapGetters({
      customFields: 'country/getCustomFields',
    }),
    companyFields() {
      return this.customFields.filter(f => !f.isSenderIdInformation);
    },
  },

  watch: {
    showDialog(val) {
      this.visible = val;
      if (val) {
        this.initScript();
      }
    },
    form: {
      async handler(val) {
        const valid = await this.areRequiredFieldsNotEmpty(val, this.rules);
        this.formValid = valid;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setCompanyFields: 'country/setCompanyFields',
    }),

    async initScript() {
      const { country: { formId }, values, action } = this.formData;
      this.loading = true;
      await this.setCompanyFields({ formId });
      await this.setFormRules(values);
      if (action === 'add') {
        this.resetCompanyForm();
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    resetCompanyForm() {
      const formRef = this.$refs['company-details-form'];
      if (formRef) {
        formRef.resetFields();
      }
    },

    equalStr(str1, str2) {
      if (!str1 || !str2) return false;
      return `${str1}`.toLowerCase() === `${str2}`.toLowerCase();
    },

    async setFormRules() {
      const { action, values } = this.formData;

      const rules = {};
      const form = {};
      const countryRules = this.companyFields;

      await countryRules.forEach((customField) => {
        const formVal = {
          key: customField.key,
          name: customField.raw_title_in_portal,
          type: customField.type,
          options: customField.custom_field_options,
        };
        if (action === 'edit') {
          const currentVal = values.fields.find(x => this.equalStr(x.name, customField.raw_title_in_portal));
          form[customField.key] = currentVal ? currentVal.value : '';
          formVal.value = currentVal ? currentVal.value : '';
        } else {
          form[customField.key] = '';
        }
        this.formValues.push(formVal);

        rules[customField.key] = [
          {
            required: customField.required_in_portal,
            validator: (rule, value, callback) => this.validate(rule, value, callback, customField),
            trigger: 'change',
            titleInPortal: customField.title_in_portal,
            key: customField.key,
          },
        ];
      });
      this.form = form;
      this.rules = rules;
    },

    validate(rule, value, callback, customField) {
      const { customValidation } = customField;
      if (value && customValidation) {
        customValidation.forEach((field) => {
          if (!field.rule.test(value)) {
            callback(new Error(`${field.message}`));
          }
        });
      }

      if (value && customField.type === 'integer') {
        const pn = parsePhoneNumber(`+${value}`);
        if (!pn.valid) {
          callback(new Error('Contact Number must be in an international format. (e.g. +65 9876 5432)'));
        }
      }

      if (!value && customField.required_in_portal) {
        callback(new Error(`${customField.raw_title_in_portal} is required`));
      }

      callback();
    },

    actionHandler(action) {
      if (action === 'cancel') {
        this.$emit('action', { action });
        return;
      }
      const param = { action, form: {} };
      this.$refs['company-details-form'].validate(async (valid) => {
        if (valid) {
          const form = await this.formValues.map((field) => {
            const { key, options } = field;
            const val = this.form[key];
            if (field.type === 'tagger') {
              const taggerVal = options.find(x => x.value.toLowerCase().includes(val.toLowerCase()));
              return {
                ...field,
                value: taggerVal.name,
                rawValue: val,
              };
            }
            return {
              ...field,
              value: val,
              rawValue: val,
            };
          });
          this.loading = true;
          this.$emit('action', { ...param, form });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.company-form {
    .docs-details-company-form {
        width: 800px;
        .el-dialog__header {
            padding: 24px;
            border-bottom: none !important;
        }
        .el-dialog__body {
            padding: 0px  24px;
            min-height: 30vh;
            .company-form-contents {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 16px 24px;
            }

            .actions {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 24px 0px;

              .el-button {
                &.is-disabled {
                  color: #858585 !important;
                  background: #C2C2C2 !important;
                }
              }
            }
        }
    }
}
</style>
