
const tooltips = {
  'id-local': {
    'company-tax-number': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/local/company-tax-number.jpg',
    },
    'letterhead': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/local/letterhead.png',
    },
    'wet-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/wet-signature.png',
    },
    'wet-company-stamp': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/wet-company-stamp.png',
    },
    'stamp-duty': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/local/stamp-duty.png',
    },

  },
  'id-international': {
    'letterhead': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/international/letterhead.png',
    },
    'wet-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/wet-signature.png',
    },
    'wet-company-stamp': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/wet-company-stamp.png',
    },
    'digital-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/id/international/digital-signature.png',
    },
  },
  'ph-local': {
    'letterhead': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/letterhead.png',
    },
    'validity-date': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/local/validity-date.png',
    },
    'wet-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/wet-signature.png',
    },
    'wet-company-stamp': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/wet-company-stamp.png',
    },
    'digital-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/digital-signature.png',
    },
  },
  'ph-international': {
    'letterhead': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/letterhead.png',
    },
    'validity-date-globe': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/international/validity-date-globe.png',
    },
    'validity-date-smart': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/international/validity-date-smart.png',
    },
    'wet-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/wet-signature.png',
    },
    'wet-company-stamp': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/wet-company-stamp.png',
    },
    'digital-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/ph/digital-signature.png',
    },
  },
  'sg-local': {
    'uen-number': {
      'type:': 'img',
      'key': 'SenderID/tooltips-media/sg/uen-number.png',
    },
    'letterhead': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/letterhead.png',
    },
    'wet-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/wet-signature.png',
    },
    'wet-company-stamp': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/wet-company-stamp.png',
    },
    'digital-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/digital-signature.png',
    },
  },
  'sg-international': {
    'uen-number': {
      'type:': 'img',
      'key': 'SenderID/tooltips-media/sg/uen-number.png',
    },
    'letterhead': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/letterhead.png',
    },
    'wet-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/wet-signature.png',
    },
    'wet-company-stamp': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/wet-company-stamp.png',
    },
    'digital-signature': {
      'type': 'img',
      'key': 'SenderID/tooltips-media/sg/digital-signature.png',
    },
  },
};

const attachments = {
  'id-local': [
    {
      'mapKey': 'id-ft|id-finance|id-cc|id-ecdp',
      'name': 'NPWP',
      'filetype': 'image',
      'sampleFilename': 'NPWP-tax-number-1.jpg',
      'sampleKey': 'SenderID/company-documents/id/local/NPWP-tax-number-1.jpg',
    },
    {
      'mapKey': 'id-ft|id-finance|id-cc|id-ecdp',
      'name': 'Single Business Number document (NIB)',
      'filetype': 'image',
      'sampleFilename': 'single-business-number.jpg',
      'sampleKey': 'SenderID/company-documents/id/local/single-business-number.jpg',
    },
    {
      'mapKey': 'id-finance',
      'name': 'OJK OR Bank Indonesia and Kominfo License',
      'filetype': 'image',
      'sampleFilename': 'OJK.jpg',
      'sampleKey': 'SenderID/company-documents/id/local/OJK.jpg',
    },
    {
      'mapKey': 'id-ecdp',
      'name': 'Kominfo OR Pengguna Sistem Elektronik (PSE) License',
      'filetype': 'image',
      'sampleFilename': 'PSE.jpg',
      'sampleKey': 'SenderID/company-documents/id/local/PSE.jpg',
    },
    {
      'mapKey': 'id-ft',
      'name': 'BAPPEBTI License',
      'filetype': 'image',
      'sampleFilename': 'BAPPEBTI-future-trading.jpg',
      'sampleKey': 'SenderID/company-documents/id/local/BAPPEBTI-future-trading.jpg',
    },
    {
      'mapKey': 'id-cc',
      'name': 'BAPPEBTI License',
      'filetype': 'image',
      'sampleFilename': 'BAPPEBTI-crypto-currency.jpg',
      'sampleKey': 'SenderID/company-documents/id/local/BAPPEBTI-crypto-currency.jpg',
    },
    {
      'mapKey': 'id-ft',
      'name': 'SK Kemenkumham',
      'filetype': 'image',
      'sampleFilename': 'sk-kemenkumhan.jpg',
      'sampleKey': 'SenderID/company-documents/id/local/sk-kemenkumhan.jpg',
    },
  ],
  'ph-local': [
    {
      'mapKey': 'ph-bfsi|ph-og',
      'name': 'SEC Business Registration OR Articles of Incorporation',
      'filetype': 'image',
      'sampleFilename': 'business-registration.png',
      'sampleKey': 'SenderID/company-documents/ph/local/business-registration.png',
    },
    {
      'mapKey': 'ph-bfsi|ph-others',
      'name': 'Business Permit OR Mayor\'s Permit from the local government unit\'s BPLO (Business Permit and Licensing office)',
      'filetype': 'image',
      'sampleFilename': 'business-permit.png',
      'sampleKey': 'SenderID/company-documents/ph/local/business-permit.png',
    },
    {
      'mapKey': 'ph-og',
      'name': 'Business Permit',
      'filetype': 'image',
      'sampleFilename': 'business-permit.png',
      'sampleKey': 'SenderID/company-documents/ph/local/business-permit.png',
    },
    {
      'mapKey': 'ph-og',
      'name': 'Mayor\'s Permit',
      'filetype': 'no-sample',
      'sampleFilename': 'no-sample',
      'sampleKey': 'no-sample',
    },
    {
      'mapKey': 'ph-og',
      'name': 'Written confirmation of client consent for database usage',
      'filetype': 'no-sample',
      'sampleFilename': 'no-sample',
      'sampleKey': 'no-sample',
    },
    {
      'mapKey': 'ph-bfsi',
      'name': 'Specific regulatory agency licenses',
      'filetype': 'image',
      'sampleFilename': 'specific-regulatory-agency-license.png',
      'sampleKey': 'SenderID/company-documents/ph/local/specific-regulatory-agency-license.png',
    },
    {
      'mapKey': 'ph-og',
      'name': 'PAGCOR License',
      'filetype': 'image',
      'sampleFilename': 'pagcor-license.png',
      'sampleKey': 'SenderID/company-documents/ph/local/pagcor-license.png',
    },
  ],
  'ph-international': [
    {
      'mapKey': 'ph-og',
      'name': 'Philippines Offshore Gaming Operator (POGO) License',
      'filetype': 'no-sample',
      'sampleFilename': 'no-sample',
      'sampleKey': 'no-sample',
    },
    {
      'mapKey': 'ph-og',
      'name': 'Philippines Inland Gaming Operator (PIGO) License',
      'filetype': 'no-sample',
      'sampleFilename': 'no-sample',
      'sampleKey': 'no-sample',
    },
  ],
  'sg-local': [
    {
      'mapKey': 'sg-ft|sg-finance|sg-cc|sg-ecdp|sg-others',
      'name': 'Singapore Business Registration Certificate (ACRA)',
      'filetype': 'image',
      'sampleFilename': 'acra.png',
      'sampleKey': 'SenderID/company-documents/sg/local/acra.png',
    },
  ],
};
export default {
  methods: {
    async getTooltipsMedia(payload) {
      try {
        const { title, code, headquarter } = payload;
        const codei = code.toLowerCase();
        let titleKey = title.toLowerCase().replace(/ /g, '-');
        if (!title) {
          return false;
        }
        const tooltipKey = `${code.toLowerCase()}-${headquarter}`;
        if (codei === 'ph') {
          if (titleKey.includes('validity-period')) {
            titleKey = 'validity-date';
          }
          if (titleKey.includes('validity-period') && title.includes('globe')) {
            titleKey = 'validity-date-globe';
          } else if (titleKey.includes('validity-period') && title.includes('smart')) {
            titleKey = 'validity-date-smart';
          }
        }

        if (codei === 'sg') {
          if (titleKey.includes('uen')) {
            titleKey = 'uen-number';
          }
        }

        const { type, key } = tooltips[tooltipKey][titleKey] || {};
        if (!key) {
          return false;
        }
        return { key, type };
      } catch (error) {
        return false;
      }
    },

    getRequiredDocuments(payload) {
      const { code, industry, headquarter } = payload;

      const industryKeys = new Map([
        // Indonesia map keys
        ['ID-futures-trading', 'id-ft'],
        ['ID-finance', 'id-finance'],
        ['ID-crypto', 'id-cc'],
        ['ID-e-commerce', 'id-ecdp'],
        // Philippines map keys
        ['PH-bfsi', 'ph-bfsi'],
        ['PH-online-gaming', 'ph-og'],
        ['PH-others', 'ph-others'],
        // Singapore map keys
        ['SG-futures-trading', 'sg-ft'],
        ['SG-finance', 'sg-finance'],
        ['SG-crypto', 'sg-cc'],
        ['SG-e-commerce', 'sg-ecdp'],
        ['SG-others', 'sg-others'],
      ]);
      const supported = Object.keys(attachments)
        .some(key => key === `${code.toLowerCase()}-${headquarter.toLowerCase()}`);
      if (!supported) {
        return false;
      }
      const mapKey = industryKeys.get(`${code}-${industry.id}`);
      const doclist = attachments[`${code.toLowerCase()}-${headquarter.toLowerCase()}`]
        .filter(item => item.mapKey.includes(mapKey));
      return doclist;
    },
  },
};
