export default {
  methods: {
    getCompanyAddress(companyFields) {
      const fields = companyFields || [];

      if (fields.length > 0) {
        const address1Field = fields.filter(field => field.name === 'Company Address Line 1')[0];
        const address2Field = fields.filter(field => field.name === 'Company Address Line 2')[0];
        const postalCodeField = fields.filter(field => field.name === 'Postal Code')[0];
        const cityField = fields.filter(field => field.name === 'City')[0];
        const countryField = fields.filter(field => field.name === 'Country')[0];

        const address1 = address1Field ? address1Field.rawValue.trim() : '';
        const address2 = address2Field ? address2Field.rawValue.trim() : '';
        const postalCode = postalCodeField ? postalCodeField.rawValue.trim() : '';
        const city = cityField ? cityField.rawValue.trim() : '';
        const country = countryField ? countryField.rawValue.trim() : '';

        return `${address1}${address2 ? ',' : ''} ${address2}${city ? ',' : ''} ${city}${postalCode ? ',' : ''} ${postalCode}${country ? ',' : ''} ${country}`;
      }
      return '';
    },
  },
};
