<template>
  <div>
    <div
      v-if="showField()"
      class="mb-1 text-sm font-medium text-grey-darker">
      <label class="flex items-center justify-between">
        <div class="flex items-center">
          <span class="flex">
            <p class="text-sm">{{ getFieldLabel() }}</p>
            <span
              v-if="customField.required_in_portal"
              class="text-xs text-red">&nbsp;*</span>
          </span>
          <el-tooltip
            v-if="!tooltipField && !!getFieldTooltip()"
            :enterable="false"
            placement="top"
            effect="light">
            <div
              slot="content"
              class="tooltip-content">
              <div>{{ getFieldTooltip() }} </div>
            </div>
            <i class="icon-novo-support ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </div>
        <el-tooltip
          v-if="tooltipField"
          :enterable="false"
          placement="right"
          effect="light">
          <div
            slot="content"
            class="tooltip-content">
            <div class="media-tooltip">
              <PdfView :field="tooltipField" />
            </div>
          </div>
          <span class="btn-text-primary">Show example</span>
        </el-tooltip>
      </label>
    </div>
    <el-form-item
      class="leading-normal"
      :prop="fieldName">
      <!-- eslint-disable vue/no-mutating-props -->
      <el-input
        v-model="value[fieldName]"
        size="small"
        :type="!textarea ? 'input' : 'textarea'"
        :rows="6"
        :placeholder="getFieldLabel()"
        @blur="handleBlur" />
      <p
        class="leading-tight text-grey mb-2 mt-1 text-xs text-left no-break">
        {{ getFieldHelpText() }}
      </p>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PdfView from './PdfView';
import mediaTooltips from '../../../mixins/mediaTooltips';

const capitalized = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export default {
  name: 'TextInput',
  components: {
    PdfView,
  },

  mixins: [
    mediaTooltips,
  ],

  props: {
    value: {
      type: Object,
      required: true,
    },

    fieldName: {
      type: String,
      default() {
        return '';
      },
    },

    isValidating: {
      type: Boolean,
      default() {
        return false;
      },
    },

    textarea: {
      type: Boolean,
      value: false,
    },

    metadata: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tooltipField: null,
    };
  },

  computed: {
    ...mapGetters({
      countryCustomFields: 'country/getCustomFields',
    }),
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  async created() {
    this.customField = this.countryCustomFields.find(customField => customField.key === this.fieldName);
    if (this.customField.title_in_portal.toLowerCase() === 'company industry') {
      // eslint-disable-next-line vue/no-mutating-props
      this.value[this.fieldName] = this.metadata.industry.name || this.metadata.industry || '';
    }

    if (this.customField.title_in_portal.toLowerCase() === 'company headquarter') {
      // eslint-disable-next-line vue/no-mutating-props
      this.value[this.fieldName] = this.metadata.headquarter || '';
    }
    const title = this.customField.title_in_portal;
    const field = await this.getTooltipsMedia({ title, ...this.metadata });
    if (!field) {
      this.tooltipField = null;
    } else {
      this.tooltipField = field;
    }
  },

  methods: {

    getFieldLabel() {
      return capitalized(this.customField.raw_title_in_portal);
    },

    getFieldTooltip() {
      return this.customField.raw_description;
    },

    showField() {
      return this.countryCustomFields.some(customField => customField.key === this.fieldName);
    },

    getFieldHelpText() {
      let helpText = '';
      const field = this.countryCustomFields.find(customField => customField.key === this.fieldName);

      if (field.raw_title_in_portal === 'Sender ID') {
        // helpText = 'Inclusion of special character in Sender ID is subject to approval';
        helpText = 'Your Sender ID name should include the brand name of the\n company. Use a minimum of 3 characters and a maximum of 11 characters.';
      }

      if (!this.isValidating && field.raw_title_in_portal === 'Content of Message') {
        helpText = 'Your message must include the brand name in the content.';
      }

      return helpText;
    },

    handleBlur() {
      if (this.getFieldLabel().toLowerCase() === 'sender id') {
        this.$emit('blur', this.value);
      }
    },
  },
};
</script>
<style scoped>
.el-input__inner::placeholder {
  color: #a9a9a9 !important;
  opacity: 1;
}

.no-break {
  white-space: normal;
  word-break: keep-all;
  overflow-wrap: break-word;
}
</style>
