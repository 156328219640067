<template>
  <div class="mb-10">
    <div class="flex py-5">
      <div class="flex-1">
        <h1 class="text-xl text-color font-semibold page-title pt-2 capitalize">
          {{ $t('sidebar_menu[5]') }}
        </h1>
      </div>
      <div class="flex-1 text-right">
        <el-button
          id="create-api-keys"
          type="primary"
          @click="handleCreate">
          {{ $t('messaging.api_keys.index[0]') }}
        </el-button>
      </div>
    </div>
    <TestCode
      :curl-code="curlCode"
      :show-api-key="showApiKey"
      :api-key-selected="apiKeySelected"
      :selected-subaccount="subAccountSelected"
      :user="user"
      :loading="loading"
      :subaccounts="subAccounts"
      :mask-api-key="maskApiKey"
      @select-subaccount="handleSubAccountCode" />

    <h3 class="mt-8 mb-3">
      {{ $t('messaging.api_keys.index[9]') }}
    </h3>
    <div
      v-if="subAccountSelected"
      class="border-grey-lighter border solid flex flex-col w-full relative rounded bg-grey-inputs mt-5">
      <div class="flex-shrink border-b border-grey-lighter">
        <Filters
          :search-key="searchKey"
          :total="total"
          @reload-filter="()=> {
            reloadFilter();
          }"
          @search="v => { handleSearch(v) }" />
      </div>
      <div class="relative">
        <Table
          :data="tableData.paginated"
          :pagination="paginationDef"
          :loading="loading"
          :fetching="fetching"
          :search-key="searchKey"
          :show-api-key="showApiKey"
          :api-key-selected="apiKeySelected"
          :mask-api-key="maskApiKey"
          @copy-key="(v) => { handleCopy(v) }"
          @show-key="(v) => { handleShow(v) }"
          @delete-key="deleteKey"
          @create-key="handleCreate"
          @edit-key="handleEdit" />
      </div>
      <div
        class="flex-shrink px-3 py-2 border-t border-grey-lighter">
        <Pagination
          :items="tableData.paginated"
          :pagination="{...paginationDef, pageCount, total, currentPageSize }"
          @handle-current-page="(v) => { handleCurrentPage(v)}"
          @handle-page-size="(v) => { handlePageSize(v) }" />
      </div>
    </div>
    <div class="pt-4 api-keys-table">
      <IPWhitelisting />
    </div>
    <EditFormDialog
      :dialog-title="dialogTitle"
      :dialog-visible="dialogVisible"
      :is-editing="isEditing"
      :show-api-key="showApiKey"
      :loading="loading"
      :form-data="formData"
      :api-key-selected="apiKeySelected"
      :mask-api-key="maskApiKey"
      @close-dialog="() => { dialogVisible = false }"
      @open-form="handleFormOpen"
      @submit-form="handleSubmit" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import EditFormDialog from './EditFormDialog';
import TestCode from './TestCode';
import Filters from './Filters';
import Table from './Table';
import Pagination from './Pagination';
import TimeFormatter from '../../mixins/timeFormatter';
import IPWhitelisting from './IPWhitelisting';

export default {
  name: 'AccountConfigurationApiList',

  components: {
    EditFormDialog,
    TestCode,
    Filters,
    Table,
    Pagination,
    IPWhitelisting,
  },

  mixins: [
    TimeFormatter,
  ],

  data() {
    const codes = [
      'curl -X POST https://sms.8x8.com/api/v1/subaccounts/xxxxxxx/messages', //eslint-disable-line
      '-H "Authorization: Bearer xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"',
      '-H "Content-Type:  application/json"',
      '-d $\'{ "source": "xxxxxxxx", "destination": "+6512345678", "text": "Hello World!", "encoding": "AUTO" }\'', //eslint-disable-line
    ];

    return {
      showSb: false,
      showCurlCode: true,

      curlCode: {
        obj: codes,
        text: codes.join(' '),
      },
      user: {},
      formData: {},
      apiKeySelected: '',
      subAccountSelected: '',
      loading: false,
      dialogTitle: '',
      dialogVisible: false,
      isEditing: false,
      paginationDef: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 20,
        currentPage: 1,
      },
      apiKeys: [],
      fetching: '',
      searchKey: '',
      showApiKey: false,
      copyApiKey: false,
    };
  },

  computed: {
    ...mapState('api', [
      'keys',
      'totalActive',
    ]),

    ...mapState('common', ['subAccounts']),
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    pageCount() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return Math.ceil(this.tableData.original.length / this.paginationDef.pageSize);
      }

      return 0;
    },

    currentPageSize() {
      if (this.tableData
        && this.tableData.paginated
        && Array.isArray(this.tableData.paginated)) {
        return this.tableData.paginated.length;
      }

      return 0;
    },

    total() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return this.tableData.original.length;
      }

      return 0;
    },

    tableData() {
      const list = this.apiKeys.slice(0);
      const start = this.paginationDef.currentPage * this.paginationDef.pageSize - this.paginationDef.pageSize;
      const end = start + this.paginationDef.pageSize;

      if (this.searchKey.trim().length) {
        const searchString = this.$sanitize(this.searchKey);
        const filters = ['Name'];
        const result = list
          .filter(row => filters.some((k) => {
            const s = row[k];

            if (s) {
              return s
                .toLowerCase()
                .includes(searchString.toLowerCase());
            }
            return 0;
          }));

        return {
          paginated: result.slice(start, end) || [],
          original: result || [],
        };
      }

      return {
        paginated: list.slice(start, end) || [],
        original: list || [],
      };
    },
  },

  created() {
    this.searchKey = '';
    this.fetching = '';
    this.getCurlCode();
    this.fetchSubAccounts();
  },

  methods: {
    ...mapActions({
      getAll: 'api/getAll',
      createKey: 'api/create',
      editKey: 'api/edit',
      delete: 'api/delete',
      setSelected: 'api/setSelected',
      getSubAccounts: 'common/getSubAccounts',
    }),

    handleSubAccountCode(subAccountCode) {
      this.subAccountSelected = subAccountCode;
      this.getCurlCode();
    },

    handleUpdateCode(apiCode) {
      this.apiKeySelected = apiCode;
      this.getCurlCode();
    },

    getCurlCode() {
      if (this.subAccounts.length && this.apiKeys.length) {
        const obj = [
          `curl -X POST https://sms.8x8.com/api/v1/subaccounts/${this.subAccountSelected}/messages`, //eslint-disable-line
          `-H "Authorization: Bearer ${this.apiKeySelected}"`,
          '-H "Content-Type:  application/json"',
          `-d $\'{ "source": "abcde", "destination": "+6512345678", "text": "Hello World!", "encoding": "AUTO" }\'`, //eslint-disable-line
        ];

        this.curlCode = {
          obj,
          text: obj.join(' '),
        };
      }
    },

    reloadFilter() {
      this.fetchData();
    },

    fetchSubAccounts() {
      this.user = this.$auth.user();
      this.loading = true;
      this.fetching = 'ready';
      this.getSubAccounts(this.user)
        .then(() => {
          if (!_.get(this, 'subAccounts[0].SubAccountId')) {
            return;
          }

          this.subAccountSelected = this.subAccounts[0].SubAccountId;

          // fetch api keys
          this.fetchData();
        })
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        })
        .then(() => {
          this.loading = false;
          this.fetching = '';
        });
    },

    handleSearch(v) {
      this.searchKey = v;
      this.paginationDef.currentPage = 1;
    },

    handleCreate() {
      this.isEditing = false;
      this.dialogVisible = true;
    },

    fetchData(page = 0) {
      this.loading = true;

      this.paginationDef.currentPage = page || 1;

      this.getAll()
        .then(() => {
          this.fetching = 'done';
          this.apiKeys = this.keys;
          this.apiKeySelected = this.apiKeys[0].ApiKey;
          this.loading = false;
          this.getCurlCode();
        })
        .catch((err) => {
          this.fetching = 'done';
          this.loading = false;
          this.$showError(this, err);
        });
    },

    handleFormOpen() {
      this.dialogTitle = (this.isEditing) ? this.$t('messaging.api_keys.index[11]') : this.$t('messaging.api_keys.index[12]');
    },

    handleSubmit(formData = {}) {
      const form = { ...this.formData, ...formData };

      // Show loading
      this.loading = true;

      if (!this.isEditing) {
        // Dispatch create action
        this.createKey(form)
          .then(() => {
            this.$notify({
              type: 'success',
              message: this.$t('success.created', { value: this.$t('messaging.api_keys.index[13]') }),
            });

            this.formData = {};
            this.dialogVisible = false;
            this.fetchData();
            this.loading = false;
          })
          .catch((err) => {
            this.$showError(this, err);
          });
      } else {
        // Dispatch edit action
        this.editKey(form)
          .then(() => {
            this.$notify({
              type: 'success',
              message: this.$t('success.updated', { value: this.$t('messaging.api_keys.index[14]') }),
            });

            this.formData = {};
            this.dialogVisible = false;
            this.loading = false;
            this.fetchData();
          })
          .catch((err) => {
            this.loading = false;
            this.$showError(this, err);
          });
      }
    },

    handleEdit(key = {}) {
      this.isEditing = true;
      this.formData = { ...key };
      this.dialogVisible = true;
    },

    deleteKey(key) {
      const activeKey = this.apiKeys.filter(k => k.Active);

      if (activeKey.length > 1 || !key.Active) {
        const msg = this.$t('messaging.api_keys.index[15]', { name: key.Name });
        this.$confirm(msg, this.$t('app_labels.warning'), {
          confirmButtonText: this.$t('actions.delete'),
          cancelButtonClass: 'el-button--text',
          cancelButtonText: this.$t('actions.cancel'),
          type: 'error',
        }).then(() => {
          this.loading = true;
          this.delete(key)
            .then(() => {
              this.$notify({
                type: 'success',
                message: this.$t('success.deleted', { value: this.$t('column_labels.key') }),
              });
              this.loading = false;
              this.fetchData();
            })
            .catch((err) => {
              this.$showError(this, err);
              this.loading = false;
            });
        })
          .catch(() => {
          // Do nothing
          });
      } else {
        this.$notify({
          type: 'error',
          message: this.$t('success.deleted', { value: this.$t('messaging.api_keys.index[16]') }),
        });
      }
    },

    handleCurrentPage(currentPage) {
      this.paginationDef.currentPage = parseInt(currentPage, 10);

      console.log(this.paginationDef);
    },

    handlePageSize(size) {
      this.paginationDef.pageSize = parseInt(size, 10);
      this.paginationDef.currentPage = 1;
    },

    maskApiKey(key = '') {
      if (!key) return '';
      const masked = key.substring(0, key.length - 6);

      return `${masked.replace(/./g, '*')}${key.slice(-6)}`;
    },

    handleCopy(key = '') {
      this.copyApiKey = true;
      this.showApiKey = this.showApiKey && key === this.apiKeySelected;
      this.handleUpdateCode(key);
    },

    handleShow(key = '') {
      this.showApiKey = true;
      this.handleUpdateCode(key);
    },
  },
};
</script>
