/* eslint-disable no-param-reassign */
import { handlePackageLimit } from '../../../helpers/handlePackageLimit';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    user: {},
    hasSmsAccess: false,
    hasChatAppsAccess: false,
    hasSmsEngageAccess: false,
    hasVoiceAccess: false,
    showMessageBody: false,
    hasPricingEntitlement: true,

    timeZoneName: 'UTC / Greenwich Mean Time',
    timeZoneOffset: 0,
    timeZone: '(UTC-00)',
    isTimeZoneAllowed: false,
  },

  getters: {
    getUser: st => st.user,
    getSmsAccess: st => st.hasSmsAccess,
    getChatAppsAccess: st => st.hasChatAppsAccess,
    getSmsEngageAccess: st => st.hasSmsEngageAccess,
    getVoiceAccess: st => st.hasVoiceAccess,
    getMessageBodyAccess: st => st.showMessageBody,
    getPricingEntitlementAccess: st => st.hasPricingEntitlement,
  },

  mutations: {
    SET_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.user = payload.user;

      const {
        Product_SMS: hasSMSAccess,
        Product_CA: hasCAAccess,
        Product_VO: hasVoiceAccess,
        SmsToSurveyEnabled: hasSurvey,
        HasSubAccountsWithSurveyForms: hasSurveyForms,
        BetaFeatures: betaFeatures,
        Roles: userRoles,
        TimeZoneName,
        TimeZoneGMTOffset,
      } = payload.user;

      state.hasSmsAccess = hasSMSAccess;
      state.hasChatAppsAccess = hasCAAccess;
      state.hasVoiceAccess = hasVoiceAccess && betaFeatures.includes('VO-Messaging');
      state.showMessageBody = userRoles.includes('CA.ShowMessageBody_v2');
      state.hasSmsEngageAccess = hasSMSAccess
        && hasSurvey && hasSurveyForms;

      state.hasPricingEntitlement = handlePackageLimit('feature-cp-pricing');
      // state.isTimeZoneAllowed = betaFeatures.includes('CONNECT-Timezone');
      // always allow timezone
      state.isTimeZoneAllowed = true;

      // User timezone settings
      state.timeZoneName = TimeZoneName || 'UTC / Greenwich Mean Time';
      state.timeZoneOffset = TimeZoneGMTOffset ? (TimeZoneGMTOffset / 60) : 0; // offset should be in minutes
      let timeZone = '(UTC-00)';
      if (TimeZoneGMTOffset) {
        const offset = new Date(TimeZoneGMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
        let prefix = '-';
        if (TimeZoneGMTOffset > 0) {
          prefix = '+';
        }
        timeZone = `(UTC${prefix}${offset})`;
      }
      state.timeZone = timeZone;
    },
  },

  actions,
};
