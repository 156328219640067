<template>
  <div v-loading="loading">
    <div class="relative">
      <div class="flex flex-wrap">
        <el-alert
          v-if="connected === false"
          style="margin-bottom:1em; font-weight: bold"
          :title="$t('messaging.contacts.uploads[0]')"
          type="warning"
          show-icon
          :closable="false" />
        <div
          v-loading="connected === false"
          class="w-full p-2">
          <div class="row flex flex-wrap">
            <div class="w-4/5">
              <el-steps
                :active="active"
                finish-status="success">
                <el-step
                  :title="$t('messaging.contacts.uploads[1]')"
                  :description="$t('messaging.contacts.uploads[2]')" />
                <el-step
                  :title="`${$t('actions.select')} ${$tc('column_labels.group', 2)}`"
                  :description="$t('messaging.contacts.uploads[3]')" />
              </el-steps>
            </div>
          </div>
          <hr class="border-solid border-b border-grey mb-4">

          <el-alert
            v-if="filesToday && filesToday.length > 0"
            :title="$t('messaging.contacts.uploads[4]', { time: suspendTime })"
            type="warning"
            class="is-light leading-loose mb-2"
            :closable="false"
            show-icon />

          <div
            v-loading="isFetching"
            class="row flex flex-wrap">
            <div class="w-1/2 pr-4">
              <div class="text-center content-center">
                <div class="text-left mb-2">
                  <!-- <p
                    v-if="!filesToday.length"
                    class="text-sm leading-normal text-grey-darker">
                    After successful upload, select which column is the <strong>Mobile</strong>
                    number. First name and Last name fields are optional.
                  </p> -->
                  <i18n
                    v-if="!filesToday.length"
                    path="messaging.contacts.uploads[5]"
                    tag="p">
                    <template #mobile>
                      <strong>{{ $t('column_labels.mobile') }}</strong>
                    </template>
                    <template #msisdn>
                      <strong>MSISDN</strong>
                    </template>

                    <template #firstname>
                      <strong>Firstname</strong>
                    </template>

                    <template #lastname>
                      <strong>Lastname</strong>
                    </template>
                  </i18n>
                </div>
                <br>
                <div v-if="processing">
                  <div class="pc-loading text-center">
                    <p v-loading="true" />
                    <p>
                      <br><br> {{ $t('messaging.contacts.uploads[6]') }}
                    </p>
                  </div>
                </div>
                <div v-show="active === 1 && !processing">
                  <div
                    v-tablewidth
                    class="upload-table-list"
                    parent-class="el-card__body">
                    <el-table
                      class="mb2 partial"
                      :data="partialContacts"
                      :show-header="false"
                      border>
                      <el-table-column
                        v-for="(col, idx) in partialContactsCols"
                        :key="idx"
                        min-width="180px">
                        <template slot-scope="scope">
                          <strong
                            v-if="isFirstRow(scope.row)"
                            :class="activeColumnClass(idx)">
                            {{
                              columnHeaderLabel(idx) &&
                                columnHeaderLabel(idx)
                                  .replace(/msisdn/i, $t('column_labels.mobile')) || $t('messaging.contacts.uploads[7]')
                            }}
                          </strong>
                          <span
                            v-if="!isFirstRow(scope.row)"
                            :class="`${activeColumnClass(idx)}${isHeaderRow(scope.row) ? ' hrow' : ''}`">
                            {{ scope.row[idx] }}
                          </span>

                          <el-button
                            v-if="isFirstRow(scope.row) && columnHeaderLabel(idx)"
                            type="default"
                            size="mini"
                            icon="el-icon-minus"
                            class="remove-label"
                            @click.native.prevent="removeLabel(idx)" />

                          <el-dropdown
                            v-if="isFirstRow(scope.row) && !columnHeaderLabel(idx)"
                            trigger="click"
                            class="drp-dwn"
                            @command="setColumn">
                            <el-button
                              type="default"
                              size="mini"
                              icon="el-icon-arrow-down"
                              class="el-dropdown-link" />
                            <el-dropdown-menu
                              slot="dropdown">
                              <el-dropdown-item
                                v-for="key in activeColumnLabels"
                                :key="`${key}:${idx}`"
                                :command="`${key}:${idx}`">
                                {{ key.match(/msisdn/i) ? $t('column_labels.mobile') : key }}
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-show="!processing && active === 0 && !filesToday.length">
                  <el-upload
                    ref="upload"
                    :class="active === 1 ? 'import-active': ''"
                    class="w-grow w-full"
                    :auto-upload="false"
                    :http-request="handleRequest"
                    :action="`${uploadApiUrl}contacts/upload`"
                    drag
                    :before-upload="handleBeforeUpload"
                    :on-change="handlePreview"
                    :multiple="false"
                    :on-success="handleSuccess"
                    :on-progress="handleProgress"
                    :on-error="handleError"
                    :on-remove="handleRemove"
                    :headers="getHeaders()"
                    :limit="1"
                    :on-exceed="handleExceed">
                    <div v-if="!processing && active === 0">
                      <i class="el-icon-upload el-icon-wc-cloud-upload" />
                      <i18n
                        path="messaging.contacts.uploads[8]"
                        class="el-upload__text"
                        tag="div">
                        <template #upload>
                          <em>{{ $t('messaging.contacts.uploads[21]') }}</em>
                        </template>
                      </i18n>
                    </div>
                    <div
                      slot="
                        tip"
                      class="el-upload__tip pt-2">
                      {{ $t('messaging.contacts.uploads[9]') }}
                      <div>
                        <el-button
                          size="small"
                          type="text"
                          @click.prevent="downloadSampleFile()">
                          {{ $t('messaging.contacts.uploads[10]') }}
                        </el-button>
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
            <div
              class="w-2/5 ml-16"
              :hidden="(active === 0)">
              <div class="flex flex-wrap">
                <div class="w-full">
                  <p class="mb-2 text-sm leading-normal text-grey-darker">
                    {{ $t('messaging.contacts.uploads[11]') }}
                  </p>
                  <br>
                  <el-radio-group
                    v-model="addToGroup"
                    @change="handleRadioGroup">
                    <el-radio
                      :label="0"
                      class="mb-2">
                      {{ $t('messaging.contacts.uploads[12]') }}
                    </el-radio><br>
                    <el-radio
                      :label="1"
                      class="mb-2"
                      :disabled="!hasRoles().contactGroups">
                      {{ $t('messaging.contacts.uploads[13]') }}
                    </el-radio>
                  </el-radio-group>
                  <div v-show="addToGroup">
                    <el-select
                      v-model="selectedGroups"
                      multiple
                      allow-create
                      filterable
                      style="width:60%"
                      :placeholder="$t('messaging.contacts.uploads[14]')"
                      @change="handleSelectChange">
                      <el-option
                        v-for="(group,i) in groups"
                        :key="i"
                        :label="group.name"
                        :value="group.name" />
                    </el-select>
                  </div>
                </div>
                <br>
                <hr class="border-solid border-b border-grey mb-8 mt-8 w-full">
                <el-button
                  type="text"
                  size="small"
                  class="my3"
                  @click.native="resetUpload">
                  {{ $t('actions.cancel') }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  class="my3"
                  :disabled="columnLabels.MSISDN === -1"
                  @click.native="processFile">
                  {{ $t('actions.start_processing') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8">
      <div class="flex flex-wrap">
        <div class="w-full p-2">
          <DataTables
            :data="uploadFiles"
            :has-action-col="false"
            :heading="getHeading"
            :actions-def="getActionsDef()"
            :search-def="getSearchDef()"
            :pagination-def="getPaginationDef()"
            type="upload contact"
            @sizeChange="sizeChanged">
            <el-table-column
              :min-width="100"
              :label="getDateHeader(timeZone, $t('column_labels.date_imported'))">
              <template slot-scope="scope">
                <span>
                  {{ getLocalDate(scope.row.createdAt, timeZoneOffset) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="fileName"
              :min-width="180"
              :label="$t('column_labels.file_name')"
              show-overflow-tooltip />
            <el-table-column
              :min-width="100"
              :label="$t('column_labels.no_of_records')">
              <template
                slot-scope="scope">
                {{ getTotalRows(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column
              :min-width="140"
              :label="$t('column_labels.status')">
              <template
                slot-scope="scope">
                <el-tag
                  size="mini"
                  class="flex-auto text-xs"
                  :type="getStatusType(scope.row.status)"
                  close-transition>
                  <span>{{ getStatus(scope.row) }}</span>
                </el-tag>
                <el-tag
                  v-if="scope.row.status === 'inProgress' && Number(scope.row.batchFilesProcessed) > 0 && Number(scope.row.totalBatchFiles) > 0"
                  class="flex-auto text-xs"
                  size="mini"
                  close-transition
                  type="success"
                  style="margin-right: 10px; margin-left: 5px">
                  <span> {{ getPercentage(scope.row) }}</span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="80"
              :label="$t('column_labels.valid_rows')">
              <template slot-scope="scope">
                {{ getInsertedRows(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column
              :min-width="100"
              :label="$t('column_labels.invalid_rows')">
              <template slot-scope="scope">
                {{ getErrorRows(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column
              :min-width="100"
              :label="$t('column_labels.duplicates')">
              <template slot-scope="scope">
                {{ getDuplicates(scope.row) }}
              </template>
            </el-table-column>
            <!--
            <el-table-column
              :min-width="120"
              :label="$t('column_labels.added_to_groups')">
              <template slot-scope="scope">
                {{ Number(scope.row.contactGroups).toLocaleString($constants.LOCALE) }}
              </template>
            </el-table-column>
            -->
            <el-table-column
              align="right"
              :min-width="100">
              <template slot-scope="scope">
                <el-tooltip
                  v-if="scope.row.FileStateId === 5"
                  class="item"
                  effect="dark"
                  :content="$t('messaging.contacts.uploads[15]')"
                  placement="left"
                  hide-after="5">
                  <el-tag
                    class="cursor-pointer"
                    size="medium"
                    type="danger"
                    style="margin-right: 5px"
                    @click.native.prevent="stopUploadProcess(scope.row)">
                    <i
                      type="danger"
                      class="el-icon-remove" />
                  </el-tag>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('actions.download')"
                  placement="left"
                  hide-after="5">
                  <el-dropdown
                    trigger="click"
                    @command="handleCommand">
                    <el-button
                      type="default"
                      size="mini">
                      <i class="el-icon-download" />
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="getDownloadUrl(scope.row)">
                        <span class="text-xs">{{ $t('column_labels.original') }}</span>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="scope.row.errorRows > 0"
                        :command="getDownloadUrl(scope.row, 'invalid')">
                        <span class="text-xs">{{ $t('column_labels.invalid_rows') }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-tooltip>
              </template>
            </el-table-column>
          </DataTables>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExportToCsv } from 'export-to-csv';
import { mapState, mapActions } from 'vuex';
import moment from 'moment-timezone'; // eslint-disable-line
import XLSX from 'xlsx';

import DataTables from '../../../components/DataTables.vue';
import TimeFormatter from '../../../mixins/timeFormatter';

export default {
  name: 'ContactUpload',

  components: {
    DataTables,
  },

  mixins: [
    TimeFormatter,
  ],

  data() {
    return {
      active: 0,
      isFetching: false,
      selectedGroups: [],
      addToGroup: 0,
      files: [],
      loading: false,
      fetching: '',

      suspendTime: '',
      interval: 1000,

      getHeaders() {
        return {
          Authorization: `Bearer ${this.$auth.token()}`,
        };
      },
      paginationDef: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        currentPage: 1,
      },
      uploadFiles: [],
      filesToday: [],
      hasUpload: false,

      columnLabels: {
        MSISDN: -1,
        FirstName: -1,
        LastName: -1,
      },

      uploadApiUrl: `${process.env.VUE_APP_API_URL}`,
    };
  },

  beforeRouteLeave(to, from, next) {
    this.resetUpload();
    next();
  },

  computed: {
    ...mapState('socket', ['message', 'connected', 'queueUrl', 'socketId']),

    ...mapState('fileManagement', {
      uploads: 'files',
      totalActive: 'totalActive',
    }),

    ...mapState('websender', {
      partialData: 'partialData',
      processing: 'processing',
    }),

    ...mapState('groupManagement', {
      groups: 'groups',
    }),

    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    getDownloadUrl() {
      return (row, type = 'original') => {
        const { fileName, invalidFileLocation, fileLocation } = row;
        const location = type === 'invalid' ? invalidFileLocation : fileLocation;
        return { location, fileName, type };
      };
    },

    uploadInProgress() {
      return this.uploads
        .filter(i => moment(i.createdAt).format('l') === moment().format('l'))
        .filter(i => ['completed', 'failed'].indexOf(i.status) === -1)
        .filter((i) => {
          const minAgo = moment(i.createdAt).add(5, 'minutes');
          return (!moment().isAfter(minAgo));
        });
    },


    getHeading() {
      return this.$t('messaging.contacts.uploads[22]');
    },

    showDownloadButton() {
      return this.showTotalRows;
    },

    showTotalRows() {
      // Because 9 is error and 10 is ready
      return n => n > 8;
    },

    partialContacts() {
      if (!this.partialData) return [];
      // header column
      const hc = Array(this.partialData.List[0].length).fill('');
      if (hc.length === 1) {
        this.columnLabels.MSISDN = 0; // eslint-disable-line
      }

      return [hc, ...this.partialData.List];
    },

    partialContactsCols() {
      // Get the length partial contact first row
      if (this.partialContacts[0] && Object.keys(this.partialContacts[0]).length) {
        return Object.keys(this.partialContacts[0]).length;
      }

      return 0;
    },

    removeLabel() {
      return (idx) => {
        Object.keys(this.columnLabels).forEach((k) => {
          if (this.columnLabels[k] === idx) this.columnLabels[k] = -1; // eslint-disable-line
        });
      };
    },

    columnHeaderLabel() {
      return idx => Object.keys(this.columnLabels)
        .find(k => this.columnLabels[k] === idx);
    },

    isFirstRow() {
      return row => this.partialContacts.indexOf(row) === 0;
    },

    isHeaderRow() {
      return row => !/[0-9]{1,20}/.test(row[0]);
    },

    activeColumnLabels() {
      return Object
        .keys(this.columnLabels)
        .filter(k => this.columnLabels[k] === -1);
    },

    activeColumnClass() {
      return (idx) => {
        // If found
        if (Object.keys(this.columnLabels).find(k => this.columnLabels[k] === idx)) {
          return '';
        }

        // otherwise
        return 'opaque';
      };
    },
  },

  watch: {
    partialContacts(val) {
      if (val.length) {
        this.active = 1;
      }
    },
    message(msg) {
      // Update file
      if (msg.message && msg.message.match(/(is ready|was not imported)/i)) {
        this.getAllFiles()
          .then(() => {
            this.uploadFiles = this.uploads;
            this.filesToday = this.uploadInProgress;
          });
      }
    },
  },

  created() {
    this.resetUpload();
    this.fetching = 'ready';
    this.getAllGroups();
    this.fetchData();

    let { loggedInUser: user } = this;

    const au = this.$auth.user();
    const at = this.$auth.token();

    if (!user && au && at) {
      user = au;
      user.Token = at;
      this.setLoggedInUser(user);
    }

    // Change Upload api url based on User's regionId
    const token = localStorage.getItem('WWW-Authenticate');
    const decodedData = this.decodeJwt(token);

    if (decodedData && Object.keys(decodedData).includes('RegionId')) {
      const { RegionId: region } = decodedData;
      const regionId = region;

      if (regionId === 1) {
        // Region is Indonesia
        this.uploadApiUrl = `${process.env.VUE_APP_API_URL_ID}`;
      } else if (regionId === 2) {
        // Region is UK
        this.uploadApiUrl = `${process.env.VUE_APP_API_URL_UK}`;
      }
    }
  },

  mounted() {
    setInterval(() => {
      if (this.filesToday && this.filesToday.length > 0) {
        this.countTime(this.filesToday[0].createdAt);
      }
    }, this.interval);
  },

  methods: {
    ...mapActions({
      getAllFiles: 'fileManagement/getAll',
      updateFile: 'file/update',
      getBatch: 'fileManagement/getBatch',
      download: 'fileManagement/download',
      getAllGroups: 'groupManagement/getAll',
      createGroup: 'groupManagement/create',
      setPartialData: 'websender/setPartialData',
      setProcessing: 'websender/setProcessing',
      setActiveStep: 'websender/setActiveStep',
      upload: 'contactManagement/upload',
      setLoggedInUser: 'common/setLoggedInUser',
    }),

    decodeJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        const decodedData = JSON.parse(jsonPayload);
        return decodedData;
      } catch (err) {
        console.error('Failed to validate region'); // eslint-disable-line
        this.$showError(this, new Error('Failed to validate region'));
        return 0;
      }
    },

    countTime(cd) {
      const endTime = moment(cd).add(5, 'minutes');
      const createdTime = moment();
      let duration = moment.duration((endTime.unix() - createdTime.unix()) * this.interval, 'milliseconds');

      duration = moment.duration(duration.asMilliseconds() - this.interval, 'milliseconds');

      // const d = moment.duration(duration).days();
      // const h = moment.duration(duration).hours();
      let m = moment.duration(duration).minutes();
      let s = moment.duration(duration).seconds();


      m = m < 10 ? `0${m}` : m;
      s = s < 10 ? `0${s}` : s;

      const timeLabel = m > 1 ? 'minutes' : 'minute';

      this.suspendTime = `${m}:${s} ${timeLabel}`;

      if (Number(m) === 0 && Number(s) === 0) {
        this.filesToday = [];
        this.hasUpload = false;
      }
    },

    downloadSampleFile() {
      const data = [
        { column0: 6596793616, column1: 'Chris', column2: 'Shiflet' },
        { column0: 6596790613, column1: 'Don Don', column2: 'Ampalayo' },
        { column0: 6596790641, column1: 'John', column2: 'Boonham' },
        { column0: 6596793611, column1: 'Chris', column2: 'Shiflet' },
        { column0: 6596780611, column1: 'Sean', column2: 'Yseult' },
        { column0: 6596790612, column1: 'Mike', column2: 'Pinisi' },
        { column0: 6596790614, column1: 'Don Don', column2: 'Ampalayo' },
        { column0: 6596790642, column1: 'John', column2: 'Boonham' },
        { column0: 6596793616, column1: 'Chris', column2: 'Shiflet' },
        { column0: 6596780617, column1: 'Sean', column2: 'Yseult' },
        { column0: 6596790612, column1: 'Mike', column2: 'Pinisi' },
        { column0: 6596790614, column1: 'Don Don', column2: 'Ampalayo' },
        { column0: 6596790642, column1: 'John', column2: 'Boonham' },
        { column0: 6596793616, column1: 'Chris', column2: 'Shiflet' },
        { column0: 96780617, column1: 'Sean', column2: 'Yseult' },
      ];

      const options = {
        fieldSeparator: ',',
        decimalSeparator: '.',
        title: 'sample_file',
        filename: 'sample_file',
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    },

    hasRoles() {
      const { Roles } = this.$auth.user();

      return {
        contactGroups: Roles.indexOf('Contacts_v2') !== -1,
      };
    },

    getDuplicates(row) {
      const {
        totalRows = 0,
        insertedRows = 0,
        errorRows = 0,
        status = '',
      } = row;

      if (status.toLowerCase() === 'queued' || status.toLowerCase() === 'inprogress') {
        return '-';
      }
      const duplicates = totalRows - (insertedRows + errorRows);
      return Number(duplicates).toLocaleString(this.$constants.LOCALE);
    },

    getStatus(row) {
      let {
        status = 'queued',
      } = row;

      if (status === 'inprogress') {
        status = 'in progress';
      }

      return status.toUpperCase();
    },

    getPercentage(row) {
      const {
        totalBatchFiles,
        batchFilesProcessed,
      } = row;

      const total = (Number(batchFilesProcessed) / Number(totalBatchFiles) * 100);
      const pct = total > 100 ? 100 : total;
      return `${Number(pct).toFixed(2)}%`;
    },

    getTotalRows(row) {
      const {
        totalRows = null,
      } = row;

      return totalRows !== null ? Number(totalRows).toLocaleString(this.$constants.LOCALE) : '-';
    },

    getInsertedRows(row) {
      const {
        insertedRows = null,
      } = row;

      return insertedRows !== null ? Number(insertedRows).toLocaleString(this.$constants.LOCALE) : '-';
    },

    getErrorRows(row) {
      const {
        errorRows = null,
      } = row;

      return errorRows !== null ? Number(errorRows).toLocaleString(this.$constants.LOCALE) : '-';
    },

    getStatusType(n) {
      /*
      const state = {
        5: 'warning',
        0: 'warning',
        9: 'danger',
        10: 'success',
      };
      */

      const state = {
        'inProgress': 'warning',
        'queue': 'warning',
        'failed': 'danger',
        'completed': 'success',
      };
      return n ? state[n.toString()] : '';
    },

    resetUpload() {
      this.files = [];
      this.selectedGroups = [];
      this.active = 0;
      this.hasUpload = false;
      this.setPartialData(undefined);
      this.setProcessing(false);
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
    },

    handleRadioGroup() {
      if (this.addToGroup === 0) this.selectedGroups = [];
    },

    stopUploadProcess(s) {
      this.$confirm(this.$t('messaging.contacts.uploads[16]'), this.$t('messaging.contacts.uploads[17]'), {
        type: 'warning',
        confirmButtonText: this.$t('actions.ok'),
        cancelButtonClass: 'el-button--text',
        cancelButtonText: this.$t('actions.cancel'),
      })
        .then(() => {
          this.updateFile({ fileId: s.FileId })
            .then(() => {
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.$showError(this, err);
            });
        })
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    handleCommand(params) {
      this.loading = true;

      this.download(params)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$showError(this, err);
        });
    },

    handleSelectChange(value) {
      const groupName = value[value.length - 1];

      if (groupName && typeof groupName === 'string') {
        // Check if value is not from existing groups
        const exist = this.groups.find((group) => {
          const str1 = String(group.name).toLowerCase();
          const str2 = String(groupName).toLowerCase();

          return str1 === str2;
        });

        // Create group if it does not exist
        if (!exist) {
          this.createGroup({
            name: String(groupName),
          });
        }
      }
    },

    setColumn(cmd) {
      const a = cmd.trim().split(':');
      const key = a[0];
      const index = parseInt(a[1], 10);

      this.columnLabels[key] = index;
    },

    processFile() {
      this.$refs.upload.submit();
    },

    handleBeforeUpload() {
      return this.files.length === 0;
    },

    handleRequest(req) {
      // const q = Object.assign(this.paginationDef);
      const firstRow = this.partialData.List[0];
      const fileHasHeader = !/[0-9]{1,20}/.test(firstRow[0]);

      const columns = Object.entries(this.columnLabels)
        .sort((a, b) => a[1] - b[1])
        .reduce((s, n) => {
          const [key, value] = n;
          if (value > -1) {
            s.push(key);
          }
          return s;
        }, []).join();

      const groups = this.selectedGroups.reduce((s, n) => {
        this.groups.forEach((g) => {
          if (g.name === n) {
            s.push(g.id);
          }
        });

        return s;
      }, []).join();

      // show loader
      this.loading = true;

      this.upload({
        file: req.file,
        groups,
        columns,
        fileHasHeader,
      })
        .then((res) => {
          this.$message({
            message: this.$t('messaging.contacts.uploads[19]', { filename: req.file.name }),
            type: 'success',
          });

          if (this.$telemetry) {
            const { track } = this.$telemetry;
            const { data } = res;
            track('contacts_uploaded',
              {
                filename: data.fileName,
                status: data.status,
                numberContacts: data.totalRows,
                uiArea: 'contact_management_screen',
              });
          }

          // Reset
          this.resetUpload();
          this.isFetching = false;
          this.fetching = 'done';
          this.$refs.upload.clearFiles();
          this.loading = false;

          const { data: batch } = res;
          this.uploadFiles = this.uploads;
          this.filesToday = this.uploadInProgress;
          this.handleCheckBatch(batch);
        })
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    async handleCheckBatch(batch) {
      let tries = 0;
      const maxTries = 100;
      const { id } = batch;
      while (tries < maxTries) {
        const item = await this.getBatch({ id }); // eslint-disable-line
        this.uploadFiles = this.uploads;
        this.filesToday = this.uploadInProgress;
        if (item && item.status === 'completed') {
          break;
        }
        tries += 1;
        await this.timeout(4000); // eslint-disable-line
      }
    },

    async handlePreview(file) {
      // show loader
      this.loading = true;
      try {
        const data = await file.raw.arrayBuffer();
        const workbook = XLSX.read(data, { sheetRows: 10 });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });


        if (!/[0-9]{1,20}/.test(rows.slice(0)[0])) {
          const incorrectHeaders = this.checkHeaders(rows.slice(0));
          if (incorrectHeaders > 0) {
            this.resetUpload();
            this.handleError(new Error('Invalid Headers'));
            return;
          }
        }
        // Get only first 10
        const n = 10;
        this.setPartialData({ List: rows.slice(0, n) });
        this.setActiveStep(1);
      } catch (err) {
        this.resetUpload();
        this.handleError(new Error('Unable to parse file'));
      } finally {
        this.loading = false;
      }
    },

    checkHeaders(arr) {
      const headers = arr[0];
      const validHeaders = [
        'Msisdn',
        'FirstName',
        'LastName',
        'Country',
        'ExternalId',
        'WeChatUserId',
        'FacebookUserId',
        'Email',
        'ZaloId',
        'LineId',
        'KakaoTalkId',
      ];

      return headers.filter((h) => {
        const i = validHeaders.find(v => v.toUpperCase() === h.toUpperCase());
        return i === undefined;
      }).length;
    },

    handleRemove() {
      this.files = [];
      this.active = 0;
      this.hasUpload = false;
      this.filesToday = [];
    },

    handleError(err) {
      this.hasUpload = false;
      let emsg = '';
      if (err.message && err.message.match(/not supported/i)) {
        emsg = this.$t('errors.file_not_supported');
      }

      if (err.message && err.message.match(/(less|than|30|mb)/i)) {
        emsg = this.$t('errors.file_size_limit', { size: '30mb' });
      }

      if (err.message && err.message.match(/(to|parse|file)/i)) {
        emsg = this.$t('errors.file_parse_error');
      }

      this.$showError(this, err, { useMessage: emsg || '' });
      this.active = 0;
      this.setProcessing(false);
    },

    handleProgress(p) {
      this.hasUpload = true;
      if (Number(p.percent) === 100) {
        this.setProcessing(true);
      }
    },

    handleExceed() {
      this.$message.warning(this.$t('messaging.contacts.uploads[20]'));
    },

    handleSuccess(resp) {
      // this.$refs.upload.clearFiles();
      this.files.push(resp);
      // this.active = 1;
    },

    getActionsDef() {
      return {};
    },

    getSearchDef() {
      return {
        props: ['Filename', 'FileStateName'],
        show: false,
      };
    },

    getPaginationDef() {
      return Object.assign({}, {
        total: this.totalActive,
      }, this.paginationDef);
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    sizeChanged(val) {
      this.paginationDef = Object.assign(this.paginationDef, val);
      this.fetching = 'ready';
      this.fetchData();
    },

    async fetchData() {
      const q = Object.assign(this.paginationDef);
      this.loading = true;

      if (q && this.uploadFiles.length > 0) {
        const from = (q.currentPage - 1) * q.pageSize;
        const to = q.pageSize + from;

        setTimeout(() => {
          this.uploadFiles = this.uploads.slice(from, to);
          this.filesToday = this.uploadInProgress;

          this.loading = false;
        }, 500);
      }

      // this to avoid watching of upload state
      if (this.fetching === 'ready') {
        await this.getAllFiles({
          offset: (q.currentPage - 1) * q.pageSize,
          limit: q.pageSize,
        })
          .then(() => {
            this.uploadFiles = this.uploads;
            this.filesToday = this.uploadInProgress;

            setTimeout(() => {
              this.fetching = 'done';
              this.loading = false;
            }, 1000);
          })
          .catch((err) => {
            this.loading = false;
            // this.$message({
            //   type: 'error',
            //   message: 'Failed to fetch import history',
            // });
            this.$showError(this, err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset, .reset:hover {
  display: none;
  padding: 5px;
  border-radius: 100px;
  position: absolute;
  right: -10px;
  top: 10px;
  z-index: 100;
  border: 1px solid #fff;
  background-color: #aaa;
  font-weight: bold;
  color: #fff;
}

.up:hover .reset {
  display: block;
}

.pc-loading {
  text-align: center;
  padding: 4em 2em;
  margin: 2em auto;
  border-radius: 5px;
  color: #20a0ff
}

.opaque {
  opacity: 0.5;
}

.remove-label {
  position:absolute;
  right:20px;
  top:10px
}

.drp-dwn {
  position:absolute;
  right:20px;
  top:8px
}

</style>
